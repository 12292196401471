import { transformDateValue } from 'core/js/FormUtils';
export const getMforderstatusView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let mforderstatusList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
              
              
            ],
            'dataList': [
                
              ]
          }

          mforderstatusList.push(card);
  })
  return mforderstatusList;
}