import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import MforderDashboard from '../MforderDashboard';
import { setMforderDashboardMforderList } from 'store/slice/ManufSlice';
import { setOrderType } from 'store/slice/AppSlice';
import { setSelectedBottomMenu } from 'store/slice/MenuSlice';
import BottomMenu from 'pages/dashboard/BottomMenu';

export default function MforderPageArchived() {
    const dispatch = useDispatch();
    const {mobileView} = useSelector((state)=>state.menu);
    const {mforderList} = useSelector((state)=>state.manufslice);
    useEffect(() => {
    dispatch(setSelectedBottomMenu("ARCHIVED-ORDER"))
    dispatch(setOrderType('ARCHIVED'))
    dispatch(setMforderDashboardMforderList(
        mforderList.filter(orderObj => orderObj.orderstatusid===9)
    ))
    },[]);

    return (
        <>
        <MforderDashboard/>
        </>
    )

}