import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import BusinessprocessDashboard from '../BusinessprocessDashboard';

import { openDrawer } from './../../../store/slice/MenuSlice';

export default function BusinessprocessPage() {
    const dispatch = useDispatch();
    const { BusinessprocessList} = useSelector((state)=>state.manufaslice)
    useEffect(() => {
        dispatch(openDrawer(false));
    },[]);

    return (
        < BusinessprocessDashboard />
    )

}