import {useState, useEffect} from 'react';


import MforderStatusEditForm from 'components/mforder/components/MforderStatusEditForm';
export default function UpdateMfOrderStatusActionImpl(){

    return (
        <>
        <MforderStatusEditForm/>
        </>
    )
}