import {formFieldToReqJSON} from '../../core/js/FormUtils';
import { v4 as uuidv4 } from 'uuid';

 var MfproductorderResourcePrefix = "https://appmachine.in/manuf-oms-api/mfproductorder";
 var MfproductordersResourcePrefix = "https://appmachine.in/manuf-oms-api/mfproductorders";


export const createAPI = (formValues, successCallBack, failuerCallback) => {
    const token = localStorage.getItem("JWT");
    var reqObj = formFieldToReqJSON(formValues);
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
        body: JSON.stringify(reqObj)
    };

    fetch(MfproductorderResourcePrefix, requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}


export function updateAPI(id,formValues, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    var reqObj = formFieldToReqJSON(formValues);
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json',
      'X-Request-ID': requestId,
      'Authorization': `Bearer ${token}` },
      body: JSON.stringify(reqObj)
    };
      
        fetch(MfproductorderResourcePrefix, requestOptions)
            .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}
export function getAPI(id, loadForm){
    const token = localStorage.getItem("JWT");
    let response;
    const requestId = uuidv4().split("-")[4];
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'X-Request-ID': requestId,
                'Authorization': `Bearer ${token}`}
            };
        fetch(MfproductorderResourcePrefix+'/'+id,requestOptions)
            .then((data) => data.json())
            .then((data) => {
                loadForm(data);
                //setData({data},{formValues}, setFormValues);
            })
    } catch (error) {
        // setAlert(true);
        console.log('There was an error', error);
    }
    
}

export function getListAPI(setMfproductorderList, dataFetchCallBack){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'GET',
      headers: {
        'X-Request-ID': requestId,
        'Authorization': `Bearer ${token}`
    }
    };
    fetch(MfproductordersResourcePrefix, requestOptions)
        .then((data) => data.json())
        .then((data) => {setMfproductorderList(data.list);dataFetchCallBack(data.list)})
}

export function deleteAPI(id, deleteCallBack){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'DELETE',
      headers: {'X-Request-ID': requestId,'Authorization': `Bearer ${token}`}
    };
    fetch(MfproductorderResourcePrefix+"/"+id, requestOptions)
        .then((data) => data.json())
        .then((data) => {
            deleteCallBack(data);
        })
  }

export function updateEditableAPI(id,formValues, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    var reqObj = formFieldToReqJSON(formValues);
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(reqObj)
    };
      
    fetch(MfproductorderResourcePrefix+'/'+id+'/editable', requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            if (response.status===undefined)    successCallBack(response);
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    failuerCallback(errorMessage);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}

export function getMfproductorderInfoByBusinessidAPI(businessid, setMfproductorderList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(MfproductordersResourcePrefix+"/businessid/"+businessid+"/businessid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setMfproductorderList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getMfproductorderInfoByOrderIdAPI(orderid, setMfproductorderList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(MfproductordersResourcePrefix+"/orderid/"+orderid+"/orderId", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setMfproductorderList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getMfproductorderInfoByStatusidAPI(productorderstatusid, setMfproductorderList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(MfproductordersResourcePrefix+"/productorderstatusid/"+productorderstatusid+"/statusid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setMfproductorderList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getMfproductorderInfoByProductidAPI(productid, setMfproductorderList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(MfproductordersResourcePrefix+"/productid/"+productid+"/productid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setMfproductorderList(data.list);
          dataFetchCallBack(data.list)
    })
}