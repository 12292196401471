import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MforderstatusFilterCriteria from './MforderstatusFilterCriteria'
import MforderstatusList from './MforderstatusList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MforderstatusAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMforderstatusDashboardMforderstatusList } from '../../store/slice/ManufSlice';

export default function MforderstatusListView({setShowEdit,setMforderstatusId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mforderstatusDashboard);   

    const [filteredMforderstatusList, setFilteredMforderstatusList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mforderstatusDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mforderstatusDashboard.mforderstatusList.length>-1){
        setFilteredMforderstatusList(mforderstatusDashboard.mforderstatusList);
        transformToCardFormat(mforderstatusDashboard.mforderstatusList, setCardList);
      }
    },[mforderstatusDashboard.mforderstatusList])

    useEffect(()=>{
          transformToCardFormat(filteredMforderstatusList, setCardList);
      },[filteredMforderstatusList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredMforderstatusList(mforderstatusDashboard.mforderstatusList);
            transformToCardFormat(mforderstatusDashboard.mforderstatusList, setCardList);

        } else {
            setFilteredMforderstatusList(
                mforderstatusDashboard.mforderstatusList
                    .filter(mforderstatus=> mforderstatus.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mforderstatusDashboard.filteredMforderstatusList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MforderstatusList
                    setShowEdit={setShowEdit}
                    setMforderstatusId = {setMforderstatusId}
                    tableList={filteredMforderstatusList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}