import React, {useState, useEffect} from 'react';
import {Grid, Typography,Button, Divider} from '@mui/material';
import { useSelector, useDispatch, useStore } from 'react-redux';
import OTP from './OTP';
import {AuthenticateAPI, validateCustomerOtpAPI} from './AuthAPI';
import { useNavigate } from "react-router-dom";
import {sendOTPAPI} from './AuthAPI';
import UserId from './UserId';
import { setLoggedIn, setMessage, setAppuserInfo, setuserprofileInfo } from 'store/slice/HeaderSlice';

export default function LoginContainer({refreshPage, description, customerMode}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [userId, setUserId] = useState('');
  const [otpReqId, setOtpReqId] = useState('');

  const [otp, setOtp] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  
  const [mode, setMode]= useState('userid');
  const {isLoggedIn,userprofileInfo} = useSelector((state)=>state.headerslice)
  
  useEffect(()=>{
  },[isLoggedIn])
  useEffect(()=>{
    if (mode==='otp')   setOtp('');
  },[mode])
  useEffect(()=>{
    if (customerMode){
      sendOTPAPI(crypto.randomUUID(),sendOTPsuccess, () =>{});
    }
  },[])

  const sendOTPsuccess = (data) =>{
    setOtpReqId(data.otpResponse.id);
  }
  const validateOTP = () =>{
      AuthenticateAPI(otpReqId, otp,validateOTPsuccessCallBack, failuerCallback);
  }
  const validateCustomerOTPsuccessCallBack = (data) =>{
    dispatch(setLoggedIn(true));
    dispatch(setAppuserInfo(data.appuserInfo));
    dispatch(setuserprofileInfo(data.userprofileInfo));
    localStorage.setItem("JWT",data.accesstoken); 
    //navigate('/');
  }
  const validateOTPsuccessCallBack = (data) =>{
    dispatch(setuserprofileInfo(data.userprofileInfo));
    localStorage.setItem("JWT",data.accesstoken);    
    dispatch(setLoggedIn(true));
    if (data.userprofileInfo!==null)
      navigate('/');
  }
  
  const failuerCallback = (data) =>{
    //dispatch(setMessage(JSON.stringify(data)));
    setShowErrorMsg(data.appError.errorMsg);
  }
  const getLoginSuccessComponent = () =>{
    if (isLoggedIn && userprofileInfo.email===undefined){
      return <>
      <Grid sx={{width:'100%'}}>
      <Divider/>
      <Grid sx={{padding:2, textAlign:'center'}}>
        <Typography variant="h5" sx={{color:'primary.main'}}>Thank you for Logging In, but you do not have access.</Typography>
      </Grid>
      <Grid sx={{padding:2, textAlign:'center'}}>
        <Typography variant="h6" sx={{color:'grey'}}>Please ask your APP owner to get the access and relogin.</Typography>
      </Grid>
      </Grid>
        
      </>
    }
    else return <>
              <Grid item lg={12} xs={12}>
              <UserId mode={mode} setMode={setMode} setUserId={setUserId} setOtpReqId={setOtpReqId}/>
              </Grid>
              
              <Grid item lg={12} xs={12} sx={{width:'100%'}} padding={1}/>
              <OTP mode={mode} setMode={setMode} setOtp={setOtp} showErrorMsg={showErrorMsg} setShowErrorMsg={setShowErrorMsg}/>
              
              <Grid item lg={12} xs={12} align="right" >
                  <Button variant="contained" onClick={validateOTP} disabled={(userId.length>0 && otp.length>0)?false:true}>Login</Button>
              </Grid>
            </>
    } 
  return (
    <>
        <Grid container direction="row" padding={.5} sx={{width:'100%', minHeight:'200px', minwidth:'500px'}} >
            {getLoginSuccessComponent()}
        </Grid>
     </>
  );
  
}