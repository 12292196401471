import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function BusinessprocessInfo(){
  const { businessprocessObj} = useSelector((state)=>state.manufslice.businessprocessDashboard);
    return (
      <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: { xs: window.screen.height - 250, lg: window.screen.height } }}>
        
        <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{backgroundColor:'white'}}
      >
            
        <Grid item lg={4} xs={6}><FieldView label="Business Id"  value={ businessprocessObj.businessid!==null && businessprocessObj.businessid!==undefined?businessprocessObj.businessid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Title"  value={ businessprocessObj.title!==null && businessprocessObj.title!==undefined?businessprocessObj.title:'NOT KNONWN' }/></Grid>
      </Grid>
      </Grid>
      </Box>
    )
}