import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import TasktypeEditForm from './TasktypeEditForm';
import TasktypeList from './TasktypeList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import TasktypeListView from './TasktypeListView'


export default function TasktypeDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.tasktypeDashboard);
  const [ tasktypeId, setTasktypeId] = React.useState('');
  const [reloadTasktypeList, setReloadTasktypeList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <TasktypeEditForm 
            setReloadTasktypeList={setReloadTasktypeList}
            tasktypeId={tasktypeId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <TasktypeListView 
                      setTasktypeId = {setTasktypeId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
