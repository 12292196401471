import { Grid, Tooltip} from "@mui/material";

export function getMfpartyStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        title:{
            name:'title',
            label:'Title',
            value:'',
            error:false,
            errorMessage:'You must enter Title',
            required:true,
            fieldtype:'string'
        },
        email:{
            name:'email',
            label:'email',
            value:'',
            error:false,
            errorMessage:'You must enter email',
            required:true,
            fieldtype:'emailid'
        },
        address:{
            name:'address',
            label:'Address',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'You must enter Business Id',
            required:true,
            fieldtype:'ID'
        },
        mobile:{
            name:'mobile',
            label:'Mobile',
            value:'',
            error:false,
            errorMessage:'You must enter Mobile',
            required:true,
            fieldtype:'mobile'
        },
        gst:{
            name:'gst',
            label:'GST',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'title', headerName: 'Title', width:150 },
        { field: 'email', headerName: 'email', width:250 },
        { field: 'mobile', headerName: 'Mobile', width:250 },
        { field: 'gst', headerName: 'GST', width:50 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['title']:{
          ...formValues['title'],
          value:data.title,
        },['email']:{
          ...formValues['email'],
          value:data.email,
        },['address']:{
          ...formValues['address'],
          value:data.address,
        },['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['mobile']:{
          ...formValues['mobile'],
          value:data.mobile,
        },['gst']:{
          ...formValues['gst'],
          value:data.gst,
        },
      })
}