import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box, Typography} from '@mui/material';
import FieldView2 from 'core/components/layout/FieldView2';


export default function TaskinstanceInfo(){
  const { taskinstanceObj} = useSelector((state)=>state.manufslice.taskinstanceDashboard);
    return (
      <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid xs={12}><Typography color="primary.darker" variant="h5">Task Info</Typography></Grid>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: { xs: window.screen.height - 250, lg: window.screen.height } }}>
        
        <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{backgroundColor:'white'}}
      >
        {taskinstanceObj?<>
          <Grid item lg={4} xs={6}><FieldView2 color="white" label="Task"  value={ taskinstanceObj.taskname!==null && taskinstanceObj.taskname!==undefined?taskinstanceObj.taskname:'NOT KNONWN' }/></Grid>
          <Grid item lg={4} xs={6}><FieldView2 color="white" label="Status"  value={ taskinstanceObj.taskstatusname!==null && taskinstanceObj.taskstatusname!==undefined?taskinstanceObj.taskstatusname:'NOT KNONWN' }/></Grid>
        </>
        :<></>}
        </Grid>
      </Grid>
      </Box>
    )
}