import React from 'react'

import { TextField ,Grid, Typography} from "@mui/material"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BooleanFilter from '../../core/form/BooleanFilter';
import { useSelector, useDispatch } from 'react-redux';
import { setMfprintingstatusFilterCriteria } from 'store/slice/ManufSlice';

export default function MfprintingstatusFilterCriteria() {
    const dispatch = useDispatch();
    const {filterCriteria} = useSelector((state)=>state.manufslice.mfprintingstatusDashboard);
    
    
const updateTitleCriteria = (title) =>{
        dispatch(setMfprintingstatusFilterCriteria(
            {
                ...filterCriteria,
                title:title
        }))
    }

    return (

        <Grid container direction="row" lg={12} sx={{justifyContent:'center'}}>
                    <Grid item lg={4} padding={1}>
                        <TextField type="search" variant="standard"
                            label="Title"
                            name="title"
                            value={filterCriteria.title}
                            onChange={(e)=>updateTitleCriteria(e.target.value)}
                            sx={{minWidth:'100px'}}
                        />
                    </Grid>


        </Grid>
    )

}