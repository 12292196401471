import { Grid, Tooltip} from "@mui/material";

export function getMforderStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        title:{
            name:'title',
            label:'title',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'You must enter Business Id',
            required:true,
            fieldtype:'ID'
        },
        partyid:{
            name:'partyid',
            label:'Party Id',
            value:'',
            error:false,
            errorMessage:'You must enter Party Id',
            required:true,
            fieldtype:'bodynamiclist'
        },
        partyname:{
            name:'partyname',
            label:'Party Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        orderdate:{
            name:'orderdate',
            label:'Order Date',
            value:'',
            error:false,
            errorMessage:'You must enter Order Date',
            required:true,
            fieldtype:'date'
        },
        remarks:{
            name:'remarks',
            label:'Remarks',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        executive:{
            name:'executive',
            label:'Executive',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        orderstatusid:{
            name:'orderstatusid',
            label:'Order Status Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        orderstatusname:{
            name:'orderstatusname',
            label:'Order Status Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'partyname', headerName: 'Party Name', width:150 },
        { field: 'orderdate', headerName: 'Order Date', width:150 },
        { field: 'executive', headerName: 'Executive', width:50 },
        { field: 'orderstatusid', headerName: 'Order Status Id', width:50 },
        { field: 'orderstatusname', headerName: 'Order Status Name', width:50 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['title']:{
          ...formValues['title'],
          value:data.title,
        },['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['partyid']:{
          ...formValues['partyid'],
          value:data.partyid,
        },['partyname']:{
          ...formValues['partyname'],
          value:data.partyname,
        },['orderdate']:{
          ...formValues['orderdate'],
          value:data.orderdate,
        },['remarks']:{
          ...formValues['remarks'],
          value:data.remarks,
        },['executive']:{
          ...formValues['executive'],
          value:data.executive,
        },['orderstatusid']:{
          ...formValues['orderstatusid'],
          value:data.orderstatusid,
        },['orderstatusname']:{
          ...formValues['orderstatusname'],
          value:data.orderstatusname,
        },
      })
}