import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { updateTaskStatusAPI } from "api/OmsFactoryopsWebServices";
import { updateTaskinstanceObj, updateMfproductorderObj, updateMforderObj } from 'store/slice/ManufSlice';
import { setTaskinstanceUpdateStatusModal } from "store/slice/ManufSlice";


const UpdateStatusRadio = () => {
    const dispatch = useDispatch();
    const { taskstatusList } = useSelector((state) => state.manufslice);
    const { taskinstanceObj } = useSelector((state) => state.manufslice.taskinstanceDashboard)
    const [selectedStatusId, setSelectedStatusId] = useState(1);
    const [apiInProgress, setApiInProgress] = useState(false);

    useEffect(()=>{
        if (taskinstanceObj) {
            setSelectedStatusId(taskinstanceObj.taskstatusid);
        }
    },[taskinstanceObj])
    const handleChange = (event) => {
        setSelectedStatusId(event.target.value);
       
    };

    const updateTaskStatusApiFailure = (errMsg) =>{
        setApiInProgress(false);
        alert("ERROR !!! "+errMsg);
    }
    const updateTaskStatusApiSuccess = (apiResponse) =>{
        setApiInProgress(false);

        if (apiResponse.mforderInfo!==null) dispatch(updateMforderObj(apiResponse.mforderInfo));
        if (apiResponse.mfproductorderInfo!==null) dispatch(updateMfproductorderObj(apiResponse.mfproductorderInfo));
        
        dispatch(updateTaskinstanceObj(apiResponse.taskinstanceInfo));
        dispatch(setTaskinstanceUpdateStatusModal(false));
    }
    const updateTaskStatus = () =>{
        setApiInProgress(true);
        updateTaskStatusAPI({
            "taskInstanceId":taskinstanceObj.id,
            "taskStatusId":selectedStatusId
            },updateTaskStatusApiSuccess,updateTaskStatusApiFailure);
    }
    return (
        <>
            <FormControl fullWidth>
                <FormLabel><Typography color="primary.dark" variant="h5">Update Task Status To</Typography></FormLabel>
                <Box sx={{ backgroundColor: "white", borderRadius: 1, padding:.5 }}>
                    <RadioGroup
                        row
                        value={selectedStatusId}
                        onChange={handleChange}
                        sx={{ width: "100%", justifyContent: "space-around" }} // Full width & evenly spaced
                    >
                        {taskstatusList.slice().reverse().map((status) => (
                            <FormControlLabel
                                key={status.id}
                                value={status.id}
                                control={<Radio />}
                                label={status.title}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </FormControl>
            <Grid container justifyContent="flex-end" sx={{ paddingTop: 2 }}>
                {apiInProgress ? <CircularProgress /> : 
                taskinstanceObj?<Button variant="contained" disabled={parseInt(selectedStatusId)===parseInt(taskinstanceObj.taskstatusid)} onClick={()=>updateTaskStatus()}>Save</Button>:<></>} </Grid>
        </>
    );
};

export default UpdateStatusRadio;
