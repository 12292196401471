import { transformDateValue } from 'core/js/FormUtils';
export const getTaskView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let taskList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
                            {"fieldLabel":"Business Process Id","fieldValue":transformDataValue(data.businessprocessid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Pre Task Id","fieldValue":transformDataValue(data.pretaskid,"number"), "fieldType":"number"},
                            {"fieldLabel":"PerformingRole Id","fieldValue":transformDataValue(data.performingroleid,"bodynamiclist"), "fieldType":"bodynamiclist"},
                            {"fieldLabel":"PerformingRole Name","fieldValue":transformDataValue(data.performingrolename,"string"), "fieldType":"string"},
                            {"fieldLabel":"Task Type Id","fieldValue":transformDataValue(data.tasktypeid,"bodynamiclist"), "fieldType":"bodynamiclist"},
                            {"fieldLabel":"Task Type Name","fieldValue":transformDataValue(data.tasktypename,"string"), "fieldType":"string"},
              
              
            ],
            'dataList': [
                
              ]
          }

          taskList.push(card);
  })
  return taskList;
}