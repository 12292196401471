import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FactoryIcon from '@mui/icons-material/Factory';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MforderInfo from './MforderInfo';
import MfproductorderDashboard from 'components/mfproductorder/MfproductorderDashboard';
import { activeItem, openDrawer } from 'store/reducers/menu';
import { setMfproductorderDashboardMfproductorderList, setMforderOrderToFactoryModal, setMfpartyManagePartyModal, updateMforderObj, setMforderManageMfOrderModal } from 'store/slice/ManufSlice';
import OrderToFactoryActionModal from '../action/orderToFactory/OrderToFactoryActionModal';
import ManagePartyActionModal from 'components/mfparty/action/ManageParty/ManagePartyActionModal';
import RemarksMgmt from '../action/UpdateRemarks/RemarksMgmt';

import SelectPartyActionModal from 'core/components/party-mgmt/action/SelectParty/SelectPartyActionModal';
import { setMfpartySelectPartyModal } from 'store/slice/OrderMgmtSlice';
import { getAPI as getPartyObj } from 'components/mfparty/MfpartyAPI';
import { setPartyObj, setOrderObj } from 'store/slice/OrderMgmtSlice';
import UpdateRemarksActionModal from '../action/UpdateRemarks/UpdateRemarksActionModal';
import { setOrderExpanded } from 'store/slice/OrderMgmtSlice';

export default function MforderPageImpl() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { mforderObj } = useSelector((state) => state.manufslice.mforderDashboard);
    const { mfproductorderList } = useSelector((state) => state.manufslice);
    const { mfproductorderDashboard } = useSelector((state) => state.manufslice);
    const { orderObj, partyObj,orderExpanded } = useSelector((state) => state.ordermgmtslice);
    
    useEffect(()=>{
        dispatch(setMfproductorderDashboardMfproductorderList(
            mfproductorderList.filter(productObj => productObj.orderid == mforderObj.id)
        ))
    },[mfproductorderList, mforderObj])

    useEffect(() => {
        dispatch(setOrderExpanded(true));
        dispatch(setOrderObj(mforderObj));
        getPartyObj(mforderObj.partyid, getPartyObjSuccess)
        dispatch(setMfproductorderDashboardMfproductorderList(
            mfproductorderList.filter(productObj => productObj.orderid == mforderObj.id)
        ))
    }, [])

    useEffect(() => {
        if (orderObj && orderObj.id!==undefined)
            dispatch(updateMforderObj(orderObj));
        //getPartyObj(orderObj.partyid, getPartyObjSuccess);
    }, [orderObj]) // Party is updated in Order Obj managed by order management slice, hence need to update order object in manuf order List

    const getPartyObjSuccess = (partyObj) => {
        dispatch(setPartyObj(partyObj));
    }

    const backToOrderList = () => {
        dispatch(setMforderManageMfOrderModal(false));
        //navigate('/mforder');
        // dispatch(openDrawer({ drawerOpen: true }))
    }
    const sendOrderToFactory = () => {
        dispatch(setMforderOrderToFactoryModal(true));
    }
    const selectParty = () => {
        dispatch(setMfpartySelectPartyModal(true));
    }
    const handleClick = () =>{
        //setExpand(!expand);
        dispatch(setOrderExpanded(!orderExpanded));
    }
    return (
        <>
            <Grid container direction="column">
                <Grid item lg={12}>
                    <Grid container direction="row" sx={{ justifyContent: "space-between", alignItems: "flex-start", }}>
                        <Grid item lg={12} xs={12} sx={{backgroundColor:orderExpanded?'':'secondary.200'}}>
                            <Grid container >
                                <Grid item lg={11} xs={11}>
                                    <Typography variant="h5">Order Info</Typography>
                                </Grid>
                                <Grid item lg={1} xs={1} onClick={()=>handleClick()} sx={{ ml: "auto", textAlign: "right", cursor:'pointer' }}>
                                    {orderExpanded?<ExpandLessIcon />:<ExpandMoreIcon/>}
                                </Grid>
                            </Grid>
                        </Grid>
                        {orderExpanded?<>
                        <Grid item lg={12} sx={{ borderRadius: 2, backgroundColor: 'secondary.200' }}><MforderInfo /></Grid>
                        </>:<></>}


                    </Grid>
                </Grid>
                <Grid item lg={12}>
                    <MfproductorderDashboard />
                </Grid>
                <Grid item lg={12} sx={{paddingTop:2}}>
                    <Grid
                        container
                        direction="row"
                        sx={{ justifyContent: "space-between", alignItems: "flex-end" }}
                    >
                        <Grid item lg={4} xs={5}>
                            <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={backToOrderList}>
                                List
                            </Button>
                        </Grid>
                        <Grid item lg={4} xs={5} sx={{ ml: "auto", textAlign: "right" }}>
                            <Button variant="contained" startIcon={<FactoryIcon />} onClick={sendOrderToFactory}>
                                Initiate
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <OrderToFactoryActionModal />
            <ManagePartyActionModal />
            <SelectPartyActionModal />
            <UpdateRemarksActionModal />
        </>
    )

}