import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector, useDispatch } from 'react-redux';

import UpdateStatusActionModalContainer from './UpdateStatusActionModalContainer';
import {setTaskinstanceUpdateStatusModal} from '../../../../store/slice/ManufSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  width: { lg: '85%', xs: '95%' },
  height: { lg: '85%', xs: '90%' },
  borderRadius: 3,
  //overflow:'scroll',
  p: .5,
  backgroundColor:'secondary.lighter',
};

export default function UpdateStatusActionModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);;
  const {UpdateStatusModalOpen, taskinstanceObj} = useSelector((state)=>state.manufslice.taskinstanceDashboard);
  const {mfproductorderObj} = useSelector((state)=>state.manufslice.mfproductorderDashboard);

  useEffect(()=>{
  },[UpdateStatusModalOpen])

  const handleModalClose=()=>{
    handleClose();
    dispatch(setTaskinstanceUpdateStatusModal(false));
  }
  return (
    <Modal
       open={UpdateStatusModalOpen===undefined?false:UpdateStatusModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} >

        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5"  >
            [{taskinstanceObj && taskinstanceObj.title}] Status Update  
            </Typography>
          </Grid>
          <Grid item>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => handleModalClose()} />
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
            < UpdateStatusActionModalContainer/>
        </Grid>

      </Box>
    </Modal>
  );
}