import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box, Typography} from '@mui/material';
import FieldView2 from 'core/components/layout/FieldView2';


export default function MfproductorderInfo(){
  const { taskinstanceObj} = useSelector((state)=>state.manufslice.taskinstanceDashboard);
  const { mfproductorderList} = useSelector((state)=>state.manufslice);

  const [productOrder, setProductOrder] = useState({})
  useEffect(()=>{
    const index = mfproductorderList.findIndex(mfproductorder=>mfproductorder.orderid===taskinstanceObj.orderid && mfproductorder.productid===taskinstanceObj.productid)
    setProductOrder(mfproductorderList[index]);
  },[])

  const getRenderingComponent = () =>{
    if (productOrder.stockready){
      if (productOrder.withprinting){
        return <Grid item lg={4} xs={4}><FieldView2 color="white" label="Printing Type"  value={ productOrder.printingtype?'Digital':'Screen' }/></Grid>
      }
    } else {
        return <>
          <Grid item lg={4} xs={4}><FieldView2 color="white" label="Stiching Type"  value={ productOrder.stichingtype?'Simple':'Complex' }/></Grid>
          <Grid item lg={4} xs={4}><FieldView2 color="white" label="Printing Type"  value={ productOrder.printingtype?'Digital':'Screen' }/></Grid>
        </>
    }
  }
    return (
      <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid ><Typography variant="h5" color="primary.darker">Product Info</Typography></Grid>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: { xs: '20vh', lg: '60vh' } }}>
        
        <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{backgroundColor:'white'}}
      >
        <Grid item lg={4} xs={4}><FieldView2 color="white" label="Product"  value={ productOrder.productname!==null && productOrder.productname!==undefined?productOrder.productname:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={4}><FieldView2 color="white" label="Matter"  value={ productOrder.matter!==null && productOrder.matter!==undefined?productOrder.matter:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={4}><FieldView2 color="white" label="Qty"  value={ productOrder.qty!==null && productOrder.qty!==undefined?productOrder.qty:'NOT KNONWN' }/></Grid>
        {getRenderingComponent()}
        <Grid item lg={4} xs={4}><FieldView2 color="white" label="Remarks"  value={ productOrder.remarks}/></Grid>
        <Grid item lg={4} xs={4}><FieldView2 color="white" label="Status"  value={ productOrder.productorderstatusid}/></Grid>
        {/* <Grid item lg={1} xs={4}><FieldView color="white" label="Priority"  value={ mfproductorderObj.deliverypriorityname!==null && mfproductorderObj.deliverypriorityname!==undefined?mfproductorderObj.deliverypriorityname:'NOT KNONWN' }/></Grid> */}
        </Grid>
      </Grid>
      </Box>
    )
}