import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip, Divider } from "@mui/material";
import Cutting from "../assets/cutter.png"; // Import local images
import Printing from "../assets/printer.png";
import Stitching from "../assets/sewing.png";
import Design from '../assets/drawing.png';
import RmCheck from '../assets/rmcheck.png';
import AccountCheck from '../assets/accountcheck.png';
import Packaging from '../assets/packaging.png';
import { useSelector, useDispatch } from 'react-redux';
import { getTaskinstanceInfoByOrderidandproductidAPI } from "components/taskinstance/TaskinstanceAPI";
import { setTaskinstanceUpdateStatusModal } from "store/slice/ManufSlice"; 

export default function MfproductOrderProgressUnderOrder({ productId, orderId }) {
    const dispatch = useDispatch();
    const [tastList, setTaskList] = useState([]);
    const {taskinstanceObj} = useSelector((state)=>state.manufslice.taskinstanceDashboard);
    useEffect(() => {
        getTaskinstanceInfoByOrderidandproductidAPI( productId, orderId,setTaskList, () => { })
    }, [])

    const openStatusUpdate = () =>{
        dispatch(setTaskinstanceUpdateStatusModal(true));
    }

    const getTaskImage = (taskObj) => {
        if (taskObj.taskname === 'Cutting') return Cutting;
        if (taskObj.taskname === 'Printing') return Printing;
        if (taskObj.taskname === 'Stitching') return Stitching;
        if (taskObj.taskname === 'Design Approval') return Design;
        if (taskObj.taskname === 'Finance Check') return AccountCheck;
        if (taskObj.taskname === 'RM Check') return RmCheck;
        if (taskObj.taskname === 'Packaging') return Packaging;

    }
    return (

        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ padding:.5,paddingTop:1}}>
            <Grid item lg={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item lg={12} sx={{  borderRadius: 5 }}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" >
                            {tastList
                                .filter((task) => task.taskstatusname === 'TODO')
                                .map((task) => (
                                    <Grid item sx={{ padding: 0.5, backgroundColor: 'secondary.200', borderTop:task.taskstatusname==="TODO"?'1px solid #91d5ff':'', borderBottom:task.taskstatusname==="TODO"?'1px solid #91d5ff':''}}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                width: '30px',
                                                height: '30px',  // ✅ Ensure height for centering
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                display: "flex",
                                                
                                                //cursor:'pointer'
                                            }}
                                            // onClick={()=>openStatusUpdate()}
                                        >
                                            <Tooltip title={task.taskname} arrow>
                                                <Avatar
                                                    src={getTaskImage(task)}
                                                    sx={{ width: 25, height: 25, padding: 0.5 }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ))}
                            {tastList
                            .filter((task) => task.taskstatusname === 'WIP').length>0?
                            <Grid item sx={{ padding: 1, }}></Grid>:<></>}
                            {tastList
                                .filter((task) => task.taskstatusname === 'WIP')
                                .map((task) => (
                                    <Grid item sx={{ padding: 0.5, backgroundColor: 'secondary.200',borderTop:task.taskstatusname==="WIP"?'1px solid #1890ff':'', borderBottom:task.taskstatusname==="WIP"?'1px solid #1890ff':'' }}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                width: '30px',
                                                height: '30px',  // ✅ Ensure height for centering
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                display: "flex",
                                                //cursor:'pointer',
                                                border:'2px solid #69c0ff'
                                            }}
                                            // onClick={()=>openStatusUpdate()}
                                        >
                                            <Tooltip title={task.taskname} arrow>
                                                <Avatar
                                                    src={getTaskImage(task)}
                                                    sx={{ width: 25, height: 25, padding: 0.5 }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ))}
                        {tastList
                            .filter((task) => task.taskstatusname === 'DONE').length>0?
                            <Grid item sx={{ padding: 1, }}></Grid>:<></>}

                            {tastList
                                .filter((task) => task.taskstatusname === 'DONE')
                                .map((task) => (
                                    <Grid item sx={{ padding: 0.5, backgroundColor: 'secondary.200',borderTop:task.taskstatusname==="DONE"?'1px solid #0050b3':'', borderBottom:task.taskstatusname==="DONE"?'1px solid #0050b3':'' }}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                width: '30px',
                                                height: '30px',  // ✅ Ensure height for centering
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                display: "flex",
                                               // cursor:'pointer',
                                                border:'2px solid #0050b3'
                                            }}
                                            // onClick={()=>openStatusUpdate()}
                                        >
                                            <Tooltip title={task.taskname} arrow>
                                                <Avatar
                                                    src={getTaskImage(task)}
                                                    sx={{ width: 25, height: 25, padding: 0.5 }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ))}

                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
        </Grid>

    );
};
