import { v4 as uuidv4 } from 'uuid';

 var MfpartyResourcePrefix = "https://appmachine.in/manuf-oms-api/mfparty";
 var MfpartysResourcePrefix = "https://appmachine.in/manuf-oms-api/mfpartys";

 var manufOmsWebServicesPrefix = "https://appmachine.in/manuf-oms-service";


export function getListAPI(setMfpartyList, dataFetchCallBack){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'GET',
      headers: {
        'X-Request-ID': requestId,
        'Authorization': `Bearer ${token}`
    }
    };
    fetch(MfpartysResourcePrefix, requestOptions)
        .then((data) => data.json())
        .then((data) => {setMfpartyList(data.list);dataFetchCallBack(data.list)})
}
export function updateOrderPartyAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/updateorderparty", requestOptions)
        .then(async function(response) {
            let errorMessage = "Something Went Wrong"
            if(response.ok)
            {
                return response.json();         
            }
            else {
                if (response.status===208){
                    errorMessage = "Record already exist";
                } else if (403 === response.status) {
                    errorMessage = "Access Denied"
                }else if (400 === response.status) {
                    return response.json().then((errorData) => {
                        console.error('400 Error:', errorData);
                        errorMessage=errorData.message;
                        throw new Error(errorMessage);
                      });
                }
                else if (500 === response.status) {
                } 
            }
            throw new Error(errorMessage);
        })
        .then((response) => {
            successCallBack(response);
        })
        .catch(function(error) { 
            console.log('Request failed', error.message);
            failuerCallback(error.message);
        });
}export function updateOrderRemarksAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/orderremarks", requestOptions)
        .then(async function(response) {
            let errorMessage = "Something Went Wrong"
            if(response.ok)
            {
                return response.json();         
            }
            else {
                if (response.status===208){
                    errorMessage = "Record already exist";
                } else if (403 === response.status) {
                    errorMessage = "Access Denied"
                }else if (400 === response.status) {
                    return response.json().then((errorData) => {
                        console.error('400 Error:', errorData);
                        errorMessage=errorData.message;
                        throw new Error(errorMessage);
                      });
                }
                else if (500 === response.status) {
                } 
            }
            throw new Error(errorMessage);
        })
        .then((response) => {
            successCallBack(response);
        })
        .catch(function(error) { 
            console.log('Request failed', error.message);
            failuerCallback(error.message);
        });
}
