import { useState, useEffect } from 'react';
import { Grid, Typography, Divider, Button } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';

import UpdateProductOrderStatusActionImpl from './UpdateProductOrderStatusActionImpl';

export default function UpdateProductOrderStatusActionModalContainer() {
  const dispatch = useDispatch();
  
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >

      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{width:'100%'}}
          spacing="5"
        >
          <Grid item lg={12} sx={{width:'100%'}}>
            <UpdateProductOrderStatusActionImpl />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}