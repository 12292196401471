import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MfdeliverypriorityEditForm from './MfdeliverypriorityEditForm';
import MfdeliverypriorityList from './MfdeliverypriorityList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MfdeliverypriorityListView from './MfdeliverypriorityListView'


export default function MfdeliverypriorityDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.mfdeliverypriorityDashboard);
  const [ mfdeliverypriorityId, setMfdeliverypriorityId] = React.useState('');
  const [reloadMfdeliverypriorityList, setReloadMfdeliverypriorityList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MfdeliverypriorityEditForm 
            setReloadMfdeliverypriorityList={setReloadMfdeliverypriorityList}
            mfdeliverypriorityId={mfdeliverypriorityId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MfdeliverypriorityListView 
                      setMfdeliverypriorityId = {setMfdeliverypriorityId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
