import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography, Divider, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { setMforderAddMfOrderModal, setMforderManageMfOrderModal, setSelectedMforderId } from 'store/slice/ManufSlice';
import ManagePartyActionModal from '../../../mfparty/action/ManageParty/ManagePartyActionModal';
import { getListAPI as getPartyList } from '../../../mfparty/MfpartyAPI';
import { setMode, setOrderObj,setPartyObj } from 'store/slice/OrderMgmtSlice';
import SelectPartyActionModal from '../../../mfparty/action/SelectParty/SelectPartyActionModal';
import MfpartySelection from 'core/components/party-mgmt/action/SelectParty/MfpartySelection';
import { addMforderObj } from 'store/slice/ManufSlice';
import { orderAPI } from 'api/OmsFactoryopsWebServices';



export default function AddMfOrderActionImpl() {
    const dispatch = useDispatch();
    const [partyId, setPartyId] = useState(0);
    const [partyList, setPartyList] = useState([])
    const { mfpartyObj, ManagePartyModalOpen } = useSelector((state) => state.manufslice.mfpartyDashboard);
    const { mforderObj, } = useSelector((state) => state.manufslice.mforderDashboard);
    const { mode, refreshProductList, partyObj } = useSelector((state) => state.ordermgmtslice);
    const [apiInProgress, setApiInProgress] = useState(false);

    useEffect(()=>{
        dispatch(setOrderObj({}));
        dispatch(setPartyObj({}));
        if (!partyObj.id){
            //dispatch(setMfpartySelectPartyModal(true));
        }
    },[])
    useEffect(()=>{

    },[mode, partyObj])
    const editParty = ()=>{
        dispatch(setMode("EDIT-PARTY"));
    }

    
    const addOrderSuccess = (apiResponse) =>{
        setApiInProgress(false);
        dispatch(addMforderObj(apiResponse.mforderInfo));
        dispatch(setSelectedMforderId(apiResponse.mforderInfo.id))
        dispatch(setMforderManageMfOrderModal(true));
        dispatch(setMforderAddMfOrderModal(false));
    }
    
    const addOrderFailure = (ErrorMessage) =>{
        setApiInProgress(false);
    alert("!!! ERROR =>"+ErrorMessage);
    }

    const initiateOrder = () =>{
        
    
        if (mforderObj.id===undefined){
            setApiInProgress(true);
            orderAPI({
            "partyId":partyObj.id
            },addOrderSuccess, addOrderFailure)
        } 
    }
        
    const getComponent = () => {
        if (mode==="EDIT-PARTY")    return <MfpartySelection/>;
            if (partyObj.id){
                return <>
                <Grid item lg={12} xs={12} sx={{ backgroundColor: 'secondary.200', padding:1}}>
                    <Grid container direction="column">
                        <Grid item sx={{paddingBottom:1}}> 
                            <Typography color="primary" variant="h5">Selected Party</Typography>
                        </Grid>
                        <Grid item sx={{ backgroundColor: 'white', padding: 2, borderRadius:2 }}>
                            <Typography color="primary.darker" variant="h6">{partyObj.title}</Typography>
                        </Grid>
                        <Grid container justifyContent="flex-end" sx={{paddingTop:2}}>
                            <Button variant="outlined" onClick={()=>editParty()}>Edit</Button>
                        </Grid>
                    </Grid>
                   
                </Grid>
                <Grid container justifyContent="center" sx={{padding:5}}>
                    <Grid item xs={12} sx={{padding:1, borderTop:'1px solid #bae7ff'}}>
                        <Divider/>
                    </Grid>
                    <Grid item >
                        {apiInProgress?<CircularProgress/>:<Button variant="contained" onClick={()=>initiateOrder()}>Initiate Order</Button>}
                    </Grid>
                </Grid>
            </>
            }
        
            return <MfpartySelection/>
        
    }
    return (
        <Grid container  justifyContent="space-between">
            {getComponent()}
            <SelectPartyActionModal/>
            <ManagePartyActionModal />
         </Grid>
    )
}