import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import MfprintingstatusDashboard from 'components/mfprintingstatus/MfprintingstatusDashboard';
import MfprintingstatusPage from 'components/mfprintingstatus/page/MfprintingstatusPage';
import MfstitchingstatusDashboard from 'components/mfstitchingstatus/MfstitchingstatusDashboard';
import MfstitchingstatusPage from 'components/mfstitchingstatus/page/MfstitchingstatusPage';
import MfdigitalstatusDashboard from 'components/mfdigitalstatus/MfdigitalstatusDashboard';
import MfdigitalstatusPage from 'components/mfdigitalstatus/page/MfdigitalstatusPage';
import MfdeliverypriorityDashboard from 'components/mfdeliverypriority/MfdeliverypriorityDashboard';
import MfdeliverypriorityPage from 'components/mfdeliverypriority/page/MfdeliverypriorityPage';
import MfprintingtypeDashboard from 'components/mfprintingtype/MfprintingtypeDashboard';
import MfprintingtypePage from 'components/mfprintingtype/page/MfprintingtypePage';
import MforderstatusDashboard from 'components/mforderstatus/MforderstatusDashboard';
import MforderstatusPage from 'components/mforderstatus/page/MforderstatusPage';
import MforderDashboard from 'components/mforder/MforderDashboard';
import MforderPage from 'components/mforder/page/MforderPage';
import MfpartyDashboard from 'components/mfparty/MfpartyDashboard';
import MfpartyPage from 'components/mfparty/page/MfpartyPage';
import MfproductorderDashboard from 'components/mfproductorder/MfproductorderDashboard';
import MfproductorderPage from 'components/mfproductorder/page/MfproductorderPage';
import MfproductDashboard from 'components/mfproduct/MfproductDashboard';
import MfproductPage from 'components/mfproduct/page/MfproductPage';
import MfproductorderstatusDashboard from 'components/mfproductorderstatus/MfproductorderstatusDashboard';
import MfproductorderstatusPage from 'components/mfproductorderstatus/page/MfproductorderstatusPage';
import BusinessprocessDashboard from 'components/businessprocess/BusinessprocessDashboard';
import BusinessprocessPage from 'components/businessprocess/page/BusinessprocessPage';
import TaskDashboard from 'components/task/TaskDashboard';
import TaskPage from 'components/task/page/TaskPage';
import TaskinstanceDashboard from 'components/taskinstance/TaskinstanceDashboard';
import TaskinstancePage from 'components/taskinstance/page/TaskinstancePage';
import TasktypeDashboard from 'components/tasktype/TasktypeDashboard';
import TasktypePage from 'components/tasktype/page/TasktypePage';
import FactoryroleDashboard from 'components/factoryrole/FactoryroleDashboard';
import FactoryrolePage from 'components/factoryrole/page/FactoryrolePage';
import TaskstatusDashboard from 'components/taskstatus/TaskstatusDashboard';
import TaskstatusPage from 'components/taskstatus/page/TaskstatusPage';
import MforderProgressPage from 'components/mforder/page/MforderProgressPage';
import MfOrderDashboard from 'components/mforder/page/Dashboard/MfOrderDashboard';
import MforderListPage from 'components/mforder/page/MforderListPage';
import MforderPageArchived from 'components/mforder/page/MforderPageArchived';



// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },

    {path: '/mfprintingstatus',element: <MfprintingstatusDashboard />},
{path: '/mfprintingstatuspage',element: <MfprintingstatusPage />},
{path: '/mfstitchingstatus',element: <MfstitchingstatusDashboard />},
{path: '/mfstitchingstatuspage',element: <MfstitchingstatusPage />},
{path: '/mfdigitalstatus',element: <MfdigitalstatusDashboard />},
{path: '/mfdigitalstatuspage',element: <MfdigitalstatusPage />},
{path: '/mfdeliverypriority',element: <MfdeliverypriorityDashboard />},
{path: '/mfdeliveryprioritypage',element: <MfdeliverypriorityPage />},
{path: '/mfprintingtype',element: <MfprintingtypeDashboard />},
{path: '/mfprintingtypepage',element: <MfprintingtypePage />},
{path: '/mforderstatus',element: <MforderstatusDashboard />},
{path: '/mforderstatuspage',element: <MforderstatusPage />},
{path: '/mforder',element: <MforderDashboard />},
{path: '/mforderpage',element: <MforderListPage />},
{path: '/mfparty',element: <MfpartyDashboard />},
{path: '/mfpartypage',element: <MfpartyPage />},
{path: '/mfproductorder',element: <MfproductorderDashboard />},
{path: '/mfproductorderpage',element: <MfproductorderPage />},
{path: '/mfproduct',element: <MfproductDashboard />},
{path: '/mfproductpage',element: <MfproductPage />},
{path: '/mfproductorderstatus',element: <MfproductorderstatusDashboard />},
{path: '/mfproductorderstatuspage',element: <MfproductorderstatusPage />},
{path: '/businessprocess',element: <BusinessprocessDashboard />},
{path: '/businessprocesspage',element: <BusinessprocessPage />},
{path: '/task',element: <TaskDashboard />},
{path: '/taskpage',element: <TaskPage />},
{path: '/taskinstance',element: <TaskinstanceDashboard />},
{path: '/taskinstancepage',element: <TaskinstancePage />},
{path: '/tasktype',element: <TasktypeDashboard />},
{path: '/tasktypepage',element: <TasktypePage />},
{path: '/factoryrole',element: <FactoryroleDashboard />},
{path: '/factoryrolepage',element: <FactoryrolePage />},
{path: '/taskstatus',element: <TaskstatusDashboard />},
{path: '/taskstatuspage',element: <TaskstatusPage />},
{path: '/showprogress',element: <MforderProgressPage />},
{path: '/orderdashboard',element: <MfOrderDashboard />},
{path: '/orderarchived',element: <MforderPageArchived />},



    {
      path: 'color',
      element: <Color />
    },

    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    }
  ]
};

export default MainRoutes;