import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MforderFilterCriteria from './MforderFilterCriteria'
import MforderListImpl from './MforderListImpl'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MforderAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMforderDashboardMforderList } from '../../store/slice/ManufSlice';

export default function MforderListView({setShowEdit,setMforderId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mforderDashboard);   

    const [filteredMforderList, setFilteredMforderList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mforderDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mforderDashboard.mforderList.length>-1){
        setFilteredMforderList(mforderDashboard.mforderList);
        transformToCardFormat(mforderDashboard.mforderList, setCardList);
      }
    },[mforderDashboard.mforderList])

    useEffect(()=>{
          transformToCardFormat(filteredMforderList, setCardList);
      },[filteredMforderList])

    useEffect(()=>{
        if (filterCriteria.partyid==='' && filterCriteria.partyname==='' && filterCriteria.orderdate==='' && filterCriteria.executive==='' && filterCriteria.orderstatusid==='' && filterCriteria.orderstatusname==='' ){
            setFilteredMforderList(mforderDashboard.mforderList);
            transformToCardFormat(mforderDashboard.mforderList, setCardList);

        } else {
            setFilteredMforderList(
                mforderDashboard.mforderList
                    .filter(mforder=> mforder.partyname.toString().toUpperCase().includes(filterCriteria.partyname.toString().toUpperCase()))
.filter(mforder=> mforder.executive.toString().toUpperCase().includes(filterCriteria.executive.toString().toUpperCase()))
.filter(mforder=> mforder.orderstatusid.toString().toUpperCase().includes(filterCriteria.orderstatusid.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mforderDashboard.filteredMforderList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MforderListImpl
                    setShowEdit={setShowEdit}
                    setMforderId = {setMforderId}
                    tableList={filteredMforderList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}