import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function MfproductorderListTableHeader(){
    const {mobileView} = useSelector((state)=>state.menu);
    useEffect(()=>{
      
    },[])
    return (
      
      <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "center", width: '100%', padding: 1, backgroundColor: 'primary.lighter' }}>

      <Grid item lg={2} xs={4} ><Typography variant="h5"> Product </Typography></Grid>
      <Grid item lg={2} xs={3} ><Typography variant="h5"> Matter </Typography></Grid>
      
      {!mobileView?<>
        <Grid item lg={1} xs={3} ><Typography variant="h5"> Qty </Typography></Grid>
        <Grid item lg={2} xs={3} ><Typography variant="h5"> Priority</Typography></Grid>
        <Grid item lg={2} xs={4} ><Typography variant="h5"> Expected </Typography></Grid>
        <Grid item lg={2} xs={4} ><Typography variant="h5"> Remarks </Typography></Grid>
        
      </>
      :<></>}
                        

      </Grid>
      
    )
}