import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MfdigitalstatusFilterCriteria from './MfdigitalstatusFilterCriteria'
import MfdigitalstatusList from './MfdigitalstatusList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MfdigitalstatusAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMfdigitalstatusDashboardMfdigitalstatusList } from '../../store/slice/ManufSlice';

export default function MfdigitalstatusListView({setShowEdit,setMfdigitalstatusId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mfdigitalstatusDashboard);   

    const [filteredMfdigitalstatusList, setFilteredMfdigitalstatusList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mfdigitalstatusDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mfdigitalstatusDashboard.mfdigitalstatusList.length>-1){
        setFilteredMfdigitalstatusList(mfdigitalstatusDashboard.mfdigitalstatusList);
        transformToCardFormat(mfdigitalstatusDashboard.mfdigitalstatusList, setCardList);
      }
    },[mfdigitalstatusDashboard.mfdigitalstatusList])

    useEffect(()=>{
          transformToCardFormat(filteredMfdigitalstatusList, setCardList);
      },[filteredMfdigitalstatusList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredMfdigitalstatusList(mfdigitalstatusDashboard.mfdigitalstatusList);
            transformToCardFormat(mfdigitalstatusDashboard.mfdigitalstatusList, setCardList);

        } else {
            setFilteredMfdigitalstatusList(
                mfdigitalstatusDashboard.mfdigitalstatusList
                    .filter(mfdigitalstatus=> mfdigitalstatus.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mfdigitalstatusDashboard.filteredMfdigitalstatusList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MfdigitalstatusList
                    setShowEdit={setShowEdit}
                    setMfdigitalstatusId = {setMfdigitalstatusId}
                    tableList={filteredMfdigitalstatusList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}