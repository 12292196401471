import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import {setFilter} from '../../store/slice/ConfSlice';
import ManufStoreLoader from 'layout/ManufStoreLoader';
import ProfileMgmtModal from 'core/components/profile-mgmt/ProfileMgmtModal';


// types
import { openDrawer,setMobileView } from 'store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);
  const {userprofileInfo} = useSelector((state)=>state.headerslice)
  
  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
      dispatch(setMobileView({mobileView:matchDownLG}))
    /*
    if (userprofileInfo.rolename!=='PHARMA_OMS_SALES'){
      setOpen(false);
      dispatch(openDrawer({ drawerOpen: false }));
      dispatch(setMobileView({mobileView:true}))
    }
    else {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
      dispatch(setMobileView({mobileView:matchDownLG}))
    }
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        {/* <Breadcrumbs navigation={navigation} title /> */}
        <Outlet />
      </Box>
      <ManufStoreLoader />
      <ProfileMgmtModal/>
    </Box>
  );
};

export default MainLayout;