import {useState, useEffect} from 'react';
import { Grid, Button, Typography } from '@mui/material';
import MfpartyEditFormImpl from 'components/mfparty/MfpartyEditFormImpl';

export default function ManagePartyActionImpl(){

    return (
        <>
        <MfpartyEditFormImpl/>
        </>
    )
}