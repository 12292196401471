import {useState, useEffect} from 'react';

import MforderPageImpl from 'components/mforder/page/MforderPageImpl';
export default function ManageMfOrderActionImpl(){

    return (
        <>
        <MforderPageImpl/>
        </>
    )
}