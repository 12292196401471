import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip, Divider, Button } from "@mui/material";
import Cutting from "../assets/cutter.png"; // Import local images
import Printing from "../assets/printer.png";
import Stitching from "../assets/sewing.png";
import Design from '../assets/drawing.png';
import RmCheck from '../assets/rmcheck.png';
import AccountCheck from '../assets/accountcheck.png';
import Packaging from '../assets/packaging.png';
import { useNavigate } from "react-router-dom";
import { setTaskinstanceList, setSelectedTaskinstanceId } from "store/slice/ManufSlice";

import { getTaskinstanceInfoByOrderIdAPI } from "components/taskinstance/TaskinstanceAPI";
import { useSelector,useDispatch } from 'react-redux';
import { getMfproductorderInfoByOrderIdAPI } from 'components/mfproductorder/MfproductorderAPI';
import { getTaskinstanceInfoByOrderidandproductidAPI } from "components/taskinstance/TaskinstanceAPI";
import MfproductOrderProgress from "./MfproductOrderProgress";

// const tasks = [
//     { id: 1, title: "Order Received", image: CutterImg, status: "TODO" },
//     { id: 2, title: "Processing Order", image: PrinterImg, status: "TODO" },
//     { id: 3, title: "Order Shipped", image: SewingImg, status: "DONE" }
// ];

const MforderProgressPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { mforderObj } = useSelector((state) => state.manufslice.mforderDashboard);
    const [tastList, setTaskList] = useState([]);
    const [productOrderList, setProductOrderList] = useState([]);
    useEffect(() => {
        dispatch(setTaskinstanceList([]));
        dispatch(setSelectedTaskinstanceId(-1))
        getMfproductorderInfoByOrderIdAPI(mforderObj.id, setProductOrderList, () => { })
    }, [])

    const getTaskImage = (taskObj) => {
        if (taskObj.taskname === 'Cutting') return Cutting;
        if (taskObj.taskname === 'Printing') return Printing;
        if (taskObj.taskname === 'Stitching') return Stitching;
        if (taskObj.taskname === 'Design Approval') return Design;
        if (taskObj.taskname === 'Finance Check') return AccountCheck;
        if (taskObj.taskname === 'RM Check') return RmCheck;
        if (taskObj.taskname === 'Packaging') return Packaging;

    }
    return (

        <Grid container directin="column" justifyContent="flex-start" >
            <Grid item lg={12} xs={12}>
                <Typography variant="h4" color="primary.darker">Order # {mforderObj.id}</Typography>
            </Grid>
            {/* <Grid item lg={12} xs={12} sx={{padding:.5 }}>
                <Divider sx={{ backgroundColor: 'primary.light', height: '2px', }} />
            </Grid> */}
            <Grid item lg={12} xs={12}>
                <Grid container direction="row" >
                    <Grid item sx={{ width: '100%', paddingBottom: .5 }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ textAlign: 'center', }}>
                            <Grid item sx={{ backgroundColor: 'secondary.200', width: '30%', padding: 2 }}>TODO</Grid>
                            <Grid item sx={{ backgroundColor: 'secondary.200', width: '30%', padding: 2 }} >WIP</Grid>
                            <Grid item sx={{ backgroundColor: 'secondary.200', width: '30%', padding: 2 }}>DONE</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item lg={12} xs={12}>
                <Divider sx={{ backgroundColor: 'primary.light', height: '2px' }} />
            </Grid> */}

            <Box lg={12} sx={{ flexGrow: 1 }}>
                <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '58vh' }}>
                    <Grid item xs={12} lg={12}>
                        <Grid container direction="row" >
                            <Grid item sx={{ width: '100%' }}>
                                {
                                    productOrderList && productOrderList.map((productOrderObj) => {
                                        return (

                                            <Grid container sx={{ backgroundColor: 'white', padding: .5, }} justifyContent="flex-start">
                                                <Grid container lg={12} alignItems="center" >
                                                    <Typography variant="h5" color="primary.darker">{productOrderObj.productname}</Typography>
                                                </Grid>
                                                <Grid item lg={12} sx={{}}>
                                                    <MfproductOrderProgress orderId={mforderObj.id} productOrderObj={productOrderObj} />
                                                </Grid>
                                            </Grid>

                                        )
                                    })}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item lg={12} sx={{ padding: 1 }}>
                        <Divider sx={{ backgroundColor: 'primary.lighter', height: '2px' }} />
                    </Grid> */}
                </Grid>
            </Box>
            <Grid item lg={12} xs={12} sx={{padding:1 }}>
                <Divider sx={{ backgroundColor: 'primary.light', height: '2px', }} />
            </Grid>
            <Grid container justifyContent="flex-end">
                <Button variant="contained" onClick={()=>navigate('/')}>Order List</Button>
            </Grid>


        </Grid>

    );
};

export default MforderProgressPage;
