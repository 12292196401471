import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography,Divider} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import FactoryroleInfo from '../FactoryroleInfo';


export default function FactoryroleListTableRow({obj, getActionComponent,handleRowClick}){
  const {mobileView} = useSelector((state)=>state.menu);
  useEffect(()=>{
  },[])
  const getHoverColor = () =>{
    if (mobileView) return ''
    return 'primary.main';
  }
  const getHoverBackgroundColor = () =>{
    if (mobileView) return ''
    return 'secondary.200';
  }
  const getBackgroundColor = (obj) =>{
    if (mobileView) {
      if (obj.expand) return 'secondary.500'
      return 'white'
    }
    return 'white'
  }
    return (
      
      <>
        <Grid item lg={12} sx={{ width: '100%'}}>
          {/* <ROW1 - CONTAINER/> */}
          <Grid container direction="row" onClick={() => handleRowClick(obj.id)}
            sx={{cursor: 'pointer',
              justifyContent: "flex-start", alignItems: "center",  padding:1,  
              backgroundColor: getBackgroundColor(obj),
              color: mobileView && obj.expand?'primary.main':'primary.darker',
              '&:hover': {
                backgroundColor: getHoverBackgroundColor, 
                color: getHoverColor()
              },
            }}>
            {/* <ROW1 - CELLS/> */}
            
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.title}</Typography></Grid>

              <Grid item lg={2} xs={2} >
                <Grid container direction="row" sx={{ justifyContent: "flex-end", width: '100%', }}>
                  {getActionComponent(obj)}
                </Grid>
              </Grid>
            
          </Grid>
        </Grid>
        <Grid item lg={12} sx={{ width: '100%' }}></Grid>
        {obj.expand ?
        <Grid container direction="row">
          <Grid item sx={{ width: '100%' }}>
            < FactoryroleInfo  factoryroleObj={obj} />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>
        : <Grid container direction="row">
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>}
      </>
      
    )
}