import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MfstitchingstatusEditForm from './MfstitchingstatusEditForm';
import MfstitchingstatusList from './MfstitchingstatusList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MfstitchingstatusListView from './MfstitchingstatusListView'


export default function MfstitchingstatusDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.mfstitchingstatusDashboard);
  const [ mfstitchingstatusId, setMfstitchingstatusId] = React.useState('');
  const [reloadMfstitchingstatusList, setReloadMfstitchingstatusList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MfstitchingstatusEditForm 
            setReloadMfstitchingstatusList={setReloadMfstitchingstatusList}
            mfstitchingstatusId={mfstitchingstatusId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MfstitchingstatusListView 
                      setMfstitchingstatusId = {setMfstitchingstatusId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
