import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import { Button, Divider, TextField, Grid, Typography, Box, CircularProgress } from "@mui/material"

import { getMfproductorderStruct, setData } from './MfproductorderStruct'
import { updateTextValue, handleSubmit, updateValue, updateSelectedValue } from '../../core/js/FormUtils'
import { createAPI, updateAPI, getAPI, updateEditableAPI } from './MfproductorderAPI';
import { CoreContext } from "../../core/context/CoreContext";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { validMobileNumberEntry, validNameEntry, validEmailEntry } from '../../core/js/FormValidation';

import { getPathVariable } from '../../core/js/UrlUtils';
import { useSearchParams } from "react-router-dom";
import RadioForBoolean from '../../core/form/RadioForBoolean';
import MyDate from '../../core/components/MyDate';
import { useDispatch, useSelector } from 'react-redux';
import { updateMfproductorderObj, addMfproductorderObj, setShowEditForMfproductorder } from 'store/slice/ManufSlice';
import { setApiInProgress } from 'store/slice/HeaderSlice';
import RadioForStitchingType from './components/RadioForStitchingType';
import RadioForPrintingType from './components/RadioForPrintingType';


export default function MfproductorderEditForm() {
    const dispatch = useDispatch();
    const { setNotification, lastURL } = React.useContext(CoreContext);
    const [formValues, setFormValues] = useState(getMfproductorderStruct);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('Something went wrong');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { mforderObj } = useSelector((state) => state.manufslice.mforderDashboard);
    const [sortedList, setSortedList] = useState([]);
    const [stockReady, setStockReady] = useState(false);
    const [withPrint, setWithPrint] = useState(false);

    const { mfproductorderDashboard } = useSelector((state) => state.manufslice);
    const [mfproductorderId, setMfproductorderId] = useState(mfproductorderDashboard.selectedId);
    const { businessid } = useSelector((state) => state.headerslice.userprofileInfo)

    const { mfproductList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueForproductid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'productname', e.target.value == 0 ? 'Select Item' : mfproductList.find(obj => obj.id === e.target.value).title);
    }
    const { mfdeliverypriorityList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueFordeliverypriorityid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'deliverypriorityname', e.target.value == 0 ? 'Select Item' : mfdeliverypriorityList.find(obj => obj.id === e.target.value).title);
    }
    const { mfstitchingstatusList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueForstitchingstatusid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'stitchingstatusname', e.target.value == 0 ? 'Select Item' : mfstitchingstatusList.find(obj => obj.id === e.target.value).title);
    }
    const { mfdigitalstatusList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueFordigitalstatusid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'digitalstatusname', e.target.value == 0 ? 'Select Item' : mfdigitalstatusList.find(obj => obj.id === e.target.value).title);
    }
    const { mfproductorderstatusList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueForproductorderstatusid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'productorderstatusname', e.target.value == 0 ? 'Select Item' : mfproductorderstatusList.find(obj => obj.id === e.target.value).title);
    }

    const { apiInProgress } = useSelector((state) => state.headerslice)
    useEffect(() => {
    }, [apiInProgress])

    useEffect(()=>{
        setStockReady(formValues.stockready.value);
        setWithPrint(formValues.withprinting.value)
        
    },[formValues])
    useEffect(() => {
        if (mfproductorderId !== -1) {
            if (dataLoaded) return;
            getAPI(mfproductorderId, loadForm);
        }
        else {
            // formValues.<<CONTEXT>>.value=searchParams.get('<<CONTEXT>>');
            setDataLoaded(true);
        }
        setFormValues({
            ...formValues,
            ['businessid']: {
                ...formValues['businessid'],
                value: businessid
            },
            ['productorderstatusname']: {
                ...formValues['productorderstatusname'],
                value: 'DRAFT'
            },
            ['partyname']: {
                ...formValues['partyname'],
                value: mforderObj.partyname
            },
            ['orderid']: {
                ...formValues['orderid'],
                value: mforderObj.id
            }
        })
        
    }, []);
    const sortedProducts = [...mfproductList].sort((a, b) =>
        a.title.localeCompare(b.title)
    );

    const loadForm = (data) => {
        setData(data, formValues, setFormValues);
        
        setDataLoaded(true);
    }
    const handleRadioChange = (name, value) => {
        setShowError(false);
        console.log(value + " == " + getRadioAPIvalue(value))
        updateValue(formValues, setFormValues, name, getRadioAPIvalue(value));
    }
    const getRadioValue = (value) => {
        if (value)
            return "yes"
        else
            return "no"
    }
    const getRadioAPIvalue = (value) => {
        if (value === "yes")
            return true
        else
            return false
    }
    const handleTextChange = (e) => {
        setShowError(false);
        updateTextValue(formValues, setFormValues, e);
    }
    const handleDoubleChange = (e) => {
        setShowError(false);
        const parsed = parseFloat(e.target.value);
        if (!isNaN(parsed) && e.target.value == parsed)
            updateTextValue(formValues, setFormValues, e);
    }
    const handleNameChange = (e) => {
        setShowError(false);

        if (validNameEntry(e))
            updateTextValue(formValues, setFormValues, e);
    }
    const handleMobileChange = (e) => {
        setShowError(false);

        if (validMobileNumberEntry(e))
            updateTextValue(formValues, setFormValues, e);
    }
    const handleEmailidChange = (e) => {
        setShowError(false);

        if (validEmailEntry(e))
            updateTextValue(formValues, setFormValues, e);
    }

    const createSuccessCallback = (obj) => {
        dispatch(setApiInProgress(false));
        dispatch(addMfproductorderObj(obj));
        //setNotification('Record has been cretaed successfully !!!');
        dispatch(setShowEditForMfproductorder(false));
    }
    const updateSuccessCallback = (obj) => {
        dispatch(setApiInProgress(false));
        dispatch(updateMfproductorderObj(obj));
        //setNotification('Record with id[' + mfproductorderId + '] has been updated successfully !!!');
        dispatch(setShowEditForMfproductorder(false));

    }
    const failuerCallback = (errorMsg) => {
        dispatch(setApiInProgress(false));
        setErrorMsg(errorMsg);
        setShowError(true);
    }
    const callCreateAPI = (formValues, successCallBack, failuerCallback) => {
        dispatch(setApiInProgress(true));
        createAPI(formValues, successCallBack, failuerCallback);
    }
    const callUpdateAPI = (id, formValues, updateSuccessCallback, failuerCallback) => {
        dispatch(setApiInProgress(true));
        updateAPI(id, formValues, updateSuccessCallback, failuerCallback);
    }
    const onSubmit = (e) => {
        handleSubmit(e, mfproductorderId, formValues, setFormValues, createSuccessCallback, updateSuccessCallback, failuerCallback, callCreateAPI, callUpdateAPI);
    }
    const setDate = (fieldName, value) => {
        var date = new Date(value);

        const day = date.getDate();
        const dayStr = day < 10 ? '0' + day : day.toString();

        const month = date.getMonth() + 1;
        const monthStr = month < 10 ? '0' + month : month.toString();
        var dateStr = date.getFullYear() + "-" + monthStr + "-" + dayStr + "T00:00:00.000Z";
        updateValue(formValues, setFormValues, fieldName, dateStr);
    }
    const handleStockReady = (name, value) => {
        setShowError(false);
        updateValue(formValues, setFormValues, name, getRadioAPIvalue(value));
        setStockReady(getRadioAPIvalue(value));
    }
    const handleWithPrinting = (name, value) => {
        setShowError(false);
        updateValue(formValues, setFormValues, name, getRadioAPIvalue(value));
        setWithPrint(getRadioAPIvalue(value));
    }
    const getStockDependentComponent = () =>{
        if (stockReady){

            if (withPrint){
                return <>
                        <Grid item lg={2} xs={12}>
                            <RadioForBoolean
                                label={formValues.withprinting.label}
                                fieldName={formValues.withprinting.name}
                                defaultValue={getRadioValue(formValues.withprinting.value)}
                                handleChange={handleRadioChange}
                            />
                        </Grid>
                        <Grid item lg={2} xs={6}>
                        <RadioForPrintingType
                            label={formValues.printingtype.label}
                            fieldName={formValues.printingtype.name}
                            defaultValue={getRadioValue(formValues.printingtype.value)}
                            handleChange={handleWithPrinting}
                        />
                    </Grid>
                 </>
            } else {
                return <>
                        <Grid item lg={2} xs={12}>
                            <RadioForBoolean
                                label={formValues.withprinting.label}
                                fieldName={formValues.withprinting.name}
                                defaultValue={getRadioValue(formValues.withprinting.value)}
                                handleChange={handleRadioChange}
                            />
                        </Grid>
                        </>
            }
        } else {
            return <><Grid item lg={2} xs={6}>
                        <RadioForPrintingType
                            label={formValues.printingtype.label}
                            fieldName={formValues.printingtype.name}
                            defaultValue={getRadioValue(formValues.printingtype.value)}
                            handleChange={handleRadioChange}
                        />
                    </Grid>

                    <Grid item lg={2} xs={6}>
                        <RadioForStitchingType
                            label={formValues.stitchingtype.label}
                            fieldName={formValues.stitchingtype.name}
                            defaultValue={getRadioValue(formValues.stitchingtype.value)}
                            handleChange={handleRadioChange}
                        />
                    </Grid>
                    </>
        }
    }
    return (
        <form noValidate onSubmit={onSubmit} >
            <Grid container direction="column" >
                {showError ? <Alert severity="error">{errorMsg}</Alert> : ''}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '66vh' }}>
                        <Grid container direction="column" padding={.5}>
                            <Grid item>
                                <Typography variant='h4'>{mfproductorderId === -1 ? 'Add' : 'Edit'} Product </Typography>
                                <Divider />
                            </Grid>
                            <Grid height='20px' ></Grid>
                            {dataLoaded ?
                                <Grid container display="flex" spacing={2} direction="row" justifyContent="space-between" alignItems="center">

                                    <Grid item lg={3} xs={12}>
                                        <FormControl sx={{ width: '100%' }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">Product Name</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.productid.label}
                                                name={formValues.productid.name}
                                                value={formValues.productid.value}
                                                onChange={handleDynamicSelectedValueForproductid}

                                                error={formValues.productid.error}
                                                helpertext={formValues.productid.error && formValues.productid.errorMessage}
                                            >
                                                {/* <MenuItem key={0} value="0">Select Item</MenuItem> */}
                                                {
                                                    sortedProducts.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={1} xs={6}>
                                        <TextField size="Normal"
                                            placeholder={formValues.qty.placeholder}
                                            required={formValues.qty.required}
                                            label={formValues.qty.label}
                                            name={formValues.qty.name}
                                            value={formValues.qty.value}
                                            onChange={handleTextChange}
                                            error={formValues.qty.error}
                                            helpertext={formValues.qty.error && formValues.qty.errorMessage}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item lg={1} xs={6} sx={{ width:'100%' }}>
                                                        <FormControl sx={{  width:'100%'}} size="Normal">
                                                            <InputLabel id="demo-simple-select-autowidth-label">{formValues.unit.label}</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-autowidth-label"
                                                                id="demo-simple-select-autowidth"
                                                                label={formValues.unit.label}
                                                                name={formValues.unit.name}
                                                                value={formValues.unit.value}
                                                                onChange={handleTextChange}
                                                                
                                                                error={formValues.unit.error}
                                                                helperText={formValues.unit.error && formValues.unit.errorMessage}
                                                                >
                                                                
                                                                    <MenuItem value="SET">SET</MenuItem>
                                                                    <MenuItem value="PCs">PCs</MenuItem>
                                                            </Select>
                                                        </FormControl> 
                                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.matter.placeholder}
                                            required={formValues.matter.required}
                                            label={formValues.matter.label}
                                            name={formValues.matter.name}
                                            value={formValues.matter.value}
                                            onChange={handleTextChange}
                                            error={formValues.matter.error}
                                            helpertext={formValues.matter.error && formValues.matter.errorMessage}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>



                                    <Grid item lg={2} xs={6}>
                                        <FormControl sx={{ width: '100%' }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">Delivery Priority</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.deliverypriorityid.label}
                                                name={formValues.deliverypriorityid.name}
                                                value={formValues.deliverypriorityid.value}
                                                onChange={handleDynamicSelectedValueFordeliverypriorityid}

                                                error={formValues.deliverypriorityid.error}
                                                helpertext={formValues.deliverypriorityid.error && formValues.deliverypriorityid.errorMessage}
                                            >
                                                {/* <MenuItem key={0} value="0">Select Item</MenuItem> */}
                                                {
                                                    mfdeliverypriorityList && mfdeliverypriorityList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={2} xs={6}>
                                        <MyDate
                                            label={formValues.expecteddelivery.label}
                                            fieldName={formValues.expecteddelivery.name}
                                            defaultValue={formValues.expecteddelivery.value}
                                            errStr={formValues.expecteddelivery.errorMessage}
                                            setDate={setDate}
                                        />
                                    </Grid>
                                    <Grid item lg={2} xs={12}>
                                        <RadioForBoolean
                                            label={formValues.stockready.label}
                                            fieldName={formValues.stockready.name}
                                            defaultValue={getRadioValue(formValues.stockready.value)}
                                            handleChange={handleStockReady}
                                        />
                                    </Grid>
                                    {
                                        getStockDependentComponent()
                                    }


                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.remarks.placeholder}
                                            required={formValues.remarks.required}
                                            label={formValues.remarks.label}
                                            rows={2}
                                            multiline
                                            name={formValues.remarks.name}
                                            value={formValues.remarks.value}
                                            onChange={handleTextChange}
                                            error={formValues.remarks.error}
                                            helperText={formValues.remarks.error && formValues.remarks.errorMessage}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>


                                    <Grid item lg={4} xs={12} sx={{ width: '100%', display: 'none' }}>
                                        <TextField size="Normal"
                                            placeholder={formValues.deliverypriorityname.placeholder}
                                            required={formValues.deliverypriorityname.required}
                                            label={formValues.deliverypriorityname.label}
                                            name={formValues.deliverypriorityname.name}
                                            value={formValues.deliverypriorityname.value}
                                            onChange={handleTextChange}
                                            error={formValues.deliverypriorityname.error}
                                            helpertext={formValues.deliverypriorityname.error && formValues.deliverypriorityname.errorMessage}

                                        />
                                    </Grid>


                                    <Grid item lg={4} xs={12}>
                                        <FormControl sx={{ width: { lg: '100%', xs: '200px', display: 'none' } }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">{formValues.productorderstatusid.label}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.productorderstatusid.label}
                                                name={formValues.productorderstatusid.name}
                                                value={formValues.productorderstatusid.value}
                                                onChange={handleDynamicSelectedValueForproductorderstatusid}

                                                error={formValues.productorderstatusid.error}
                                                helpertext={formValues.productorderstatusid.error && formValues.productorderstatusid.errorMessage}
                                            >
                                                <MenuItem key={0} value="0">Select Item</MenuItem>
                                                {
                                                    mfproductorderstatusList && mfproductorderstatusList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sx={{ width: '100%', display: 'none' }}>
                                        <TextField size="Normal"
                                            placeholder={formValues.productorderstatusname.placeholder}
                                            required={formValues.productorderstatusname.required}
                                            label={formValues.productorderstatusname.label}
                                            name={formValues.productorderstatusname.name}
                                            value={formValues.productorderstatusname.value}
                                            onChange={handleTextChange}
                                            error={formValues.productorderstatusname.error}
                                            helpertext={formValues.productorderstatusname.error && formValues.productorderstatusname.errorMessage}

                                        />
                                    </Grid>

                                    <Grid item lg={4} xs={12} sx={{ width: '100%', display: 'none' }}>
                                        <TextField size="Normal"
                                            placeholder={formValues.partyname.placeholder}
                                            required={formValues.partyname.required}
                                            label={formValues.partyname.label}
                                            name={formValues.partyname.name}
                                            value={formValues.partyname.value}
                                            onChange={handleTextChange}
                                            error={formValues.partyname.error}
                                            helpertext={formValues.partyname.error && formValues.partyname.errorMessage}

                                        />
                                    </Grid>

                                    <Grid item lg={4} xs={12} sx={{ width: '100%', display: 'none' }}>
                                        <TextField size="Normal"
                                            placeholder={formValues.businessid.placeholder}
                                            required={formValues.businessid.required}
                                            label={formValues.businessid.label}
                                            name={formValues.businessid.name}
                                            value={formValues.businessid.value}
                                            onChange={handleTextChange}
                                            error={formValues.businessid.error}
                                            helpertext={formValues.businessid.error && formValues.businessid.errorMessage}

                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={12} sx={{ width: '100%', display: 'none' }}>
                                        <TextField size="Normal"
                                            placeholder={formValues.productname.placeholder}
                                            required={formValues.productname.required}
                                            label={formValues.productname.label}
                                            name={formValues.productname.name}
                                            value={formValues.productname.value}
                                            onChange={handleTextChange}
                                            error={formValues.productname.error}
                                            helpertext={formValues.productname.error && formValues.productname.errorMessage}

                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={12} sx={{ width: '100%', display: 'none' }}>
                                        <TextField size="Normal"
                                            placeholder={formValues.orderid.placeholder}
                                            required={formValues.orderid.required}
                                            label={formValues.orderid.label}
                                            name={formValues.orderid.name}
                                            value={formValues.orderid.value}
                                            onChange={handleTextChange}
                                            error={formValues.orderid.error}
                                            helpertext={formValues.orderid.error && formValues.orderid.errorMessage}

                                        />
                                    </Grid>
                                </Grid>
                                : <></>}
                        </Grid>
                    </Grid>
                </Box>
                <Grid height='15px' ></Grid>

                <Grid container direction="row" justifyContent="flex-end"  >
                    {apiInProgress ? <CircularProgress /> :
                        <>
                            <Button variant="outlined" type="submit" >{mfproductorderId === -1 ? 'Add' : 'Save'}</Button>&nbsp;&nbsp;&nbsp;
                            <Button variant="outlined" onClick={() => { dispatch(setShowEditForMfproductorder(false)); }}>Close</Button>
                        </>
                    }
                </Grid>
            </Grid>
        </form>
    );
};