import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MfproductorderFilterCriteria from './MfproductorderFilterCriteria'
import MfproductorderListImpl from './MfproductorderListImpl'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MfproductorderAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMfproductorderDashboardMfproductorderList } from '../../store/slice/ManufSlice';

export default function MfproductorderListView({setShowEdit,setMfproductorderId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mfproductorderDashboard);   

    const [filteredMfproductorderList, setFilteredMfproductorderList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mfproductorderDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mfproductorderDashboard.mfproductorderList.length>-1){
        setFilteredMfproductorderList(mfproductorderDashboard.mfproductorderList);
        transformToCardFormat(mfproductorderDashboard.mfproductorderList, setCardList);
      }
    },[mfproductorderDashboard.mfproductorderList])

    useEffect(()=>{
          transformToCardFormat(filteredMfproductorderList, setCardList);
      },[filteredMfproductorderList])

    useEffect(()=>{
        if (filterCriteria.productid==='' && filterCriteria.productname==='' && filterCriteria.matter==='' && filterCriteria.qty==='' && filterCriteria.deliverypriorityid==='' && filterCriteria.deliverypriorityname==='' && filterCriteria.expecteddelivery==='' && filterCriteria.stitchingstatusid==='' && filterCriteria.stitchingstatusname==='' && filterCriteria.digitalstatusid==='' && filterCriteria.digitalstatusname==='' && filterCriteria.productorderstatusid==='' && filterCriteria.productorderstatusname==='' && filterCriteria.partyname==='' && filterCriteria.orderid==='' && filterCriteria.unit==='' ){
            setFilteredMfproductorderList(mfproductorderDashboard.mfproductorderList);
            transformToCardFormat(mfproductorderDashboard.mfproductorderList, setCardList);

        } else {
            setFilteredMfproductorderList(
                mfproductorderDashboard.mfproductorderList
                    .filter(mfproductorder=> mfproductorder.productname.toString().toUpperCase().includes(filterCriteria.productname.toString().toUpperCase()))
.filter(mfproductorder=> mfproductorder.deliverypriorityid.toString().toUpperCase().includes(filterCriteria.deliverypriorityid.toString().toUpperCase()))
.filter(mfproductorder=> mfproductorder.stitchingstatusid.toString().toUpperCase().includes(filterCriteria.stitchingstatusid.toString().toUpperCase()))
.filter(mfproductorder=> mfproductorder.digitalstatusid.toString().toUpperCase().includes(filterCriteria.digitalstatusid.toString().toUpperCase()))
.filter(mfproductorder=> mfproductorder.productorderstatusid.toString().toUpperCase().includes(filterCriteria.productorderstatusid.toString().toUpperCase()))
.filter(mfproductorder=> mfproductorder.partyname.toString().toUpperCase().includes(filterCriteria.partyname.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mfproductorderDashboard.filteredMfproductorderList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MfproductorderListImpl
                    setShowEdit={setShowEdit}
                    setMfproductorderId = {setMfproductorderId}
                    tableList={filteredMfproductorderList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}