import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import TaskFilterCriteria from './TaskFilterCriteria'
import TaskList from './TaskList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './TaskAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setTaskDashboardTaskList } from '../../store/slice/ManufSlice';

export default function TaskListView({setShowEdit,setTaskId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.taskDashboard);   

    const [filteredTaskList, setFilteredTaskList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { taskDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (taskDashboard.taskList.length>-1){
        setFilteredTaskList(taskDashboard.taskList);
        transformToCardFormat(taskDashboard.taskList, setCardList);
      }
    },[taskDashboard.taskList])

    useEffect(()=>{
          transformToCardFormat(filteredTaskList, setCardList);
      },[filteredTaskList])

    useEffect(()=>{
        if (filterCriteria.title==='' && filterCriteria.businessprocessid==='' && filterCriteria.pretaskid==='' && filterCriteria.performingroleid==='' && filterCriteria.performingrolename==='' && filterCriteria.tasktypeid==='' ){
            setFilteredTaskList(taskDashboard.taskList);
            transformToCardFormat(taskDashboard.taskList, setCardList);

        } else {
            setFilteredTaskList(
                taskDashboard.taskList
                    .filter(task=> task.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(taskDashboard.filteredTaskList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <TaskList
                    setShowEdit={setShowEdit}
                    setTaskId = {setTaskId}
                    tableList={filteredTaskList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}