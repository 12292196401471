import React, { useState, useEffect } from 'react'
import { useSearchParams} from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import DeleteNotification from '../../core/components/DeleteNotification';
import {CoreContext} from "../../core/context/CoreContext";
import PageWaiting from '../../core/components/PageWaiting';
import {isMobile} from 'react-device-detect';
import AddCardIcon from '@mui/icons-material/AddCard';

import {getListAPI, deleteAPI} from './MforderAPI';
import {getColumns} from './MforderStruct';
import ListCardView from '../../core/components/card/ListCardView';
import ListTableView from '../../core/components/card/ListTableView';

import {getMforderView} from './MforderView';
import CardListLayout from '../../core/components/card/layout/CardListLayout';
import { useSelector, useDispatch } from 'react-redux';
import {setViewParams} from '../../store/slice/ChoiceSelectionSlice';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import PrintIcon from '@mui/icons-material/Print';


import {setFilter} from '../../store/slice/ConfSlice';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import MyPageContainer from '../../core/template/MyPageContainer';
import { deleteMforderObj  ,setSelectedMforderId,setMforderUpdateMfOrderStatusModal,setMforderManageMfOrderModal,
  setMforderAddMfOrderModal,setMforderAddMfProductModal,setMforderOrderToFactoryModal,setSelectedActionId , 
  setShowEditForMforder,setMfproductorderDashboardMfproductorderList,setMforderPrintJobTicketModal} from 'store/slice/ManufSlice';

import UpdateStatusActionModal from 'components/taskinstance/action/UpdateStatus/UpdateStatusActionModal';
import PrintJobTicketActionModal from './action/PrintJobTicket/PrintJobTicketActionModal';

import MforderListTable from './components/MforderListTable';
import { useNavigate } from "react-router-dom";
import { setClickedBo,setActionClicked } from 'store/slice/BreadCrumSlice';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import FactoryIcon from '@mui/icons-material/Factory';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import RuleIcon from '@mui/icons-material/Rule';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import AddMfProductActionModal from './action/addMfProduct/AddMfProductActionModal';
import OrderToFactoryActionModal from './action/orderToFactory/OrderToFactoryActionModal'

import UpdateMfOrderStatusActionModal from './action/updateMfOrderStatus/UpdateMfOrderStatusActionModal';
import ManageMfOrderActionModal from './action/manageMfOrder/ManageMfOrderActionModal';
import AddMfOrderActionModal from './action/addMfOrder/AddMfOrderActionModal';

import { transformDateValue } from 'core/js/FormUtils';

export default function MforderListImpl( {tableList, cardList, setShowEdit, setMforderId}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = getColumns(editCallBack, initDeleteCallBack);
  const [view, setView] = useState(1);
  const { showfilter } = useSelector((state) => state.confslice)

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const {selectedId, mforderObj,mforderList} = useSelector((state)=>state.manufslice.mforderDashboard);
  const {mfproductorderList} = useSelector((state)=>state.manufslice);
  const {orderType} = useSelector((state)=>state.appslice)
  
  // useEffect(()=>{
  //   dispatch(setMfproductorderDashboardMfproductorderList(
  //     mfproductorderList
  //   ))
  // },[mfproductorderList])

  useEffect(() => {
  }, [mforderList, mforderObj])
  
  function initDeleteCallBack(id){
    dispatch(setSelectedMforderId(id));
    setShowDeleteAlert(true);
  }
  function deleteCallBack(obj){
    dispatch(deleteMforderObj(obj.id));
   // setNotification('Record deleted successfully !!!')
  }
  function deleteRecord(){
    deleteAPI(selectedId,deleteCallBack);
    setShowDeleteAlert(false);
  }

  function editCallBack(id){
    dispatch(setSelectedMforderId(id));
    dispatch(setShowEditForMforder(true));
  }
  
  const createMforder=()=>{
    dispatch(setSelectedMforderId(-1));
    //dispatch(setShowEditForMforder(true));
    dispatch(setMforderAddMfOrderModal(true));
  }


  const updateMfOrderStatusCallBack = (id) =>{
    dispatch(setSelectedMforderId(id));
    dispatch(setMforderUpdateMfOrderStatusModal(true));
  }
  const manageMfOrderCallBack = (id) =>{
    dispatch(setSelectedMforderId(id));
    dispatch(setMforderManageMfOrderModal(true));
    //navigate('/mforderpage')
  }
  const addMfOrderCallBack = (id) =>{
    dispatch(setSelectedMforderId(id));
    dispatch(setMforderAddMfOrderModal(true));
  }

  const addMfProductCallBack = (id) =>{
    dispatch(setSelectedMforderId(id));
    dispatch(setMforderAddMfProductModal(true));
  }
  const orderToFactoryCallBack = (id) =>{
    dispatch(setSelectedMforderId(id));
    dispatch(setMforderOrderToFactoryModal(true));
  }
const ShowProgressCallBack = (id) =>{
    dispatch(setSelectedMforderId(id));
    navigate('/showprogress')
}
const PrintJobTicketCallBack = (id) =>{
    dispatch(setSelectedMforderId(id));
    dispatch(setMforderPrintJobTicketModal(true));
}
const getDataWithDateTransformation = (data) =>{
    let newData = {
      ...data,
      'expand':false,
		'orderdate': transformDateValue(data.orderdate)
    }
    return newData;
  }
  const mforderTransformation = (tableList) =>{
    let newDataList=[]
    tableList.map((data)=>{ 
      newDataList.push(getDataWithDateTransformation(data));
     })
    return newDataList;
  }

  const payload = {
    mforderList:mforderList,
    cardList:getMforderView(tableList),
        actionList: [
          {title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>},
          {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>},
          
        {id:30, title:'UpdateMfOrderStatus', callBackMethod:updateMfOrderStatusCallBack, icon:<DynamicFeedIcon/>},
        {id:31, title:'ManageMfOrder', callBackMethod:manageMfOrderCallBack, icon:<RuleIcon/>},
        {id:32, title:'AddMfOrder', callBackMethod:addMfOrderCallBack, icon:<AddToQueueIcon/>},
        {id:34, title:'Add Product', callBackMethod:addMfProductCallBack, icon:<PlaylistAddIcon/>},
        {id:35, title:'Order To Factory', callBackMethod:orderToFactoryCallBack, icon:<FactoryIcon/>},
        ],
        dialogueActionList: [
          
        ],
    tableView:{
      tableList:mforderTransformation(tableList),
      mforderList:mforderList,
      actionList:[
        // {title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>},
        // {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>},
        {id:31, title:'ManageMfOrder', callBackMethod:manageMfOrderCallBack, icon:<AddCardIcon/>, status:'DRAFT', role:''},
        // {id:30, title:'UpdateMfOrderStatus', callBackMethod:updateMfOrderStatusCallBack, icon:<DynamicFeedIcon/>},
        // {id:32, title:'AddMfOrder', callBackMethod:addMfOrderCallBack, icon:<AddToQueueIcon/>},
        // {id:34, title:'Add Product', callBackMethod:addMfProductCallBack, icon:<PlaylistAddIcon/>},
        // {id:35, title:'Order To Factory', callBackMethod:orderToFactoryCallBack, icon:<FactoryIcon/>},
        {id:66, title:'Show Progress', callBackMethod:ShowProgressCallBack, icon:<FactoryIcon/>, status:'WIP', role:''},
        {id:69, title:'Print Job Ticket', callBackMethod:PrintJobTicketCallBack, icon:<PrintIcon/>, status:'WIP', role:''},
      ]
    }
  }
  
  const getPayload = ()=>{
    return payload;
  }

  useEffect(()=>{
    if (document.body.offsetWidth<500)  setView(0);
    dispatch(setViewParams({
      colsCount:{xs:12, sm:4, md:4, lg:4},
      modal:{
            
      }
      }))
  },[])

  return (
   <>
   
   
      <Grid container direction="column">
              <Grid item>
                  <Grid container direction="row" justifyContent={'space-between'} alignContent={'center'}>
                      <Grid item>
                        <Typography variant='h4' color="primary.darker">Order List [{orderType}]</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent={'flex-end'} spacing={1}>
                          {/* {showfilter?
                            <Grid item>
                              <Tooltip title="Hide filter" sx={{cursor: "pointer"}}>
                                <FilterAltOffIcon onClick={()=>{dispatch(setFilter(false))}}/>
                              </Tooltip>
                            </Grid>
                            :
                            <Grid item>
                              <Tooltip title="Show filter" sx={{cursor: "pointer"}}>
                                <FilterAltIcon onClick={()=>{dispatch(setFilter(true))}}/>
                              </Tooltip>
                            </Grid>
                            } */}
                            {orderType==='IN-PROGRESS'?<>
                            <Grid item>
                              <Tooltip title="Add Mforder" sx={{cursor: "pointer"}}>
                                <AddIcon onClick={()=>{createMforder()}}/>
                              </Tooltip>
                            </Grid>
                            </>:<></>}
                          </Grid>
                      </Grid>
                      
                  </Grid>
              </Grid>
              <Grid item>
                  < MforderListTable 
                  payload={getPayload().tableView}
                  />
              </Grid>     
      </Grid>
   
    { showDeleteAlert?<DeleteNotification 
            id={selectedId}
            width="50%"
            setShowDeleteAlert={setShowDeleteAlert}
            deleteRecord = {deleteRecord}/>: ''
    }

    
<UpdateMfOrderStatusActionModal/>
<ManageMfOrderActionModal/>
<AddMfOrderActionModal/>
<AddMfProductActionModal/>
<OrderToFactoryActionModal/>
<UpdateStatusActionModal/>
<PrintJobTicketActionModal/>
    </>
);


}