import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import TasktypeFilterCriteria from './TasktypeFilterCriteria'
import TasktypeList from './TasktypeList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './TasktypeAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setTasktypeDashboardTasktypeList } from '../../store/slice/ManufSlice';

export default function TasktypeListView({setShowEdit,setTasktypeId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.tasktypeDashboard);   

    const [filteredTasktypeList, setFilteredTasktypeList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { tasktypeDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (tasktypeDashboard.tasktypeList.length>-1){
        setFilteredTasktypeList(tasktypeDashboard.tasktypeList);
        transformToCardFormat(tasktypeDashboard.tasktypeList, setCardList);
      }
    },[tasktypeDashboard.tasktypeList])

    useEffect(()=>{
          transformToCardFormat(filteredTasktypeList, setCardList);
      },[filteredTasktypeList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredTasktypeList(tasktypeDashboard.tasktypeList);
            transformToCardFormat(tasktypeDashboard.tasktypeList, setCardList);

        } else {
            setFilteredTasktypeList(
                tasktypeDashboard.tasktypeList
                    .filter(tasktype=> tasktype.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(tasktypeDashboard.filteredTasktypeList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <TasktypeList
                    setShowEdit={setShowEdit}
                    setTasktypeId = {setTasktypeId}
                    tableList={filteredTasktypeList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}