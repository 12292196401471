import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography } from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import MfproductorderInfoByProductObj from 'components/mfproductorder/components/MfproductorderInfoByProductObj';
import MfproductOrderProgressUnderOrder from '../page/MfproductOrderProgressUnderOrder';
import { transformDateValue } from 'core/js/FormUtils';
import FieldView2 from 'core/components/layout/FieldView2';

export default function MforderProductListByOrderId({ orderId }) {
  const [productList, setProductList] = useState([])
  const { mfproductorderList } = useSelector((state) => state.manufslice);
  const [orderProductList, setOrderProductList] = useState([]);
  useEffect(() => {
    setOrderProductList(
      mfproductorderList.filter(productorderObj => productorderObj.orderid == orderId)
    )
  }, [])
  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: '100%',
          backgroundColor: 'secondary.100',

        }}

      >
        {orderProductList.length === 0 ? <>
          <Grid item width="100%" align="center" sx={{ padding: 2 }}>
            <Typography><i>No Products added</i></Typography>
          </Grid>
        </> : <></>
        }
        {
          orderProductList && orderProductList.map(obj => {
            return <>
              <Grid item lg={6} xs={12} width="100%" sx={{padding:.5}}>
                <Grid container direction="column">
                  <Grid item >
                    <Grid container direction="row" sx={{}} justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h5" color="primary.darker">{obj.productname}</Typography>
                      </Grid>
                      <Grid item>
                        {obj.productorderstatusname}
                      </Grid>
                    </Grid>
                  </Grid>
                    <Grid item lg={6} xs={12} >
                      <Grid container direction="row" sx={{ backgroundColor: 'secondary.200', padding: .5,  }} justifyContent="space-between">
                        <Grid item lg={2} xs={3} >
                          <Grid container direction="column">
                            <Grid item  ><Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>Matter</Typography></Grid>
                            <Grid item ><Typography color="primary.darker" sx={{ fontWeight: 'light', fontSize: 12 }}>{obj.matter}</Typography></Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} xs={3}>
                          <Grid container direction="column">
                            <Grid item  ><Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>Quantity</Typography></Grid>
                            <Grid item  ><Typography color="primary.darker" sx={{ fontWeight: 'light', fontSize: 12 }}>{obj.qty}&nbsp;{obj.unit}</Typography></Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} xs={3}>
                          <Grid container direction="column">
                            <Grid ><Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>Priority</Typography></Grid>
                            <Grid  ><Typography color="primary.darker" sx={{ fontWeight: 'light', fontSize: 12 }}>{obj.deliverypriorityname}</Typography></Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{height:'5px'}}>
                          &nbsp;
                        </Grid>
                        <Grid item lg={2} xs={3}>
                          <Grid container direction="column">
                            <Grid ><Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>Delivery By</Typography></Grid>
                            <Grid  ><Typography color="primary.darker" sx={{ fontWeight: 'light', fontSize: 12 }}>{transformDateValue(obj.expecteddelivery)}</Typography></Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} xs={3}>
                          <Grid container direction="column">
                            <Grid  ><Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>Printing Type</Typography></Grid>
                            <Grid  ><Typography color="primary.darker" sx={{ fontWeight: 'light', fontSize: 12 }}>{obj.printingtype?'Digital':'Screen'}</Typography></Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} xs={3}>
                          <Grid container direction="column">
                            <Grid  ><Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>Stiching Type</Typography></Grid>
                            <Grid ><Typography color="primary.darker" sx={{ fontWeight: 'light', fontSize: 12 }}>{obj.ptichingtype?'Simple':'Technical'}</Typography></Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          <Grid container direction="column">
                            <Grid ><Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>Remarks</Typography></Grid>
                            <Grid  ><Typography color="primary.darker" sx={{ fontWeight: 'light', fontSize: 12 }}>{obj.remarks}</Typography></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                        
                </Grid>
                <Grid item lg={12} xs={12} sx={{}}>
                <MfproductOrderProgressUnderOrder productId={obj.productid} orderId={orderId}/>
              </Grid>
              </Grid>
              
            </>
          })
        }
      </Grid >
    </>
  )
}