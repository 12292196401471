import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MfstitchingstatusFilterCriteria from './MfstitchingstatusFilterCriteria'
import MfstitchingstatusList from './MfstitchingstatusList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MfstitchingstatusAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMfstitchingstatusDashboardMfstitchingstatusList } from '../../store/slice/ManufSlice';

export default function MfstitchingstatusListView({setShowEdit,setMfstitchingstatusId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mfstitchingstatusDashboard);   

    const [filteredMfstitchingstatusList, setFilteredMfstitchingstatusList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mfstitchingstatusDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mfstitchingstatusDashboard.mfstitchingstatusList.length>-1){
        setFilteredMfstitchingstatusList(mfstitchingstatusDashboard.mfstitchingstatusList);
        transformToCardFormat(mfstitchingstatusDashboard.mfstitchingstatusList, setCardList);
      }
    },[mfstitchingstatusDashboard.mfstitchingstatusList])

    useEffect(()=>{
          transformToCardFormat(filteredMfstitchingstatusList, setCardList);
      },[filteredMfstitchingstatusList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredMfstitchingstatusList(mfstitchingstatusDashboard.mfstitchingstatusList);
            transformToCardFormat(mfstitchingstatusDashboard.mfstitchingstatusList, setCardList);

        } else {
            setFilteredMfstitchingstatusList(
                mfstitchingstatusDashboard.mfstitchingstatusList
                    .filter(mfstitchingstatus=> mfstitchingstatus.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mfstitchingstatusDashboard.filteredMfstitchingstatusList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MfstitchingstatusList
                    setShowEdit={setShowEdit}
                    setMfstitchingstatusId = {setMfstitchingstatusId}
                    tableList={filteredMfstitchingstatusList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}