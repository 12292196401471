import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MfpartyEditFormImpl from './MfpartyEditFormImpl';
import MfpartyList from './MfpartyList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MfpartyListView from './MfpartyListView'


export default function MfpartyDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.mfpartyDashboard);
  const [ mfpartyId, setMfpartyId] = React.useState('');
  const [reloadMfpartyList, setReloadMfpartyList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MfpartyEditFormImpl 
            setReloadMfpartyList={setReloadMfpartyList}
            mfpartyId={mfpartyId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MfpartyListView 
                      setMfpartyId = {setMfpartyId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
