import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import FactoryroleEditForm from './FactoryroleEditForm';
import FactoryroleList from './FactoryroleList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import FactoryroleListView from './FactoryroleListView'


export default function FactoryroleDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.factoryroleDashboard);
  const [ factoryroleId, setFactoryroleId] = React.useState('');
  const [reloadFactoryroleList, setReloadFactoryroleList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <FactoryroleEditForm 
            setReloadFactoryroleList={setReloadFactoryroleList}
            factoryroleId={factoryroleId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <FactoryroleListView 
                      setFactoryroleId = {setFactoryroleId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
