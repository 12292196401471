import { Grid, Tooltip} from "@mui/material";

export function getTaskStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        title:{
            name:'title',
            label:'Title',
            value:'',
            error:false,
            errorMessage:'You must enter Title',
            required:true,
            fieldtype:'string'
        },
        businessprocessid:{
            name:'businessprocessid',
            label:'Business Process Id',
            value:'',
            error:false,
            errorMessage:'You must enter Business Process Id',
            required:true,
            fieldtype:'ID'
        },
        pretaskid:{
            name:'pretaskid',
            label:'Pre Task Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'number'
        },
        performingroleid:{
            name:'performingroleid',
            label:'PerformingRole Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        performingrolename:{
            name:'performingrolename',
            label:'PerformingRole Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        tasktypeid:{
            name:'tasktypeid',
            label:'Task Type Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        tasktypename:{
            name:'tasktypename',
            label:'Task Type Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'title', headerName: 'Title', width:100 },
        { field: 'businessprocessid', headerName: 'Business Process Id', width:150 },
        { field: 'pretaskid', headerName: 'Pre Task Id', width:150 },
        { field: 'performingroleid', headerName: 'PerformingRole Id', width:150 },
        { field: 'performingrolename', headerName: 'PerformingRole Name', width:150 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['title']:{
          ...formValues['title'],
          value:data.title,
        },['businessprocessid']:{
          ...formValues['businessprocessid'],
          value:data.businessprocessid,
        },['pretaskid']:{
          ...formValues['pretaskid'],
          value:data.pretaskid,
        },['performingroleid']:{
          ...formValues['performingroleid'],
          value:data.performingroleid,
        },['performingrolename']:{
          ...formValues['performingrolename'],
          value:data.performingrolename,
        },['tasktypeid']:{
          ...formValues['tasktypeid'],
          value:data.tasktypeid,
        },['tasktypename']:{
          ...formValues['tasktypename'],
          value:data.tasktypename,
        },
      })
}