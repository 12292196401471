import {useState, useEffect} from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import TaskDashboard from 'components/task/TaskDashboard';
import { setTaskDashboardTaskList } from 'store/slice/ManufSlice';
export default function TaskMgmtActionImpl(){
    const {taskList} = useSelector(state => state.manufslice);
    const {businessprocessObj} = useSelector(state => state.manufslice.businessprocessDashboard);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setTaskDashboardTaskList(
            taskList.filter(task => task.businessprocessid===businessprocessObj.id) 
        ))
},[])
    return (
        <TaskDashboard/>
    )
}