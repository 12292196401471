import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip, Divider, Button } from "@mui/material";
import Cutting from "../assets/cutter.png"; // Import local images
import Printing from "../assets/printer.png";
import Stitching from "../assets/sewing.png";
import Design from '../assets/drawing.png';
import RmCheck from '../assets/rmcheck.png';
import AccountCheck from '../assets/accountcheck.png';
import Packaging from '../assets/packaging.png';
import { useNavigate } from "react-router-dom";
import { setTaskinstanceList, setSelectedTaskinstanceId } from "store/slice/ManufSlice";

import { getTaskinstanceInfoByOrderIdAPI } from "components/taskinstance/TaskinstanceAPI";
import { useSelector, useDispatch } from 'react-redux';
import { getMfproductorderInfoByOrderIdAPI } from 'components/mfproductorder/MfproductorderAPI';
import { getTaskinstanceInfoByOrderidandproductidAPI } from "components/taskinstance/TaskinstanceAPI";
import ProductOrderListWithOrderId from "./ProductOrderListWithOrderId";

const MforderProgressPageImpl = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { mforderObj, mforderList } = useSelector((state) => state.manufslice.mforderDashboard);
    const [tastList, setTaskList] = useState([]);
    const { mfproductorderList } = useSelector((state) => state.manufslice.mfproductorderDashboard);
    useEffect(() => {
       // dispatch(setTaskinstanceList([]));
        dispatch(setSelectedTaskinstanceId(-1))
        // getMfproductorderInfoByOrderIdAPI(mforderObj.id, setProductOrderList, () => { })
    }, [])
    return (

        <Grid container directin="column" justifyContent="flex-start" >
            <Grid item lg={12} xs={12} >
                <Grid container direction="row" justifyContent="space-between">
                    <Grid lg={1} xs={1} item ><Typography variant="h5" color="primary">Order Id</Typography></Grid>
                    <Grid lg={11} xs={11} item >
                        <Grid lg={12} xs={12} container >
                            <Grid lg={2} xs={2} item ><Typography variant="h5" color="primary">Product</Typography></Grid>
                            <Grid lg={10} xs={10} container justifyContent="space-between">

                                <Grid lg={1} xs={1} item sx={{ padding: 1, backgroundColor: 'secondary.200', border: '1px solid black' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="RM CHECK" arrow>
                                                <Avatar src={RmCheck} sx={{ width: 45, height: 45, padding: 0.5 }} />
                                            </Tooltip>
                                        </Grid><Typography variant="h6" align="center">RM</Typography></Grid>
                                </Grid>

                                <Grid lg={1} xs={1} item sx={{ padding: 1, backgroundColor: 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Design Approval" arrow>
                                                <Avatar src={Design} sx={{ width: 45, height: 45, padding: 0.5 }} />
                                            </Tooltip>
                                        </Grid><Typography variant="h6" align="center">Design</Typography></Grid>
                                </Grid>

                                <Grid lg={1} xs={1} item sx={{ padding: 1, backgroundColor: 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Cutting" arrow>
                                                <Avatar src={Cutting} sx={{ width: 45, height: 45, padding: 0.5 }} />
                                            </Tooltip>
                                        </Grid><Typography variant="h6" align="center">Cutting</Typography></Grid>
                                </Grid>

                                <Grid lg={1} xs={1} item sx={{ padding: 1, backgroundColor: 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Printing" arrow>
                                                <Avatar src={Printing} sx={{ width: 45, height: 45, padding: 0.5 }} />
                                            </Tooltip>
                                        </Grid><Typography variant="h6" align="center">Printing</Typography></Grid>
                                </Grid>

                                <Grid lg={1} xs={1} item sx={{ padding: 1, backgroundColor: 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Stitching" arrow>
                                                <Avatar src={Stitching} sx={{ width: 45, height: 45, padding: 0.5 }} />
                                            </Tooltip>
                                        </Grid><Typography variant="h6" align="center">Stitching</Typography></Grid>
                                </Grid>

                                <Grid lg={1} xs={1} item sx={{ padding: 1, backgroundColor: 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Finance Check" arrow>
                                                <Avatar src={AccountCheck} sx={{ width: 45, height: 45, padding: 0.5 }} />
                                            </Tooltip>
                                        </Grid><Typography variant="h6" align="center">Finance</Typography></Grid>
                                </Grid>

                                <Grid lg={1} xs={1} item sx={{ padding: 1, backgroundColor: 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Packaging" arrow>
                                                <Avatar src={Packaging} sx={{ width: 45, height: 45, padding: 0.5 }} />
                                            </Tooltip>
                                        </Grid><Typography variant="h6" align="center">Packaging</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
            <Grid item lg={12} xs={12} sx={{ paddingTop: .5 }}>
                <Divider sx={{ backgroundColor: 'primary.light', height: '2px' }} />
            </Grid>

            <Grid item xs={12} lg={12}>
                {
                    mforderList && mforderList.map((mforderObj) => {
                        return (
                            <Grid container direction="row" sx={{ backgroundColor: 'white', padding: .5, }} justifyContent="space-between">
                                <Grid item lg={1} alignItems="center" >
                                    <Typography variant="h6" color="primary.darker">{mforderObj.id}</Typography>
                                </Grid>
                                <Grid item lg={11} container direction="row">
                                    <Grid lg={12} container justifyContent="space-between">
                                        <ProductOrderListWithOrderId orderId={mforderObj.id} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
            </Grid>

        </Grid>


    );
};

export default MforderProgressPageImpl;
