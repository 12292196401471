import { createSlice } from '@reduxjs/toolkit';

export const ManufSlice = createSlice({
    name: "manufslice",
    initialState: {
      appId:3,
      actionList:[],
      actionDashboard:{
          selectedId:-1,
          actionList:[],
          actionObj:{},
          
      },
      menuList:[],
  
        mfprintingstatusList:[],
		mfprintingstatusDashboard:{
            selectedId:-1,
            showEdit:false,
            mfprintingstatusList:[],
            mfprintingstatusObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mfstitchingstatusList:[],
		mfstitchingstatusDashboard:{
            selectedId:-1,
            showEdit:false,
            mfstitchingstatusList:[],
            mfstitchingstatusObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mfdigitalstatusList:[],
		mfdigitalstatusDashboard:{
            selectedId:-1,
            showEdit:false,
            mfdigitalstatusList:[],
            mfdigitalstatusObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mfdeliverypriorityList:[],
		mfdeliverypriorityDashboard:{
            selectedId:-1,
            showEdit:false,
            mfdeliverypriorityList:[],
            mfdeliverypriorityObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mfprintingtypeList:[],
		mfprintingtypeDashboard:{
            selectedId:-1,
            showEdit:false,
            mfprintingtypeList:[],
            mfprintingtypeObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mforderstatusList:[],
		mforderstatusDashboard:{
            selectedId:-1,
            showEdit:false,
            mforderstatusList:[],
            mforderstatusObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mforderList:[],
		mforderDashboard:{
            selectedId:-1,
            showEdit:false,
            mforderList:[],
            mforderObj:{},
            filterCriteria:{
                
			partyname:'',
			executive:'',
			orderstatusid:''
            },
            
			updateMfOrderStatusModalOpen:false,
			manageMfOrderModalOpen:false,
			addMfOrderModalOpen:false,
			addMfProductModalOpen:false,
			orderToFactoryModalOpen:false,
			UpdateRemarksModalOpen:false,
			ShowProgressModalOpen:false,
			PrintJobTicketModalOpen:false,
            
        },
        mfpartyList:[],
		mfpartyDashboard:{
            selectedId:-1,
            showEdit:false,
            mfpartyList:[],
            mfpartyObj:{},
            filterCriteria:{
                
			title:'',
			mobile:''
            },
            
			ManagePartyModalOpen:false,
			SelectPartyModalOpen:false,
            
        },
        mfproductorderList:[],
		mfproductorderDashboard:{
            selectedId:-1,
            showEdit:false,
            mfproductorderList:[],
            mfproductorderObj:{},
            filterCriteria:{
                
			productname:'',
			deliverypriorityid:'',
			stitchingstatusid:'',
			digitalstatusid:'',
			productorderstatusid:'',
			partyname:''
            },
            
			updateProductOrderStatusModalOpen:false,
			AddMfProductOrderModalOpen:false,
            
        },
        mfproductList:[],
		mfproductDashboard:{
            selectedId:-1,
            showEdit:false,
            mfproductList:[],
            mfproductObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mfproductorderstatusList:[],
		mfproductorderstatusDashboard:{
            selectedId:-1,
            showEdit:false,
            mfproductorderstatusList:[],
            mfproductorderstatusObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        businessprocessList:[],
		businessprocessDashboard:{
            selectedId:-1,
            showEdit:false,
            businessprocessList:[],
            businessprocessObj:{},
            filterCriteria:{
                
			title:''
            },
            
			TaskMgmtModalOpen:false,
            
        },
        taskList:[],
		taskDashboard:{
            selectedId:-1,
            showEdit:false,
            taskList:[],
            taskObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        taskinstanceList:[],
		taskinstanceDashboard:{
            selectedId:-1,
            showEdit:false,
            taskinstanceList:[],
            taskinstanceObj:{},
            filterCriteria:{
                
			title:''
            },
            
			UpdateStatusModalOpen:false,
            
        },
        tasktypeList:[],
		tasktypeDashboard:{
            selectedId:-1,
            showEdit:false,
            tasktypeList:[],
            tasktypeObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        factoryroleList:[],
		factoryroleDashboard:{
            selectedId:-1,
            showEdit:false,
            factoryroleList:[],
            factoryroleObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        taskstatusList:[],
		taskstatusDashboard:{
            selectedId:-1,
            showEdit:false,
            taskstatusList:[],
            taskstatusObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
    },
    reducers: {
        
        setActionList: (state, action) =>{
            state.actionList=action.payload;
            state.actionDashboard.actionList=action.payload;
        },
        setSelectedActionId: (state, action)=>{
            state.actionDashboard.selectedId=action.payload;

            state.actionDashboard.actionObj = state.actionList[
                state.actionList.findIndex(actionObj=>actionObj.id===parseInt(action.payload))
            ];
        },

        
        /* Mfprintingstatus Reducer methods */
        setMfprintingstatusFilterCriteria : (state, action)=>{
            state.mfprintingstatusDashboard.filterCriteria=action.payload;
        },
        setShowEditForMfprintingstatus: (state,action)=>{
            state.mfprintingstatusDashboard.showEdit=action.payload;
            
        },
        addMfprintingstatusObj: (state, action)=>{
            state.mfprintingstatusList.unshift(action.payload);
            state.mfprintingstatusDashboard.mfprintingstatusList.unshift(action.payload);

            state.mfprintingstatusDashboard.mfprintingstatusObj=action.payload;
            state.mfprintingstatusDashboard.selectedId=action.payload.id;
        },
        updateMfprintingstatusObj: (state, action)=>{
            let index = state.mfprintingstatusList.map(mfprintingstatusObj=>mfprintingstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfprintingstatusList[index]=action.payload;
            }

            index = state.mfprintingstatusDashboard.mfprintingstatusList.map(mfprintingstatusObj=>mfprintingstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfprintingstatusDashboard.mfprintingstatusList[index]=action.payload;
            }
            if (state.mfprintingstatusDashboard.mfprintingstatusObj!==null){
                state.mfprintingstatusDashboard.mfprintingstatusObj = action.payload;
            }
        },
        deleteMfprintingstatusObj: (state, action)=>{
            state.mfprintingstatusList.splice(state.mfprintingstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mfprintingstatusDashboard.mfprintingstatusList.splice(state.mfprintingstatusDashboard.mfprintingstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mfprintingstatusDashboard.productObj={}
            state.mfprintingstatusDashboard.selectedId=-1
        },
        setSelectedMfprintingstatusId: (state, action)=>{
            if (action.payload!==-1){
                state.mfprintingstatusDashboard.selectedId=action.payload;
                state.mfprintingstatusDashboard.mfprintingstatusObj = state.mfprintingstatusList[
                    state.mfprintingstatusList.findIndex(mfprintingstatusObj=>mfprintingstatusObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfprintingstatusDashboard.selectedId=action.payload;
                state.mfprintingstatusDashboard.mfprintingstatusObj ={}
            }
        },
        setMfprintingstatusList: (state, action) =>{
            state.mfprintingstatusList=action.payload;
            state.mfprintingstatusDashboard.mfprintingstatusList=action.payload;
        },
        setMfprintingstatusDashboardMfprintingstatusList: (state, action) =>{
            state.mfprintingstatusDashboard.mfprintingstatusList=action.payload;
        },
        /* Mfstitchingstatus Reducer methods */
        setMfstitchingstatusFilterCriteria : (state, action)=>{
            state.mfstitchingstatusDashboard.filterCriteria=action.payload;
        },
        setShowEditForMfstitchingstatus: (state,action)=>{
            state.mfstitchingstatusDashboard.showEdit=action.payload;
            
        },
        addMfstitchingstatusObj: (state, action)=>{
            state.mfstitchingstatusList.unshift(action.payload);
            state.mfstitchingstatusDashboard.mfstitchingstatusList.unshift(action.payload);

            state.mfstitchingstatusDashboard.mfstitchingstatusObj=action.payload;
            state.mfstitchingstatusDashboard.selectedId=action.payload.id;
        },
        updateMfstitchingstatusObj: (state, action)=>{
            let index = state.mfstitchingstatusList.map(mfstitchingstatusObj=>mfstitchingstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfstitchingstatusList[index]=action.payload;
            }

            index = state.mfstitchingstatusDashboard.mfstitchingstatusList.map(mfstitchingstatusObj=>mfstitchingstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfstitchingstatusDashboard.mfstitchingstatusList[index]=action.payload;
            }
            if (state.mfstitchingstatusDashboard.mfstitchingstatusObj!==null){
                state.mfstitchingstatusDashboard.mfstitchingstatusObj = action.payload;
            }
        },
        deleteMfstitchingstatusObj: (state, action)=>{
            state.mfstitchingstatusList.splice(state.mfstitchingstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mfstitchingstatusDashboard.mfstitchingstatusList.splice(state.mfstitchingstatusDashboard.mfstitchingstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mfstitchingstatusDashboard.productObj={}
            state.mfstitchingstatusDashboard.selectedId=-1
        },
        setSelectedMfstitchingstatusId: (state, action)=>{
            if (action.payload!==-1){
                state.mfstitchingstatusDashboard.selectedId=action.payload;
                state.mfstitchingstatusDashboard.mfstitchingstatusObj = state.mfstitchingstatusList[
                    state.mfstitchingstatusList.findIndex(mfstitchingstatusObj=>mfstitchingstatusObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfstitchingstatusDashboard.selectedId=action.payload;
                state.mfstitchingstatusDashboard.mfstitchingstatusObj ={}
            }
        },
        setMfstitchingstatusList: (state, action) =>{
            state.mfstitchingstatusList=action.payload;
            state.mfstitchingstatusDashboard.mfstitchingstatusList=action.payload;
        },
        setMfstitchingstatusDashboardMfstitchingstatusList: (state, action) =>{
            state.mfstitchingstatusDashboard.mfstitchingstatusList=action.payload;
        },
        /* Mfdigitalstatus Reducer methods */
        setMfdigitalstatusFilterCriteria : (state, action)=>{
            state.mfdigitalstatusDashboard.filterCriteria=action.payload;
        },
        setShowEditForMfdigitalstatus: (state,action)=>{
            state.mfdigitalstatusDashboard.showEdit=action.payload;
            
        },
        addMfdigitalstatusObj: (state, action)=>{
            state.mfdigitalstatusList.unshift(action.payload);
            state.mfdigitalstatusDashboard.mfdigitalstatusList.unshift(action.payload);

            state.mfdigitalstatusDashboard.mfdigitalstatusObj=action.payload;
            state.mfdigitalstatusDashboard.selectedId=action.payload.id;
        },
        updateMfdigitalstatusObj: (state, action)=>{
            let index = state.mfdigitalstatusList.map(mfdigitalstatusObj=>mfdigitalstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfdigitalstatusList[index]=action.payload;
            }

            index = state.mfdigitalstatusDashboard.mfdigitalstatusList.map(mfdigitalstatusObj=>mfdigitalstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfdigitalstatusDashboard.mfdigitalstatusList[index]=action.payload;
            }
            if (state.mfdigitalstatusDashboard.mfdigitalstatusObj!==null){
                state.mfdigitalstatusDashboard.mfdigitalstatusObj = action.payload;
            }
        },
        deleteMfdigitalstatusObj: (state, action)=>{
            state.mfdigitalstatusList.splice(state.mfdigitalstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mfdigitalstatusDashboard.mfdigitalstatusList.splice(state.mfdigitalstatusDashboard.mfdigitalstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mfdigitalstatusDashboard.productObj={}
            state.mfdigitalstatusDashboard.selectedId=-1
        },
        setSelectedMfdigitalstatusId: (state, action)=>{
            if (action.payload!==-1){
                state.mfdigitalstatusDashboard.selectedId=action.payload;
                state.mfdigitalstatusDashboard.mfdigitalstatusObj = state.mfdigitalstatusList[
                    state.mfdigitalstatusList.findIndex(mfdigitalstatusObj=>mfdigitalstatusObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfdigitalstatusDashboard.selectedId=action.payload;
                state.mfdigitalstatusDashboard.mfdigitalstatusObj ={}
            }
        },
        setMfdigitalstatusList: (state, action) =>{
            state.mfdigitalstatusList=action.payload;
            state.mfdigitalstatusDashboard.mfdigitalstatusList=action.payload;
        },
        setMfdigitalstatusDashboardMfdigitalstatusList: (state, action) =>{
            state.mfdigitalstatusDashboard.mfdigitalstatusList=action.payload;
        },
        /* Mfdeliverypriority Reducer methods */
        setMfdeliverypriorityFilterCriteria : (state, action)=>{
            state.mfdeliverypriorityDashboard.filterCriteria=action.payload;
        },
        setShowEditForMfdeliverypriority: (state,action)=>{
            state.mfdeliverypriorityDashboard.showEdit=action.payload;
            
        },
        addMfdeliverypriorityObj: (state, action)=>{
            state.mfdeliverypriorityList.unshift(action.payload);
            state.mfdeliverypriorityDashboard.mfdeliverypriorityList.unshift(action.payload);

            state.mfdeliverypriorityDashboard.mfdeliverypriorityObj=action.payload;
            state.mfdeliverypriorityDashboard.selectedId=action.payload.id;
        },
        updateMfdeliverypriorityObj: (state, action)=>{
            let index = state.mfdeliverypriorityList.map(mfdeliverypriorityObj=>mfdeliverypriorityObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfdeliverypriorityList[index]=action.payload;
            }

            index = state.mfdeliverypriorityDashboard.mfdeliverypriorityList.map(mfdeliverypriorityObj=>mfdeliverypriorityObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfdeliverypriorityDashboard.mfdeliverypriorityList[index]=action.payload;
            }
            if (state.mfdeliverypriorityDashboard.mfdeliverypriorityObj!==null){
                state.mfdeliverypriorityDashboard.mfdeliverypriorityObj = action.payload;
            }
        },
        deleteMfdeliverypriorityObj: (state, action)=>{
            state.mfdeliverypriorityList.splice(state.mfdeliverypriorityList.findIndex(a => a.id === action.payload) , 1);
            state.mfdeliverypriorityDashboard.mfdeliverypriorityList.splice(state.mfdeliverypriorityDashboard.mfdeliverypriorityList.findIndex(a => a.id === action.payload) , 1);
            state.mfdeliverypriorityDashboard.productObj={}
            state.mfdeliverypriorityDashboard.selectedId=-1
        },
        setSelectedMfdeliverypriorityId: (state, action)=>{
            if (action.payload!==-1){
                state.mfdeliverypriorityDashboard.selectedId=action.payload;
                state.mfdeliverypriorityDashboard.mfdeliverypriorityObj = state.mfdeliverypriorityList[
                    state.mfdeliverypriorityList.findIndex(mfdeliverypriorityObj=>mfdeliverypriorityObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfdeliverypriorityDashboard.selectedId=action.payload;
                state.mfdeliverypriorityDashboard.mfdeliverypriorityObj ={}
            }
        },
        setMfdeliverypriorityList: (state, action) =>{
            state.mfdeliverypriorityList=action.payload;
            state.mfdeliverypriorityDashboard.mfdeliverypriorityList=action.payload;
        },
        setMfdeliverypriorityDashboardMfdeliverypriorityList: (state, action) =>{
            state.mfdeliverypriorityDashboard.mfdeliverypriorityList=action.payload;
        },
        /* Mfprintingtype Reducer methods */
        setMfprintingtypeFilterCriteria : (state, action)=>{
            state.mfprintingtypeDashboard.filterCriteria=action.payload;
        },
        setShowEditForMfprintingtype: (state,action)=>{
            state.mfprintingtypeDashboard.showEdit=action.payload;
            
        },
        addMfprintingtypeObj: (state, action)=>{
            state.mfprintingtypeList.unshift(action.payload);
            state.mfprintingtypeDashboard.mfprintingtypeList.unshift(action.payload);

            state.mfprintingtypeDashboard.mfprintingtypeObj=action.payload;
            state.mfprintingtypeDashboard.selectedId=action.payload.id;
        },
        updateMfprintingtypeObj: (state, action)=>{
            let index = state.mfprintingtypeList.map(mfprintingtypeObj=>mfprintingtypeObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfprintingtypeList[index]=action.payload;
            }

            index = state.mfprintingtypeDashboard.mfprintingtypeList.map(mfprintingtypeObj=>mfprintingtypeObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfprintingtypeDashboard.mfprintingtypeList[index]=action.payload;
            }
            if (state.mfprintingtypeDashboard.mfprintingtypeObj!==null){
                state.mfprintingtypeDashboard.mfprintingtypeObj = action.payload;
            }
        },
        deleteMfprintingtypeObj: (state, action)=>{
            state.mfprintingtypeList.splice(state.mfprintingtypeList.findIndex(a => a.id === action.payload) , 1);
            state.mfprintingtypeDashboard.mfprintingtypeList.splice(state.mfprintingtypeDashboard.mfprintingtypeList.findIndex(a => a.id === action.payload) , 1);
            state.mfprintingtypeDashboard.productObj={}
            state.mfprintingtypeDashboard.selectedId=-1
        },
        setSelectedMfprintingtypeId: (state, action)=>{
            if (action.payload!==-1){
                state.mfprintingtypeDashboard.selectedId=action.payload;
                state.mfprintingtypeDashboard.mfprintingtypeObj = state.mfprintingtypeList[
                    state.mfprintingtypeList.findIndex(mfprintingtypeObj=>mfprintingtypeObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfprintingtypeDashboard.selectedId=action.payload;
                state.mfprintingtypeDashboard.mfprintingtypeObj ={}
            }
        },
        setMfprintingtypeList: (state, action) =>{
            state.mfprintingtypeList=action.payload;
            state.mfprintingtypeDashboard.mfprintingtypeList=action.payload;
        },
        setMfprintingtypeDashboardMfprintingtypeList: (state, action) =>{
            state.mfprintingtypeDashboard.mfprintingtypeList=action.payload;
        },
        /* Mforderstatus Reducer methods */
        setMforderstatusFilterCriteria : (state, action)=>{
            state.mforderstatusDashboard.filterCriteria=action.payload;
        },
        setShowEditForMforderstatus: (state,action)=>{
            state.mforderstatusDashboard.showEdit=action.payload;
            
        },
        addMforderstatusObj: (state, action)=>{
            state.mforderstatusList.unshift(action.payload);
            state.mforderstatusDashboard.mforderstatusList.unshift(action.payload);

            state.mforderstatusDashboard.mforderstatusObj=action.payload;
            state.mforderstatusDashboard.selectedId=action.payload.id;
        },
        updateMforderstatusObj: (state, action)=>{
            let index = state.mforderstatusList.map(mforderstatusObj=>mforderstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mforderstatusList[index]=action.payload;
            }

            index = state.mforderstatusDashboard.mforderstatusList.map(mforderstatusObj=>mforderstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mforderstatusDashboard.mforderstatusList[index]=action.payload;
            }
            if (state.mforderstatusDashboard.mforderstatusObj!==null){
                state.mforderstatusDashboard.mforderstatusObj = action.payload;
            }
        },
        deleteMforderstatusObj: (state, action)=>{
            state.mforderstatusList.splice(state.mforderstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mforderstatusDashboard.mforderstatusList.splice(state.mforderstatusDashboard.mforderstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mforderstatusDashboard.productObj={}
            state.mforderstatusDashboard.selectedId=-1
        },
        setSelectedMforderstatusId: (state, action)=>{
            if (action.payload!==-1){
                state.mforderstatusDashboard.selectedId=action.payload;
                state.mforderstatusDashboard.mforderstatusObj = state.mforderstatusList[
                    state.mforderstatusList.findIndex(mforderstatusObj=>mforderstatusObj.id===parseInt(action.payload))
            ];
            } else {
                state.mforderstatusDashboard.selectedId=action.payload;
                state.mforderstatusDashboard.mforderstatusObj ={}
            }
        },
        setMforderstatusList: (state, action) =>{
            state.mforderstatusList=action.payload;
            state.mforderstatusDashboard.mforderstatusList=action.payload;
        },
        setMforderstatusDashboardMforderstatusList: (state, action) =>{
            state.mforderstatusDashboard.mforderstatusList=action.payload;
        },
        /* Mforder Reducer methods */
        setMforderFilterCriteria : (state, action)=>{
            state.mforderDashboard.filterCriteria=action.payload;
        },
        setShowEditForMforder: (state,action)=>{
            state.mforderDashboard.showEdit=action.payload;
            
			state.mforderDashboard.updateMfOrderStatusModalOpen=false;
			state.mforderDashboard.manageMfOrderModalOpen=false;
			state.mforderDashboard.addMfOrderModalOpen=false;
			state.mforderDashboard.addMfProductModalOpen=false;
			state.mforderDashboard.orderToFactoryModalOpen=false;
			state.mforderDashboard.UpdateRemarksModalOpen=false;
			state.mforderDashboard.ShowProgressModalOpen=false;
			state.mforderDashboard.PrintJobTicketModalOpen=false;
        },
        addMforderObj: (state, action)=>{
            state.mforderList.unshift(action.payload);
            state.mforderDashboard.mforderList.unshift(action.payload);

            state.mforderDashboard.mforderObj=action.payload;
            state.mforderDashboard.selectedId=action.payload.id;
        },
        updateMforderObj: (state, action)=>{
            let index = state.mforderList.map(mforderObj=>mforderObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mforderList[index]=action.payload;
            }

            index = state.mforderDashboard.mforderList.map(mforderObj=>mforderObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mforderDashboard.mforderList[index]=action.payload;
            }
            if (state.mforderDashboard.mforderObj!==null){
                state.mforderDashboard.mforderObj = action.payload;
            }
        },
        deleteMforderObj: (state, action)=>{
            state.mforderList.splice(state.mforderList.findIndex(a => a.id === action.payload) , 1);
            state.mforderDashboard.mforderList.splice(state.mforderDashboard.mforderList.findIndex(a => a.id === action.payload) , 1);
            state.mforderDashboard.productObj={}
            state.mforderDashboard.selectedId=-1
        },
        setSelectedMforderId: (state, action)=>{
            if (action.payload!==-1){
                state.mforderDashboard.selectedId=action.payload;
                state.mforderDashboard.mforderObj = state.mforderList[
                    state.mforderList.findIndex(mforderObj=>mforderObj.id===parseInt(action.payload))
            ];
            } else {
                state.mforderDashboard.selectedId=action.payload;
                state.mforderDashboard.mforderObj ={}
            }
        },
        setMforderList: (state, action) =>{
            state.mforderList=action.payload;
            state.mforderDashboard.mforderList=action.payload;
        },
        setMforderDashboardMforderList: (state, action) =>{
            state.mforderDashboard.mforderList=action.payload;
        },
      
        setMforderUpdateMfOrderStatusModal:(state,action)=>{
          state.mforderDashboard.updateMfOrderStatusModalOpen=action.payload;
        },
      
        setMforderManageMfOrderModal:(state,action)=>{
          state.mforderDashboard.manageMfOrderModalOpen=action.payload;
        },
      
        setMforderAddMfOrderModal:(state,action)=>{
          state.mforderDashboard.addMfOrderModalOpen=action.payload;
        },
      
        setMforderAddMfProductModal:(state,action)=>{
          state.mforderDashboard.addMfProductModalOpen=action.payload;
        },
      
        setMforderOrderToFactoryModal:(state,action)=>{
          state.mforderDashboard.orderToFactoryModalOpen=action.payload;
        },
      
        setMforderUpdateRemarksModal:(state,action)=>{
          state.mforderDashboard.UpdateRemarksModalOpen=action.payload;
        },
      
        setMforderShowProgressModal:(state,action)=>{
          state.mforderDashboard.ShowProgressModalOpen=action.payload;
        },
      
        setMforderPrintJobTicketModal:(state,action)=>{
          state.mforderDashboard.PrintJobTicketModalOpen=action.payload;
        },
        /* Mfparty Reducer methods */
        setMfpartyFilterCriteria : (state, action)=>{
            state.mfpartyDashboard.filterCriteria=action.payload;
        },
        setShowEditForMfparty: (state,action)=>{
            state.mfpartyDashboard.showEdit=action.payload;
            
			state.mfpartyDashboard.ManagePartyModalOpen=false;
			state.mfpartyDashboard.SelectPartyModalOpen=false;
        },
        addMfpartyObj: (state, action)=>{
            state.mfpartyList.unshift(action.payload);
            state.mfpartyDashboard.mfpartyList.unshift(action.payload);

            state.mfpartyDashboard.mfpartyObj=action.payload;
            state.mfpartyDashboard.selectedId=action.payload.id;
        },
        updateMfpartyObj: (state, action)=>{
            let index = state.mfpartyList.map(mfpartyObj=>mfpartyObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfpartyList[index]=action.payload;
            }

            index = state.mfpartyDashboard.mfpartyList.map(mfpartyObj=>mfpartyObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfpartyDashboard.mfpartyList[index]=action.payload;
            }
            if (state.mfpartyDashboard.mfpartyObj!==null){
                state.mfpartyDashboard.mfpartyObj = action.payload;
            }
        },
        deleteMfpartyObj: (state, action)=>{
            state.mfpartyList.splice(state.mfpartyList.findIndex(a => a.id === action.payload) , 1);
            state.mfpartyDashboard.mfpartyList.splice(state.mfpartyDashboard.mfpartyList.findIndex(a => a.id === action.payload) , 1);
            state.mfpartyDashboard.productObj={}
            state.mfpartyDashboard.selectedId=-1
        },
        setSelectedMfpartyId: (state, action)=>{
            if (action.payload!==-1){
                state.mfpartyDashboard.selectedId=action.payload;
                state.mfpartyDashboard.mfpartyObj = state.mfpartyList[
                    state.mfpartyList.findIndex(mfpartyObj=>mfpartyObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfpartyDashboard.selectedId=action.payload;
                state.mfpartyDashboard.mfpartyObj ={}
            }
        },
        setMfpartyList: (state, action) =>{
            state.mfpartyList=action.payload;
            state.mfpartyDashboard.mfpartyList=action.payload;
        },
        setMfpartyDashboardMfpartyList: (state, action) =>{
            state.mfpartyDashboard.mfpartyList=action.payload;
        },
      
        setMfpartyManagePartyModal:(state,action)=>{
          state.mfpartyDashboard.ManagePartyModalOpen=action.payload;
        },
      
        setMfpartySelectPartyModal:(state,action)=>{
          state.mfpartyDashboard.SelectPartyModalOpen=action.payload;
        },
        /* Mfproductorder Reducer methods */
        setMfproductorderFilterCriteria : (state, action)=>{
            state.mfproductorderDashboard.filterCriteria=action.payload;
        },
        setShowEditForMfproductorder: (state,action)=>{
            state.mfproductorderDashboard.showEdit=action.payload;
            
			state.mfproductorderDashboard.updateProductOrderStatusModalOpen=false;
			state.mfproductorderDashboard.AddMfProductOrderModalOpen=false;
        },
        addMfproductorderObj: (state, action)=>{
            state.mfproductorderList.unshift(action.payload);
            state.mfproductorderDashboard.mfproductorderList.unshift(action.payload);

            state.mfproductorderDashboard.mfproductorderObj=action.payload;
            state.mfproductorderDashboard.selectedId=action.payload.id;
        },
        updateMfproductorderObj: (state, action)=>{
            let index = state.mfproductorderList.map(mfproductorderObj=>mfproductorderObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfproductorderList[index]=action.payload;
            }

            index = state.mfproductorderDashboard.mfproductorderList.map(mfproductorderObj=>mfproductorderObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfproductorderDashboard.mfproductorderList[index]=action.payload;
            }
            if (state.mfproductorderDashboard.mfproductorderObj!==null){
                state.mfproductorderDashboard.mfproductorderObj = action.payload;
            }
        },
        deleteMfproductorderObj: (state, action)=>{
            state.mfproductorderList.splice(state.mfproductorderList.findIndex(a => a.id === action.payload) , 1);
            state.mfproductorderDashboard.mfproductorderList.splice(state.mfproductorderDashboard.mfproductorderList.findIndex(a => a.id === action.payload) , 1);
            state.mfproductorderDashboard.productObj={}
            state.mfproductorderDashboard.selectedId=-1
        },
        setSelectedMfproductorderId: (state, action)=>{
            if (action.payload!==-1){
                state.mfproductorderDashboard.selectedId=action.payload;
                state.mfproductorderDashboard.mfproductorderObj = state.mfproductorderList[
                    state.mfproductorderList.findIndex(mfproductorderObj=>mfproductorderObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfproductorderDashboard.selectedId=action.payload;
                state.mfproductorderDashboard.mfproductorderObj ={}
            }
        },
        setMfproductorderList: (state, action) =>{
            state.mfproductorderList=action.payload;
            state.mfproductorderDashboard.mfproductorderList=action.payload;
        },
        setMfproductorderDashboardMfproductorderList: (state, action) =>{
            state.mfproductorderDashboard.mfproductorderList=action.payload;
        },
      
        setMfproductorderUpdateProductOrderStatusModal:(state,action)=>{
          state.mfproductorderDashboard.updateProductOrderStatusModalOpen=action.payload;
        },
      
        setMfproductorderAddMfProductOrderModal:(state,action)=>{
          state.mfproductorderDashboard.AddMfProductOrderModalOpen=action.payload;
        },
        /* Mfproduct Reducer methods */
        setMfproductFilterCriteria : (state, action)=>{
            state.mfproductDashboard.filterCriteria=action.payload;
        },
        setShowEditForMfproduct: (state,action)=>{
            state.mfproductDashboard.showEdit=action.payload;
            
        },
        addMfproductObj: (state, action)=>{
            state.mfproductList.unshift(action.payload);
            state.mfproductDashboard.mfproductList.unshift(action.payload);

            state.mfproductDashboard.mfproductObj=action.payload;
            state.mfproductDashboard.selectedId=action.payload.id;
        },
        updateMfproductObj: (state, action)=>{
            let index = state.mfproductList.map(mfproductObj=>mfproductObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfproductList[index]=action.payload;
            }

            index = state.mfproductDashboard.mfproductList.map(mfproductObj=>mfproductObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfproductDashboard.mfproductList[index]=action.payload;
            }
            if (state.mfproductDashboard.mfproductObj!==null){
                state.mfproductDashboard.mfproductObj = action.payload;
            }
        },
        deleteMfproductObj: (state, action)=>{
            state.mfproductList.splice(state.mfproductList.findIndex(a => a.id === action.payload) , 1);
            state.mfproductDashboard.mfproductList.splice(state.mfproductDashboard.mfproductList.findIndex(a => a.id === action.payload) , 1);
            state.mfproductDashboard.productObj={}
            state.mfproductDashboard.selectedId=-1
        },
        setSelectedMfproductId: (state, action)=>{
            if (action.payload!==-1){
                state.mfproductDashboard.selectedId=action.payload;
                state.mfproductDashboard.mfproductObj = state.mfproductList[
                    state.mfproductList.findIndex(mfproductObj=>mfproductObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfproductDashboard.selectedId=action.payload;
                state.mfproductDashboard.mfproductObj ={}
            }
        },
        setMfproductList: (state, action) =>{
            state.mfproductList=action.payload;
            state.mfproductDashboard.mfproductList=action.payload;
        },
        setMfproductDashboardMfproductList: (state, action) =>{
            state.mfproductDashboard.mfproductList=action.payload;
        },
        /* Mfproductorderstatus Reducer methods */
        setMfproductorderstatusFilterCriteria : (state, action)=>{
            state.mfproductorderstatusDashboard.filterCriteria=action.payload;
        },
        setShowEditForMfproductorderstatus: (state,action)=>{
            state.mfproductorderstatusDashboard.showEdit=action.payload;
            
        },
        addMfproductorderstatusObj: (state, action)=>{
            state.mfproductorderstatusList.unshift(action.payload);
            state.mfproductorderstatusDashboard.mfproductorderstatusList.unshift(action.payload);

            state.mfproductorderstatusDashboard.mfproductorderstatusObj=action.payload;
            state.mfproductorderstatusDashboard.selectedId=action.payload.id;
        },
        updateMfproductorderstatusObj: (state, action)=>{
            let index = state.mfproductorderstatusList.map(mfproductorderstatusObj=>mfproductorderstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfproductorderstatusList[index]=action.payload;
            }

            index = state.mfproductorderstatusDashboard.mfproductorderstatusList.map(mfproductorderstatusObj=>mfproductorderstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfproductorderstatusDashboard.mfproductorderstatusList[index]=action.payload;
            }
            if (state.mfproductorderstatusDashboard.mfproductorderstatusObj!==null){
                state.mfproductorderstatusDashboard.mfproductorderstatusObj = action.payload;
            }
        },
        deleteMfproductorderstatusObj: (state, action)=>{
            state.mfproductorderstatusList.splice(state.mfproductorderstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mfproductorderstatusDashboard.mfproductorderstatusList.splice(state.mfproductorderstatusDashboard.mfproductorderstatusList.findIndex(a => a.id === action.payload) , 1);
            state.mfproductorderstatusDashboard.productObj={}
            state.mfproductorderstatusDashboard.selectedId=-1
        },
        setSelectedMfproductorderstatusId: (state, action)=>{
            if (action.payload!==-1){
                state.mfproductorderstatusDashboard.selectedId=action.payload;
                state.mfproductorderstatusDashboard.mfproductorderstatusObj = state.mfproductorderstatusList[
                    state.mfproductorderstatusList.findIndex(mfproductorderstatusObj=>mfproductorderstatusObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfproductorderstatusDashboard.selectedId=action.payload;
                state.mfproductorderstatusDashboard.mfproductorderstatusObj ={}
            }
        },
        setMfproductorderstatusList: (state, action) =>{
            state.mfproductorderstatusList=action.payload;
            state.mfproductorderstatusDashboard.mfproductorderstatusList=action.payload;
        },
        setMfproductorderstatusDashboardMfproductorderstatusList: (state, action) =>{
            state.mfproductorderstatusDashboard.mfproductorderstatusList=action.payload;
        },
        /* Businessprocess Reducer methods */
        setBusinessprocessFilterCriteria : (state, action)=>{
            state.businessprocessDashboard.filterCriteria=action.payload;
        },
        setShowEditForBusinessprocess: (state,action)=>{
            state.businessprocessDashboard.showEdit=action.payload;
            
			state.businessprocessDashboard.TaskMgmtModalOpen=false;
        },
        addBusinessprocessObj: (state, action)=>{
            state.businessprocessList.unshift(action.payload);
            state.businessprocessDashboard.businessprocessList.unshift(action.payload);

            state.businessprocessDashboard.businessprocessObj=action.payload;
            state.businessprocessDashboard.selectedId=action.payload.id;
        },
        updateBusinessprocessObj: (state, action)=>{
            let index = state.businessprocessList.map(businessprocessObj=>businessprocessObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.businessprocessList[index]=action.payload;
            }

            index = state.businessprocessDashboard.businessprocessList.map(businessprocessObj=>businessprocessObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.businessprocessDashboard.businessprocessList[index]=action.payload;
            }
            if (state.businessprocessDashboard.businessprocessObj!==null){
                state.businessprocessDashboard.businessprocessObj = action.payload;
            }
        },
        deleteBusinessprocessObj: (state, action)=>{
            state.businessprocessList.splice(state.businessprocessList.findIndex(a => a.id === action.payload) , 1);
            state.businessprocessDashboard.businessprocessList.splice(state.businessprocessDashboard.businessprocessList.findIndex(a => a.id === action.payload) , 1);
            state.businessprocessDashboard.productObj={}
            state.businessprocessDashboard.selectedId=-1
        },
        setSelectedBusinessprocessId: (state, action)=>{
            if (action.payload!==-1){
                state.businessprocessDashboard.selectedId=action.payload;
                state.businessprocessDashboard.businessprocessObj = state.businessprocessList[
                    state.businessprocessList.findIndex(businessprocessObj=>businessprocessObj.id===parseInt(action.payload))
            ];
            } else {
                state.businessprocessDashboard.selectedId=action.payload;
                state.businessprocessDashboard.businessprocessObj ={}
            }
        },
        setBusinessprocessList: (state, action) =>{
            state.businessprocessList=action.payload;
            state.businessprocessDashboard.businessprocessList=action.payload;
        },
        setBusinessprocessDashboardBusinessprocessList: (state, action) =>{
            state.businessprocessDashboard.businessprocessList=action.payload;
        },
      
        setBusinessprocessTaskMgmtModal:(state,action)=>{
          state.businessprocessDashboard.TaskMgmtModalOpen=action.payload;
        },
        /* Task Reducer methods */
        setTaskFilterCriteria : (state, action)=>{
            state.taskDashboard.filterCriteria=action.payload;
        },
        setShowEditForTask: (state,action)=>{
            state.taskDashboard.showEdit=action.payload;
            
        },
        addTaskObj: (state, action)=>{
            state.taskList.unshift(action.payload);
            state.taskDashboard.taskList.unshift(action.payload);

            state.taskDashboard.taskObj=action.payload;
            state.taskDashboard.selectedId=action.payload.id;
        },
        updateTaskObj: (state, action)=>{
            let index = state.taskList.map(taskObj=>taskObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.taskList[index]=action.payload;
            }

            index = state.taskDashboard.taskList.map(taskObj=>taskObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.taskDashboard.taskList[index]=action.payload;
            }
            if (state.taskDashboard.taskObj!==null){
                state.taskDashboard.taskObj = action.payload;
            }
        },
        deleteTaskObj: (state, action)=>{
            state.taskList.splice(state.taskList.findIndex(a => a.id === action.payload) , 1);
            state.taskDashboard.taskList.splice(state.taskDashboard.taskList.findIndex(a => a.id === action.payload) , 1);
            state.taskDashboard.productObj={}
            state.taskDashboard.selectedId=-1
        },
        setSelectedTaskId: (state, action)=>{
            if (action.payload!==-1){
                state.taskDashboard.selectedId=action.payload;
                state.taskDashboard.taskObj = state.taskList[
                    state.taskList.findIndex(taskObj=>taskObj.id===parseInt(action.payload))
            ];
            } else {
                state.taskDashboard.selectedId=action.payload;
                state.taskDashboard.taskObj ={}
            }
        },
        setTaskList: (state, action) =>{
            state.taskList=action.payload;
            state.taskDashboard.taskList=action.payload;
        },
        setTaskDashboardTaskList: (state, action) =>{
            state.taskDashboard.taskList=action.payload;
        },
        /* Taskinstance Reducer methods */
        setTaskinstanceFilterCriteria : (state, action)=>{
            state.taskinstanceDashboard.filterCriteria=action.payload;
        },
        setShowEditForTaskinstance: (state,action)=>{
            state.taskinstanceDashboard.showEdit=action.payload;
            
			state.taskinstanceDashboard.UpdateStatusModalOpen=false;
        },
        addTaskinstanceObj: (state, action)=>{
            state.taskinstanceList.unshift(action.payload);
            state.taskinstanceDashboard.taskinstanceList.unshift(action.payload);

            // state.taskinstanceDashboard.taskinstanceObj=action.payload;
            // state.taskinstanceDashboard.selectedId=action.payload.id;
        },
        updateTaskinstanceObj: (state, action)=>{
            let index = state.taskinstanceList.map(taskinstanceObj=>taskinstanceObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.taskinstanceList[index]=action.payload;
            }

            index = state.taskinstanceDashboard.taskinstanceList.map(taskinstanceObj=>taskinstanceObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.taskinstanceDashboard.taskinstanceList[index]=action.payload;
            }
            if (state.taskinstanceDashboard.taskinstanceObj!==null){
                state.taskinstanceDashboard.taskinstanceObj = action.payload;
            }
        },
        deleteTaskinstanceObj: (state, action)=>{
            state.taskinstanceList.splice(state.taskinstanceList.findIndex(a => a.id === action.payload) , 1);
            state.taskinstanceDashboard.taskinstanceList.splice(state.taskinstanceDashboard.taskinstanceList.findIndex(a => a.id === action.payload) , 1);
            state.taskinstanceDashboard.productObj={}
            state.taskinstanceDashboard.selectedId=-1
        },
        setSelectedTaskinstanceId: (state, action)=>{
            if (action.payload!==-1){
                state.taskinstanceDashboard.selectedId=action.payload;
                state.taskinstanceDashboard.taskinstanceObj = state.taskinstanceList[
                    state.taskinstanceList.findIndex(taskinstanceObj=>taskinstanceObj.id===parseInt(action.payload))
            ];
            } else {
                state.taskinstanceDashboard.selectedId=action.payload;
                state.taskinstanceDashboard.taskinstanceObj ={}
            }
        },
        setTaskinstanceList: (state, action) =>{
            state.taskinstanceList=action.payload;
            state.taskinstanceDashboard.taskinstanceList=action.payload;
        },
        setTaskinstanceDashboardTaskinstanceList: (state, action) =>{
            state.taskinstanceDashboard.taskinstanceList=action.payload;
        },
      
        setTaskinstanceUpdateStatusModal:(state,action)=>{
          state.taskinstanceDashboard.UpdateStatusModalOpen=action.payload;
        },
        /* Tasktype Reducer methods */
        setTasktypeFilterCriteria : (state, action)=>{
            state.tasktypeDashboard.filterCriteria=action.payload;
        },
        setShowEditForTasktype: (state,action)=>{
            state.tasktypeDashboard.showEdit=action.payload;
            
        },
        addTasktypeObj: (state, action)=>{
            state.tasktypeList.unshift(action.payload);
            state.tasktypeDashboard.tasktypeList.unshift(action.payload);

            state.tasktypeDashboard.tasktypeObj=action.payload;
            state.tasktypeDashboard.selectedId=action.payload.id;
        },
        updateTasktypeObj: (state, action)=>{
            let index = state.tasktypeList.map(tasktypeObj=>tasktypeObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.tasktypeList[index]=action.payload;
            }

            index = state.tasktypeDashboard.tasktypeList.map(tasktypeObj=>tasktypeObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.tasktypeDashboard.tasktypeList[index]=action.payload;
            }
            if (state.tasktypeDashboard.tasktypeObj!==null){
                state.tasktypeDashboard.tasktypeObj = action.payload;
            }
        },
        deleteTasktypeObj: (state, action)=>{
            state.tasktypeList.splice(state.tasktypeList.findIndex(a => a.id === action.payload) , 1);
            state.tasktypeDashboard.tasktypeList.splice(state.tasktypeDashboard.tasktypeList.findIndex(a => a.id === action.payload) , 1);
            state.tasktypeDashboard.productObj={}
            state.tasktypeDashboard.selectedId=-1
        },
        setSelectedTasktypeId: (state, action)=>{
            if (action.payload!==-1){
                state.tasktypeDashboard.selectedId=action.payload;
                state.tasktypeDashboard.tasktypeObj = state.tasktypeList[
                    state.tasktypeList.findIndex(tasktypeObj=>tasktypeObj.id===parseInt(action.payload))
            ];
            } else {
                state.tasktypeDashboard.selectedId=action.payload;
                state.tasktypeDashboard.tasktypeObj ={}
            }
        },
        setTasktypeList: (state, action) =>{
            state.tasktypeList=action.payload;
            state.tasktypeDashboard.tasktypeList=action.payload;
        },
        setTasktypeDashboardTasktypeList: (state, action) =>{
            state.tasktypeDashboard.tasktypeList=action.payload;
        },
        /* Factoryrole Reducer methods */
        setFactoryroleFilterCriteria : (state, action)=>{
            state.factoryroleDashboard.filterCriteria=action.payload;
        },
        setShowEditForFactoryrole: (state,action)=>{
            state.factoryroleDashboard.showEdit=action.payload;
            
        },
        addFactoryroleObj: (state, action)=>{
            state.factoryroleList.unshift(action.payload);
            state.factoryroleDashboard.factoryroleList.unshift(action.payload);

            state.factoryroleDashboard.factoryroleObj=action.payload;
            state.factoryroleDashboard.selectedId=action.payload.id;
        },
        updateFactoryroleObj: (state, action)=>{
            let index = state.factoryroleList.map(factoryroleObj=>factoryroleObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.factoryroleList[index]=action.payload;
            }

            index = state.factoryroleDashboard.factoryroleList.map(factoryroleObj=>factoryroleObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.factoryroleDashboard.factoryroleList[index]=action.payload;
            }
            if (state.factoryroleDashboard.factoryroleObj!==null){
                state.factoryroleDashboard.factoryroleObj = action.payload;
            }
        },
        deleteFactoryroleObj: (state, action)=>{
            state.factoryroleList.splice(state.factoryroleList.findIndex(a => a.id === action.payload) , 1);
            state.factoryroleDashboard.factoryroleList.splice(state.factoryroleDashboard.factoryroleList.findIndex(a => a.id === action.payload) , 1);
            state.factoryroleDashboard.productObj={}
            state.factoryroleDashboard.selectedId=-1
        },
        setSelectedFactoryroleId: (state, action)=>{
            if (action.payload!==-1){
                state.factoryroleDashboard.selectedId=action.payload;
                state.factoryroleDashboard.factoryroleObj = state.factoryroleList[
                    state.factoryroleList.findIndex(factoryroleObj=>factoryroleObj.id===parseInt(action.payload))
            ];
            } else {
                state.factoryroleDashboard.selectedId=action.payload;
                state.factoryroleDashboard.factoryroleObj ={}
            }
        },
        setFactoryroleList: (state, action) =>{
            state.factoryroleList=action.payload;
            state.factoryroleDashboard.factoryroleList=action.payload;
        },
        setFactoryroleDashboardFactoryroleList: (state, action) =>{
            state.factoryroleDashboard.factoryroleList=action.payload;
        },
        /* Taskstatus Reducer methods */
        setTaskstatusFilterCriteria : (state, action)=>{
            state.taskstatusDashboard.filterCriteria=action.payload;
        },
        setShowEditForTaskstatus: (state,action)=>{
            state.taskstatusDashboard.showEdit=action.payload;
            
        },
        addTaskstatusObj: (state, action)=>{
            state.taskstatusList.unshift(action.payload);
            state.taskstatusDashboard.taskstatusList.unshift(action.payload);

            state.taskstatusDashboard.taskstatusObj=action.payload;
            state.taskstatusDashboard.selectedId=action.payload.id;
        },
        updateTaskstatusObj: (state, action)=>{
            let index = state.taskstatusList.map(taskstatusObj=>taskstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.taskstatusList[index]=action.payload;
            }

            index = state.taskstatusDashboard.taskstatusList.map(taskstatusObj=>taskstatusObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.taskstatusDashboard.taskstatusList[index]=action.payload;
            }
            if (state.taskstatusDashboard.taskstatusObj!==null){
                state.taskstatusDashboard.taskstatusObj = action.payload;
            }
        },
        deleteTaskstatusObj: (state, action)=>{
            state.taskstatusList.splice(state.taskstatusList.findIndex(a => a.id === action.payload) , 1);
            state.taskstatusDashboard.taskstatusList.splice(state.taskstatusDashboard.taskstatusList.findIndex(a => a.id === action.payload) , 1);
            state.taskstatusDashboard.productObj={}
            state.taskstatusDashboard.selectedId=-1
        },
        setSelectedTaskstatusId: (state, action)=>{
            if (action.payload!==-1){
                state.taskstatusDashboard.selectedId=action.payload;
                state.taskstatusDashboard.taskstatusObj = state.taskstatusList[
                    state.taskstatusList.findIndex(taskstatusObj=>taskstatusObj.id===parseInt(action.payload))
            ];
            } else {
                state.taskstatusDashboard.selectedId=action.payload;
                state.taskstatusDashboard.taskstatusObj ={}
            }
        },
        setTaskstatusList: (state, action) =>{
            state.taskstatusList=action.payload;
            state.taskstatusDashboard.taskstatusList=action.payload;
        },
        setTaskstatusDashboardTaskstatusList: (state, action) =>{
            state.taskstatusDashboard.taskstatusList=action.payload;
        },
    }
});
export const { setActionList, setSelectedActionId, setMfprintingstatusList,getMfprintingstatusList,updateMfprintingstatusObj,addMfprintingstatusObj,deleteMfprintingstatusObj,
setSelectedMfprintingstatusId,
setMfprintingstatusDashboardMfprintingstatusList,
setShowEditForMfprintingstatus,
setMfprintingstatusFilterCriteria,setMfstitchingstatusList,getMfstitchingstatusList,updateMfstitchingstatusObj,addMfstitchingstatusObj,deleteMfstitchingstatusObj,
setSelectedMfstitchingstatusId,
setMfstitchingstatusDashboardMfstitchingstatusList,
setShowEditForMfstitchingstatus,
setMfstitchingstatusFilterCriteria,setMfdigitalstatusList,getMfdigitalstatusList,updateMfdigitalstatusObj,addMfdigitalstatusObj,deleteMfdigitalstatusObj,
setSelectedMfdigitalstatusId,
setMfdigitalstatusDashboardMfdigitalstatusList,
setShowEditForMfdigitalstatus,
setMfdigitalstatusFilterCriteria,setMfdeliverypriorityList,getMfdeliverypriorityList,updateMfdeliverypriorityObj,addMfdeliverypriorityObj,deleteMfdeliverypriorityObj,
setSelectedMfdeliverypriorityId,
setMfdeliverypriorityDashboardMfdeliverypriorityList,
setShowEditForMfdeliverypriority,
setMfdeliverypriorityFilterCriteria,setMfprintingtypeList,getMfprintingtypeList,updateMfprintingtypeObj,addMfprintingtypeObj,deleteMfprintingtypeObj,
setSelectedMfprintingtypeId,
setMfprintingtypeDashboardMfprintingtypeList,
setShowEditForMfprintingtype,
setMfprintingtypeFilterCriteria,setMforderstatusList,getMforderstatusList,updateMforderstatusObj,addMforderstatusObj,deleteMforderstatusObj,
setSelectedMforderstatusId,
setMforderstatusDashboardMforderstatusList,
setShowEditForMforderstatus,
setMforderstatusFilterCriteria,setMforderList,getMforderList,updateMforderObj,addMforderObj,deleteMforderObj,
setSelectedMforderId,
setMforderDashboardMforderList,
setShowEditForMforder,
setMforderFilterCriteria,
setMforderUpdateMfOrderStatusModal,
setMforderManageMfOrderModal,
setMforderAddMfOrderModal,
setMforderAddMfProductModal,
setMforderOrderToFactoryModal,
setMforderUpdateRemarksModal,
setMforderShowProgressModal,
setMforderPrintJobTicketModal,setMfpartyList,getMfpartyList,updateMfpartyObj,addMfpartyObj,deleteMfpartyObj,
setSelectedMfpartyId,
setMfpartyDashboardMfpartyList,
setShowEditForMfparty,
setMfpartyFilterCriteria,
setMfpartyManagePartyModal,
setMfpartySelectPartyModal,setMfproductorderList,getMfproductorderList,updateMfproductorderObj,addMfproductorderObj,deleteMfproductorderObj,
setSelectedMfproductorderId,
setMfproductorderDashboardMfproductorderList,
setShowEditForMfproductorder,
setMfproductorderFilterCriteria,
setMfproductorderUpdateProductOrderStatusModal,
setMfproductorderAddMfProductOrderModal,setMfproductList,getMfproductList,updateMfproductObj,addMfproductObj,deleteMfproductObj,
setSelectedMfproductId,
setMfproductDashboardMfproductList,
setShowEditForMfproduct,
setMfproductFilterCriteria,setMfproductorderstatusList,getMfproductorderstatusList,updateMfproductorderstatusObj,addMfproductorderstatusObj,deleteMfproductorderstatusObj,
setSelectedMfproductorderstatusId,
setMfproductorderstatusDashboardMfproductorderstatusList,
setShowEditForMfproductorderstatus,
setMfproductorderstatusFilterCriteria,setBusinessprocessList,getBusinessprocessList,updateBusinessprocessObj,addBusinessprocessObj,deleteBusinessprocessObj,
setSelectedBusinessprocessId,
setBusinessprocessDashboardBusinessprocessList,
setShowEditForBusinessprocess,
setBusinessprocessFilterCriteria,
setBusinessprocessTaskMgmtModal,setTaskList,getTaskList,updateTaskObj,addTaskObj,deleteTaskObj,
setSelectedTaskId,
setTaskDashboardTaskList,
setShowEditForTask,
setTaskFilterCriteria,setTaskinstanceList,getTaskinstanceList,updateTaskinstanceObj,addTaskinstanceObj,deleteTaskinstanceObj,
setSelectedTaskinstanceId,
setTaskinstanceDashboardTaskinstanceList,
setShowEditForTaskinstance,
setTaskinstanceFilterCriteria,
setTaskinstanceUpdateStatusModal,setTasktypeList,getTasktypeList,updateTasktypeObj,addTasktypeObj,deleteTasktypeObj,
setSelectedTasktypeId,
setTasktypeDashboardTasktypeList,
setShowEditForTasktype,
setTasktypeFilterCriteria,setFactoryroleList,getFactoryroleList,updateFactoryroleObj,addFactoryroleObj,deleteFactoryroleObj,
setSelectedFactoryroleId,
setFactoryroleDashboardFactoryroleList,
setShowEditForFactoryrole,
setFactoryroleFilterCriteria,setTaskstatusList,getTaskstatusList,updateTaskstatusObj,addTaskstatusObj,deleteTaskstatusObj,
setSelectedTaskstatusId,
setTaskstatusDashboardTaskstatusList,
setShowEditForTaskstatus,
setTaskstatusFilterCriteria, } = ManufSlice.actions;
export default ManufSlice.reducer;