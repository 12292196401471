import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import TaskinstanceFilterCriteria from './TaskinstanceFilterCriteria'
import TaskinstanceList from './TaskinstanceList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './TaskinstanceAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setTaskinstanceDashboardTaskinstanceList } from '../../store/slice/ManufSlice';

export default function TaskinstanceListView({setShowEdit,setTaskinstanceId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.taskinstanceDashboard);   

    const [filteredTaskinstanceList, setFilteredTaskinstanceList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { taskinstanceDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (taskinstanceDashboard.taskinstanceList.length>-1){
        setFilteredTaskinstanceList(taskinstanceDashboard.taskinstanceList);
        transformToCardFormat(taskinstanceDashboard.taskinstanceList, setCardList);
      }
    },[taskinstanceDashboard.taskinstanceList])

    useEffect(()=>{
          transformToCardFormat(filteredTaskinstanceList, setCardList);
      },[filteredTaskinstanceList])

    useEffect(()=>{
        if (filterCriteria.title==='' && filterCriteria.orderid==='' && filterCriteria.taskid==='' && filterCriteria.taskname==='' && filterCriteria.taskstatusid==='' && filterCriteria.taskstatusname==='' && filterCriteria.businessprocessid==='' && filterCriteria.productid==='' && filterCriteria.productorderid==='' ){
            setFilteredTaskinstanceList(taskinstanceDashboard.taskinstanceList);
            transformToCardFormat(taskinstanceDashboard.taskinstanceList, setCardList);

        } else {
            setFilteredTaskinstanceList(
                taskinstanceDashboard.taskinstanceList
                    .filter(taskinstance=> taskinstance.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(taskinstanceDashboard.filteredTaskinstanceList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <TaskinstanceList
                    setShowEdit={setShowEdit}
                    setTaskinstanceId = {setTaskinstanceId}
                    tableList={filteredTaskinstanceList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}