import {useState, useEffect} from 'react';
import { Grid, Button, Typography } from '@mui/material';
import MforderInfo from './MforderInfo';
import MfproductorderInfo from 'components/mfproductorder/action/updateProductOrderStatus/MfproductorderInfo';
import UpdateStatusRadio from './UpdateStatusRadio';
import TaskinstanceInfo from './TaskinstanceInfo';
import { useSelector } from 'react-redux';

export default function UpdateStatusActionImpl(){
    const {taskinstanceObj} = useSelector((state)=>state.manufslice.taskinstanceDashboard);
    useEffect(()=>{

    },[])
    return (
        <>
        <Grid container >
            <Grid item lg={12} xs={12} sx={{padding:1}}><MforderInfo/></Grid>
            <Grid item lg={4} xs={12} sx={{padding:1}}><MfproductorderInfo/></Grid>
            <Grid item lg={4} xs={12} sx={{padding:1}}><TaskinstanceInfo/></Grid>
            <Grid item lg={4} xs={12} sx={{padding:1}}><UpdateStatusRadio/></Grid>
        </Grid>
        
       
        </>
    )
}