import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography, Button } from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import { useNavigate } from "react-router-dom";
import { transformDateValue } from 'core/js/FormUtils';
import EditIcon from '@mui/icons-material/Edit';
import { setMfpartySelectPartyModal } from 'store/slice/OrderMgmtSlice';
import { setMforderUpdateRemarksModal } from 'store/slice/ManufSlice';


export default function MforderInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mforderObj } = useSelector((state) => state.manufslice.mforderDashboard);
  

  useEffect(() => {
    if (mforderObj.id === undefined) {
      navigate('/mforder');
    }
  }, []);

  useEffect(()=>{

  },[mforderObj])

  const editParty = () =>{
        dispatch(setMfpartySelectPartyModal(true));
  }
  const editRemarks = () =>{
    dispatch(setMforderUpdateRemarksModal(true));
}

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ backgroundColor: 'white',padding:1 }}
    >
      <Grid item lg={1} xs={4}>
        <Grid container direction="column" sx={{ width: '100%' }}>
          <Grid item sx={{  }}>
            <Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}  >Order #</Typography>
            <Typography variant="h6">{mforderObj.id}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={1} xs={4}>
        <Grid container direction="column" sx={{ width: '100%' }}>
          <Grid item >
            <Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }} >Order Date</Typography>
            <Typography variant="h6">{transformDateValue(mforderObj.orderdate)}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={2} xs={4}>
        <Grid container direction="column" sx={{ width: '100%' }}>
          <Grid item >
            <Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }} >Executive</Typography>
            <Typography variant="h6">{mforderObj.executive}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={4} xs={12} sx={{paddingTop:1}}>
        <Grid container direction="row" alignItems="center">
          <Grid item lg={10} xs={11}>
            <Grid container direction="column" sx={{ width: '100%' }}>
              <Grid item sx={{ backgroundColor: 'white'}}>
                <Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 15 }} >Party</Typography>
                <Typography variant="h5" color="primary">{mforderObj.partyname}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container lg={1} xs={1} justifyContent="center" sx={{cursor:'pointer'}} onClick={()=>{editParty()}}>
            <EditIcon/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} xs={12} sx={{paddingTop:1}}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{height:'70%'}}>
          <Grid item lg={11} xs={11}>
            <Grid container direction="column" sx={{ width: '100%' }}>
              <Grid item >
                <Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 15 }} >Remarks</Typography>
                <Typography variant="h6">{mforderObj.remarks}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container lg={1} xs={1} justifyContent="center" sx={{cursor:'pointer'}} onClick={()=>{editRemarks()}}>
            <EditIcon/>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}