import { transformDateValue } from 'core/js/FormUtils';
export const getMforderView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let mforderList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Party Name","fieldValue":transformDataValue(data.partyname,"string"), "fieldType":"string"},
                            {"fieldLabel":"Order Date","fieldValue":transformDataValue(data.orderdate,"date"), "fieldType":"date"},
                            {"fieldLabel":"Order Status Name","fieldValue":transformDataValue(data.orderstatusname,"string"), "fieldType":"string"},
              
              
            ],
            'dataList': [
                              {"fieldLabel":"Remarks","fieldValue":transformDataValue(data.remarks,"string"), "fieldType":"string"},
                            {"fieldLabel":"Executive","fieldValue":transformDataValue(data.executive,"string"), "fieldType":"string"},
              
              ]
          }

          mforderList.push(card);
  })
  return mforderList;
}