
import ContactsIcon from '@mui/icons-material/Contacts';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SettingsIcon from '@mui/icons-material/Settings';


const FactoryOperations = {
  id: '8',
  title: 'Factory Operations',
  type: 'group',
  role:'FACTORY_MANAGER,FACTORY_ADMIN',
  children: [
    {
      id: 'mfproductorder',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      bottomMenu:'HOME',
      icon: SettingsIcon
    },
    {
      id: 'mforder',
      title: 'Order [In Progress]',
      type: 'item',
      url: '/',
      bottomMenu:'ORDERS',
      icon: BusinessCenterIcon
    },
    {
      id: 'orderarchived',
      title: 'Order [Archived] ',
      type: 'item',
      url: '/',
      bottomMenu:'ARCHIVED-ORDERS',
      icon: ContactsIcon
    },
    {
      id: 'mfparty',
      title: 'Party',
      type: 'item',
      url: '/mfparty',
      icon: ContactsIcon
    }
  ]
};

export default FactoryOperations;