import { transformDateValue } from 'core/js/FormUtils';
export const getMfproductorderstatusView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let mfproductorderstatusList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
              
              
            ],
            'dataList': [
                
              ]
          }

          mfproductorderstatusList.push(card);
  })
  return mfproductorderstatusList;
}