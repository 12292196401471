import { transformDateValue } from 'core/js/FormUtils';
export const getMfpartyView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let mfpartyList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
                            {"fieldLabel":"Mobile","fieldValue":transformDataValue(data.mobile,"mobile"), "fieldType":"mobile"},
                            {"fieldLabel":"GST","fieldValue":transformDataValue(data.gst,"string"), "fieldType":"string"},
              
              
            ],
            'dataList': [
                              {"fieldLabel":"email","fieldValue":transformDataValue(data.email,"emailid"), "fieldType":"emailid"},
                            {"fieldLabel":"Address","fieldValue":transformDataValue(data.address,"string"), "fieldType":"string"},
              
              ]
          }

          mfpartyList.push(card);
  })
  return mfpartyList;
}