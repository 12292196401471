import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import FactoryroleFilterCriteria from './FactoryroleFilterCriteria'
import FactoryroleList from './FactoryroleList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './FactoryroleAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setFactoryroleDashboardFactoryroleList } from '../../store/slice/ManufSlice';

export default function FactoryroleListView({setShowEdit,setFactoryroleId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.factoryroleDashboard);   

    const [filteredFactoryroleList, setFilteredFactoryroleList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { factoryroleDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (factoryroleDashboard.factoryroleList.length>-1){
        setFilteredFactoryroleList(factoryroleDashboard.factoryroleList);
        transformToCardFormat(factoryroleDashboard.factoryroleList, setCardList);
      }
    },[factoryroleDashboard.factoryroleList])

    useEffect(()=>{
          transformToCardFormat(filteredFactoryroleList, setCardList);
      },[filteredFactoryroleList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredFactoryroleList(factoryroleDashboard.factoryroleList);
            transformToCardFormat(factoryroleDashboard.factoryroleList, setCardList);

        } else {
            setFilteredFactoryroleList(
                factoryroleDashboard.factoryroleList
                    .filter(factoryrole=> factoryrole.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(factoryroleDashboard.filteredFactoryroleList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <FactoryroleList
                    setShowEdit={setShowEdit}
                    setFactoryroleId = {setFactoryroleId}
                    tableList={filteredFactoryroleList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}