import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function MfproductorderInfoByProductObj({mfproductorderObj}){
  
    return (
      
        <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{backgroundColor:'secondary.200',borderBottom:'1px solid #bae7ff'}}
        width='100%'
      >
            
        <Grid item lg={2} xs={4}><FieldView label="Matter"  color="secondary.200" value={ mfproductorderObj.matter!==null && mfproductorderObj.matter!==undefined?mfproductorderObj.matter:'NOT KNONWN' }/></Grid>
        <Grid item lg={1} xs={4}><FieldView label="Qty" color="secondary.200"  value={ mfproductorderObj.qty!==null && mfproductorderObj.qty!==undefined?mfproductorderObj.qty:'NOT KNONWN' }/></Grid>
        <Grid item lg={1} xs={4}><FieldView label="Unit" color="secondary.200"  value={ mfproductorderObj.unit!==null && mfproductorderObj.unit!==undefined?mfproductorderObj.unit:'NOT KNONWN' }/></Grid>
        <Grid item lg={2} xs={4}><FieldView label="Priority" color="secondary.200" value={ mfproductorderObj.deliverypriorityname!==null && mfproductorderObj.deliverypriorityname!==undefined?mfproductorderObj.deliverypriorityname:'NOT KNONWN' }/></Grid>
        <Grid item lg={2} xs={4}><FieldView label="Printing Type" color="secondary.200" value={ mfproductorderObj.printingtype?'Digital':'Screen'}/></Grid>
       
        <Grid item lg={2} xs={4}><FieldView label="Stitching Type" color="secondary.200" value={ mfproductorderObj.stichingtype?'Simple':'Technical'}/></Grid>
        <Grid item lg={2} xs={4}><FieldView label="Delivery By" color="secondary.200" value={ mfproductorderObj.expecteddelivery!==null && mfproductorderObj.expecteddelivery!==undefined?mfproductorderObj.expecteddelivery.split('T')[0]:'NOT KNONWN' }/></Grid>
        
        <Grid item lg={2} xs={12}><FieldView label="Remarks" color="secondary.200" value={ mfproductorderObj.remarks}/></Grid>
        {/* <Grid item lg={2} xs={6}><FieldView label="Stitching Status"  value={ mfproductorderObj.stitchingstatusname!=='' && mfproductorderObj.stitchingstatusname!==null && mfproductorderObj.stitchingstatusname!==undefined?mfproductorderObj.stitchingstatusname:'NOT KNONWN' }/></Grid>
        <Grid item lg={2} xs={6}><FieldView label="Digital Status"  value={mfproductorderObj.digitalstatusname!=='' && mfproductorderObj.digitalstatusname!==null && mfproductorderObj.digitalstatusname!==undefined?mfproductorderObj.digitalstatusname:'NOT KNONWN' }/></Grid>
        <Grid item lg={1} xs={6}><FieldView label="Product Status"  value={ mfproductorderObj.productorderstatusname!=='' || mfproductorderObj.productorderstatusname!==null || mfproductorderObj.productorderstatusname!==undefined?mfproductorderObj.productorderstatusname:'NOT KNONWN' }/></Grid> */}
     
      </Grid>
      
    )
}