import React, { useState, useEffect } from 'react'
import { useSearchParams} from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import DeleteNotification from '../../core/components/DeleteNotification';
import {CoreContext} from "../../core/context/CoreContext";
import PageWaiting from '../../core/components/PageWaiting';
import {isMobile} from 'react-device-detect';

import {getListAPI, deleteAPI} from './MfprintingtypeAPI';
import {getColumns} from './MfprintingtypeStruct';
import ListCardView from '../../core/components/card/ListCardView';
import ListTableView from '../../core/components/card/ListTableView';

import {getMfprintingtypeView} from './MfprintingtypeView';
import CardListLayout from '../../core/components/card/layout/CardListLayout';
import { useSelector, useDispatch } from 'react-redux';
import {setViewParams} from '../../store/slice/ChoiceSelectionSlice';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';

import {setFilter} from '../../store/slice/ConfSlice';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import MyPageContainer from '../../core/template/MyPageContainer';
import { deleteMfprintingtypeObj  ,setSelectedMfprintingtypeId,setSelectedActionId , setShowEditForMfprintingtype} from 'store/slice/ManufSlice';

import MfprintingtypeListTable from './components/MfprintingtypeListTable';
import { useNavigate } from "react-router-dom";
import { setClickedBo,setActionClicked } from 'store/slice/BreadCrumSlice';
import MfprintingtypeFilterCriteria from './MfprintingtypeFilterCriteria';




import { transformDateValue } from 'core/js/FormUtils';

export default function MfprintingtypeList( {tableList, cardList, setShowEdit, setMfprintingtypeId}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotification, setShowLoading, showloading, lastURL } = React.useContext(CoreContext);
  const columns = getColumns(editCallBack, initDeleteCallBack);
  const [view, setView] = useState(1);
  const { showfilter } = useSelector((state) => state.confslice)

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const {selectedId, mfprintingtypeObj} = useSelector((state)=>state.manufslice.mfprintingtypeDashboard);
  const { mfprintingtypeList } = useSelector((state) => state.manufslice.mfprintingtypeDashboard);

  useEffect(() => {
  }, [mfprintingtypeList, mfprintingtypeObj])
  
  function initDeleteCallBack(id){
    dispatch(setSelectedMfprintingtypeId(id));
    setShowDeleteAlert(true);
  }
  function deleteCallBack(obj){
    dispatch(deleteMfprintingtypeObj(obj.id));
    setNotification('Record deleted successfully !!!')
  }
  function deleteRecord(){
    deleteAPI(selectedId,deleteCallBack);
    setShowDeleteAlert(false);
  }

  function editCallBack(id){
    dispatch(setSelectedMfprintingtypeId(id));
    dispatch(setShowEditForMfprintingtype(true));
  }
  
  const createMfprintingtype=()=>{
    dispatch(setSelectedMfprintingtypeId(-1));
    dispatch(setShowEditForMfprintingtype(true));
  }



const getDataWithDateTransformation = (data) =>{
    let newData = {
      ...data,
      'expand':false,

    }
    return newData;
  }
  const mfprintingtypeTransformation = (tableList) =>{
    let newDataList=[]
    tableList.map((data)=>{ 
      newDataList.push(getDataWithDateTransformation(data));
     })
    return newDataList;
  }

  const payload = {
    mfprintingtypeList:mfprintingtypeList,
    cardList:getMfprintingtypeView(tableList),
        actionList: [
          /*{title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>, status:'NA'},
          {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>, status:'NA'},
          
        */
        ],
        dialogueActionList: [
          
        ],
    tableView:{
      tableList:mfprintingtypeTransformation(tableList),
      mfprintingtypeList:mfprintingtypeList,
      actionList:[
        {title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>, status:'NA'},
        {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>, status:'NA'},
        
      ]
    }
  }
  
  const getPayload = ()=>{
    return payload;
  }

  useEffect(()=>{
    if (document.body.offsetWidth<500)  setView(0);
    dispatch(setViewParams({
      colsCount:{xs:12, sm:4, md:4, lg:4},
      modal:{
            
      }
      }))
  },[])

  return (
   <>
   {showloading?<PageWaiting/>:
      <Grid container direction="column">
              <Grid item>
                  <Grid container direction="row" justifyContent={'space-between'} alignContent={'center'} sx={{height:showfilter?'55px':'35px'}}>
                      <Grid item  lg={3}>
                        <Typography variant='h4' color="primary.darker">Printing Type</Typography>
                      </Grid>
                      <Grid item lg={8}>
                      {showfilter ?  < MfprintingtypeFilterCriteria  />: <></>}
                      </Grid>
                      <Grid item lg={1}>
                        <Grid container justifyContent={'flex-end'}>
                        {showfilter?
                            <Grid item>
                              <Tooltip title="Hide filter" sx={{cursor: "pointer"}}>
                                <FilterAltOffIcon onClick={()=>{dispatch(setFilter(false))}}/>
                              </Tooltip>
                            </Grid>
                            :
                            <Grid item>
                              <Tooltip title="Show filter" sx={{cursor: "pointer"}}>
                                <FilterAltIcon onClick={()=>{dispatch(setFilter(true))}}/>
                              </Tooltip>
                            </Grid>
                            }
                            {/*view===1?
                            <Grid item>
                              <Tooltip title="Grid View" sx={{cursor: "pointer"}}>
                                <ViewWeekIcon onClick={()=>{setView(0)}}/>
                              </Tooltip>
                            </Grid>
                            :
                            <Grid item>
                              <Tooltip title="Table View" sx={{cursor: "pointer"}}>
                                <CalendarViewMonthIcon onClick={()=>{setView(1)}}/>
                              </Tooltip>
                            </Grid>
                            */}
                            <Grid item>
                              <Tooltip title="Add Mfprintingtype" sx={{cursor: "pointer"}}>
                                <AddIcon onClick={()=>{createMfprintingtype()}}/>
                              </Tooltip>
                            </Grid>
                          </Grid>
                      </Grid>
                      
                  </Grid>
              </Grid>
              <Grid item>
                < MfprintingtypeListTable 
                  payload={getPayload().tableView}
                  />
                  
              </Grid>     
      </Grid>
   }
    { showDeleteAlert?<DeleteNotification 
            id={selectedId}
            width="50%"
            setShowDeleteAlert={setShowDeleteAlert}
            deleteRecord = {deleteRecord}/>: ''
    }

    
    </>
);


}