import {useState, useEffect} from 'react';

import MfproductorderEditFormImpl from 'components/mfproductorder/MfproductorderEditFormImpl';
export default function AddMfProductActionImpl(){

    return (
        <>
        <MfproductorderEditFormImpl/>
        </>
    )
}