import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function MfproductorderstatusListTableHeader(){
    return (
      
      <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "center", width: '100%', paddingTop: 1, backgroundColor: 'primary.lighter' }}>

            {/* <HEADER - CELLS/> */}
        
                        <Grid item lg={2} xs={1} ><Typography variant="h5"> Business Id </Typography></Grid>
                        <Grid item lg={2} xs={1} ><Typography variant="h5"> Title </Typography></Grid>

      </Grid>
      
    )
}