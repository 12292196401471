import {useState, useEffect} from 'react';
import { Grid, Button, Typography } from '@mui/material';
import PdfComponent from './PdfComponent';
import { useSelector } from 'react-redux';

export default function PrintJobTicketActionImpl(){
    const {mforderObj} = useSelector((state)=>state.manufslice.mforderDashboard);
    const {mfproductorderList} = useSelector((state)=>state.manufslice);
    const [productOrderList, setProductOrderList] = useState({})

    useEffect(()=>{
        console.log('==>'+JSON.stringify(mfproductorderList.filter(mfproductorder=>mfproductorder.orderid===mforderObj.id)))
        setProductOrderList(
            mfproductorderList.filter(mfproductorder=>mfproductorder.orderid===mforderObj.id))
    },[])
    const orderData = {
        orderId: "ORD12345",
        orderDate: "2025-02-26",
        partyName: "ABC Industries",
        products: [
          { productId: 101, productName: "Product A", quantity: 10, matter: "Material X", remarks: "Urgent" },
          { productId: 102, productName: "Product B", quantity: 5, matter: "Material Y", remarks: "Handle with care" },
        ],
      };
    return (
        <Grid container>
            <Grid item lg={12} xs={12}>
                {productOrderList.length>0 && <PdfComponent order={mforderObj} productOrderList={productOrderList}/>}
            </Grid>
        </Grid>
    )
}