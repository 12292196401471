
import AccountTreeIcon from '@mui/icons-material/AccountTree';


const FactoryAmin = {
  id: '20',
  title: 'Factory Amin',
  type: 'group',
  role: 'FACTORY_ADMIN',
  children: [
    {
      id: 'businessprocess',
      title: 'Process',
      type: 'item',
      url: '/businessprocess',
      icon: AccountTreeIcon
    },
  ]
};

export default FactoryAmin;