import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector, useDispatch } from 'react-redux';

import UpdateRemarksActionModalContainer from './UpdateRemarksActionModalContainer';
import {setMforderUpdateRemarksModal} from '../../../../store/slice/ManufSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  width: { lg: '85%', xs: '90%' },
  height: { lg: '85%', xs: '85%' },
  borderRadius: 3,
  //overflow:'scroll',
  p: 1,
  backgroundColor:'secondary.lighter',
};

export default function UpdateRemarksActionModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);;
  const {UpdateRemarksModalOpen, mforderObj} = useSelector((state)=>state.manufslice.mforderDashboard);

  useEffect(()=>{
  },[UpdateRemarksModalOpen])

  const handleModalClose=()=>{
    handleClose();
    dispatch(setMforderUpdateRemarksModal(false));
  }
  return (
    <Modal
       open={UpdateRemarksModalOpen===undefined?false:UpdateRemarksModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} >

        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5"  >
              Remarks Management
            </Typography>
          </Grid>
          <Grid item>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => handleModalClose()} />
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
          <Box height="20px"></Box>
        </Grid>
        <Grid item>
            < UpdateRemarksActionModalContainer/>
        </Grid>

      </Box>
    </Modal>
  );
}