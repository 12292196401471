import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function TaskInfo(){
  const { taskObj} = useSelector((state)=>state.manufslice.taskDashboard);
    return (
      <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: { xs: window.screen.height - 250, lg: window.screen.height } }}>
        
        <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{backgroundColor:'white'}}
      >
            
        <Grid item lg={4} xs={6}><FieldView label="Business Id"  value={ taskObj.businessid!==null && taskObj.businessid!==undefined?taskObj.businessid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Title"  value={ taskObj.title!==null && taskObj.title!==undefined?taskObj.title:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Business Process Id"  value={ taskObj.businessprocessid!==null && taskObj.businessprocessid!==undefined?taskObj.businessprocessid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Pre Task Id"  value={ taskObj.pretaskid!==null && taskObj.pretaskid!==undefined?taskObj.pretaskid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="PerformingRole Id"  value={ taskObj.performingroleid!==null && taskObj.performingroleid!==undefined?taskObj.performingroleid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="PerformingRole Name"  value={ taskObj.performingrolename!==null && taskObj.performingrolename!==undefined?taskObj.performingrolename:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Task Type Id"  value={ taskObj.tasktypeid!==null && taskObj.tasktypeid!==undefined?taskObj.tasktypeid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Task Type Name"  value={ taskObj.tasktypename!==null && taskObj.tasktypename!==undefined?taskObj.tasktypename:'NOT KNONWN' }/></Grid>
      </Grid>
      </Grid>
      </Box>
    )
}