import { transformDateValue } from 'core/js/FormUtils';
export const getFactoryroleView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let factoryroleList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
              
              
            ],
            'dataList': [
                
              ]
          }

          factoryroleList.push(card);
  })
  return factoryroleList;
}