import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MfproductorderstatusEditForm from './MfproductorderstatusEditForm';
import MfproductorderstatusList from './MfproductorderstatusList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MfproductorderstatusListView from './MfproductorderstatusListView'


export default function MfproductorderstatusDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.mfproductorderstatusDashboard);
  const [ mfproductorderstatusId, setMfproductorderstatusId] = React.useState('');
  const [reloadMfproductorderstatusList, setReloadMfproductorderstatusList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MfproductorderstatusEditForm 
            setReloadMfproductorderstatusList={setReloadMfproductorderstatusList}
            mfproductorderstatusId={mfproductorderstatusId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MfproductorderstatusListView 
                      setMfproductorderstatusId = {setMfproductorderstatusId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
