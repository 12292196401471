import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import MfpartyDashboard from '../MfpartyDashboard';

import { openDrawer } from './../../../store/slice/MenuSlice';

export default function MfpartyPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(openDrawer(false));
    },[]);

    return (
        < MfpartyDashboard />
    )

}