import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioForBoolean({label, fieldName, defaultValue, handleChange}) {
  const [value, setValue] = React.useState(defaultValue);
  
  const onChange = (e) => {
    setValue(e.target.value);
    handleChange(e.target.name, e.target.value);
  };
  
  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name={fieldName}
        onChange={onChange}
        value={value}
        >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No"/>
      </RadioGroup>
    </FormControl>
  );
}