import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import { transformDateValue } from 'core/js/FormUtils';


export default function MforderInfo({mforderObj}){
  const {mobileView} = useSelector((state)=>state.menu);
  useEffect(()=>{
    
  },[])
    return (
      <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: { xs: window.screen.height - 250, lg: window.screen.height } }}>
        
        <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{backgroundColor:'secondary.100', borderBottom:'1px solid #bae7ff' }}

      >
        {mobileView?<>
          <Grid item lg={12} xs={4}><FieldView label="Order Date" color="secondary.100" fontSize={15} value={transformDateValue(mforderObj.orderdate) }/></Grid>
          <Grid item lg={12} xs={4}><FieldView label="Executive" color="secondary.100" fontSize={15} value={mforderObj.executive }/></Grid>
          <Grid item lg={12} xs={4}><FieldView label="Status" color="secondary.100" fontSize={15} value={(mforderObj.orderstatusname) }/></Grid>
          
        </>:<></>}
        <Grid item lg={12} xs={12}><FieldView label="Remarks" color="secondary.100" fontSize={15} value={ mforderObj.remarks!==null && mforderObj.remarks!==undefined?mforderObj.remarks:'NOT KNONWN' }/></Grid>
        </Grid>
      </Grid>
      </Box>
    )
}