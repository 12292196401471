// project import
import dashboard from './dashboard';
import utilities from './utilities';
import manuf from './manuf';
import FactoryConfiguration from './factoryconfiguration';
import FactoryOperations from './factoryoperations';
import FactoryAmin from './factoryamin';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [FactoryOperations,FactoryConfiguration,FactoryAmin,dashboard, manuf, utilities]
};

export default menuItems;