import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function MfdeliverypriorityInfo(){
  const { mfdeliverypriorityObj} = useSelector((state)=>state.manufslice.mfdeliverypriorityDashboard);
    return (
      <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: { xs: window.screen.height - 250, lg: window.screen.height } }}>
        
        <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{backgroundColor:'white'}}
      >
            
        <Grid item lg={4} xs={6}><FieldView label="title"  value={ mfdeliverypriorityObj.title!==null && mfdeliverypriorityObj.title!==undefined?mfdeliverypriorityObj.title:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Business Id"  value={ mfdeliverypriorityObj.businessid!==null && mfdeliverypriorityObj.businessid!==undefined?mfdeliverypriorityObj.businessid:'NOT KNONWN' }/></Grid>
      </Grid>
      </Grid>
      </Box>
    )
}