import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MfproductFilterCriteria from './MfproductFilterCriteria'
import MfproductList from './MfproductList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MfproductAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMfproductDashboardMfproductList } from '../../store/slice/ManufSlice';

export default function MfproductListView({setShowEdit,setMfproductId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mfproductDashboard);   

    const [filteredMfproductList, setFilteredMfproductList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mfproductDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mfproductDashboard.mfproductList.length>-1){
        setFilteredMfproductList(mfproductDashboard.mfproductList);
        transformToCardFormat(mfproductDashboard.mfproductList, setCardList);
      }
    },[mfproductDashboard.mfproductList])

    useEffect(()=>{
          transformToCardFormat(filteredMfproductList, setCardList);
      },[filteredMfproductList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredMfproductList(mfproductDashboard.mfproductList);
            transformToCardFormat(mfproductDashboard.mfproductList, setCardList);

        } else {
            setFilteredMfproductList(
                mfproductDashboard.mfproductList
                    .filter(mfproduct=> mfproduct.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mfproductDashboard.filteredMfproductList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MfproductList
                    setShowEdit={setShowEdit}
                    setMfproductId = {setMfproductId}
                    tableList={filteredMfproductList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}