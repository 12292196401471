import { transformDateValue } from 'core/js/FormUtils';
export const getTaskinstanceView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let taskinstanceList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
                            {"fieldLabel":"Order Id","fieldValue":transformDataValue(data.orderid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Task Id","fieldValue":transformDataValue(data.taskid,"bodynamiclist"), "fieldType":"bodynamiclist"},
                            {"fieldLabel":"Task Name","fieldValue":transformDataValue(data.taskname,"string"), "fieldType":"string"},
                            {"fieldLabel":"Task Status Id","fieldValue":transformDataValue(data.taskstatusid,"bodynamiclist"), "fieldType":"bodynamiclist"},
                            {"fieldLabel":"Task Status Name","fieldValue":transformDataValue(data.taskstatusname,"string"), "fieldType":"string"},
                            {"fieldLabel":"Business Process Id","fieldValue":transformDataValue(data.businessprocessid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Product Id","fieldValue":transformDataValue(data.productid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Product Order Id","fieldValue":transformDataValue(data.productorderid,"ID"), "fieldType":"ID"},
              
              
            ],
            'dataList': [
                
              ]
          }

          taskinstanceList.push(card);
  })
  return taskinstanceList;
}