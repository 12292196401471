import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MforderEditFormImpl from './MforderEditFormImpl';
import MforderList from './MforderList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MforderListView from './MforderListView'


export default function MforderDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.mforderDashboard);
  const [ mforderId, setMforderId] = React.useState('');
  const [reloadMforderList, setReloadMforderList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MforderEditFormImpl 
            setReloadMforderList={setReloadMforderList}
            mforderId={mforderId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MforderListView 
                      setMforderId = {setMforderId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
