import { v4 as uuidv4 } from 'uuid';
var manufOmsWebServicesPrefix = "https://appmachine.in/manuf-oms-service";

export function submitOrderAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/submitorder", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function approveOrderAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/approveorder", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function rejectOrderAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/rejectorder", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function orderAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/order", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function updateOrderPartyAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/updateorderparty", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function updateOrderRemarksAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/orderremarks", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function updateTaskStatusAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/taskinstance", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function initWorkflowAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(manufOmsWebServicesPrefix+"/workflow", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}