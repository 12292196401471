import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import MfproductorderstatusDashboard from '../MfproductorderstatusDashboard';

import { openDrawer } from './../../../store/slice/MenuSlice';

export default function MfproductorderstatusPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(openDrawer(false));
    },[]);

    return (
        < MfproductorderstatusDashboard />
    )

}