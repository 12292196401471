import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import { Button, Divider, TextField, Grid, Typography, Box } from "@mui/material"

import { getMfproductorderStruct, setData } from '../MfproductorderStruct'
import { updateTextValue, handleSubmit, updateValue, updateSelectedValue } from '../../../core/js/FormUtils'
import { createAPI, updateAPI, getAPI, updateEditableAPI } from '../MfproductorderAPI';
import { CoreContext } from "../../../core/context/CoreContext";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { validMobileNumberEntry, validNameEntry, validEmailEntry } from '../../../core/js/FormValidation';

import { useSearchParams } from "react-router-dom";
import MyDate from '../../../core/components/MyDate';
import { useDispatch, useSelector } from 'react-redux';
import { updateMfproductorderObj, addMfproductorderObj, setShowEditForMfproductorder, setMforderAddMfProductModal } from 'store/slice/ManufSlice';




export default function MfproductorderStatusEditForm() {
    const dispatch = useDispatch();
    const { setNotification, lastURL } = React.useContext(CoreContext);
    const [formValues, setFormValues] = useState(getMfproductorderStruct);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('Something went wrong');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const { mfproductorderDashboard } = useSelector((state) => state.manufslice);
    const { mforderObj } = useSelector((state) => state.manufslice.mforderDashboard);
    const [mfproductorderId, setMfproductorderId] = useState(mfproductorderDashboard.selectedId);
    const { businessid } = useSelector((state) => state.headerslice.userprofileInfo)

    const { mfproductList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueForproductid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'productname', mfproductList.find(obj => obj.id === e.target.value).title);
    }
    const { mfdeliverypriorityList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueFordeliverypriorityid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'deliverypriorityname', mfdeliverypriorityList.find(obj => obj.id === e.target.value).title);
    }
    const { mfproductorderstatusList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueForproductorderstatusid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'productorderstatusname', mfproductorderstatusList.find(obj => obj.id === e.target.value).title);
    }

    const { mfstitchingstatusList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueForstitchingstatusid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'stitchingstatusname', mfstitchingstatusList.find(obj => obj.id === e.target.value).title);
    }
    const { mfdigitalstatusList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueFordigitalstatusid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'digitalstatusname', mfdigitalstatusList.find(obj => obj.id === e.target.value).title);
    }


    useEffect(() => {
        if (mfproductorderId !== -1) {
            if (dataLoaded) return;
            getAPI(mfproductorderId, loadForm);
        }
        else {
            // formValues.<<CONTEXT>>.value=searchParams.get('<<CONTEXT>>');
            setDataLoaded(true);
        }

        setFormValues({
            ...formValues,
            ['businessid']: {
                ...formValues['businessid'],
                value: businessid
            },
            ['productorderstatusname']: {
                ...formValues['productorderstatusname'],
                value: 'DRAFT'
            },
            ['partyname']: {
                ...formValues['partyname'],
                value: mforderObj.partyname
            },
            ['orderid']: {
                ...formValues['orderid'],
                value: mforderObj.id
            }
        })
    }, []);

    const loadForm = (data) => {
        setData(data, formValues, setFormValues);
        setDataLoaded(true);
    }
    const handleRadioChange = (name, value) => {
        setShowError(false);
        console.log(value + " == " + getRadioAPIvalue(value))
        updateValue(formValues, setFormValues, name, getRadioAPIvalue(value));
    }
    const getRadioValue = (value) => {
        if (value)
            return "yes"
        else
            return "no"
    }
    const getRadioAPIvalue = (value) => {
        if (value === "yes")
            return true
        else
            return false
    }
    const handleTextChange = (e) => {
        setShowError(false);
        updateTextValue(formValues, setFormValues, e);
    }

    const handleNameChange = (e) => {
        setShowError(false);

        if (validNameEntry(e))
            updateTextValue(formValues, setFormValues, e);
    }
    const handleMobileChange = (e) => {
        setShowError(false);

        if (validMobileNumberEntry(e))
            updateTextValue(formValues, setFormValues, e);
    }
    const handleEmailidChange = (e) => {
        setShowError(false);

        if (validEmailEntry(e))
            updateTextValue(formValues, setFormValues, e);
    }

    const createSuccessCallback = (obj) => {
        dispatch(addMfproductorderObj(obj));
        setNotification('Record has been cretaed successfully !!!');
        dispatch(setShowEditForMfproductorder(false));

    }
    const updateSuccessCallback = (obj) => {
        dispatch(updateMfproductorderObj(obj));
        setNotification('Record with id[' + mfproductorderId + '] has been updated successfully !!!');
        dispatch(setShowEditForMfproductorder(false));

    }
    const failuerCallback = (errorMsg) => {
        setErrorMsg(errorMsg);
        setShowError(true);
    }
    const onSubmit = (e) => {
        handleSubmit(e, mfproductorderId, formValues, setFormValues, createSuccessCallback, updateSuccessCallback, failuerCallback, createAPI, updateAPI);
    }
    const setDate = (fieldName, value) => {
        var date = new Date(value);

        const day = date.getDate();
        const dayStr = day < 10 ? '0' + day : day.toString();

        const month = date.getMonth() + 1;
        const monthStr = month < 10 ? '0' + month : month.toString();
        var dateStr = date.getFullYear() + "-" + monthStr + "-" + dayStr + "T00:00:00.000Z";
        updateValue(formValues, setFormValues, fieldName, dateStr);
    }
    return (
        <form noValidate onSubmit={onSubmit} >
            <Grid container direction="column" padding={.25}>
                {showError ? <Alert severity="error">{errorMsg}</Alert> : ''}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: 440 }}>
                        <Grid container direction="column" padding={.25}>
                            <Grid item>
                                <Typography variant='h4'>MF Product Order {mfproductorderId === -1 ? 'Add' : 'Edit'}</Typography>
                                <Divider />
                            </Grid>
                            <Grid height='25px' ></Grid>
                            {dataLoaded ?
                                <Grid container display="flex" spacing={2} direction="row" justifyContent="space-between" alignItems="center">

                                    <Grid item lg={6} xs={12}>
                                        <FormControl sx={{ width: { lg: '100%', xs: '200px' } }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">Stitching Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.stitchingstatusid.label}
                                                name={formValues.stitchingstatusid.name}
                                                value={formValues.stitchingstatusid.value}
                                                onChange={handleDynamicSelectedValueForstitchingstatusid}

                                                error={formValues.stitchingstatusid.error}
                                                helpertext={formValues.stitchingstatusid.error && formValues.stitchingstatusid.errorMessage}
                                            >
                                                <MenuItem key={0} value="0">Select Item</MenuItem>
                                                {
                                                    mfstitchingstatusList && mfstitchingstatusList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>


                                    <Grid item lg={6} xs={12}>
                                        <FormControl sx={{ width: { lg: '100%', xs: '200px' } }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">Digital Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.digitalstatusid.label}
                                                name={formValues.digitalstatusid.name}
                                                value={formValues.digitalstatusid.value}
                                                onChange={handleDynamicSelectedValueFordigitalstatusid}

                                                error={formValues.digitalstatusid.error}
                                                helpertext={formValues.digitalstatusid.error && formValues.digitalstatusid.errorMessage}
                                            >
                                                <MenuItem key={0} value="0">Select Item</MenuItem>
                                                {
                                                    mfdigitalstatusList && mfdigitalstatusList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>


                                    <Grid item lg={4} xs={12}>
                                        <FormControl sx={{ width: { lg: '100%', xs: '200px' } }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">Product Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.productorderstatusid.label}
                                                name={formValues.productorderstatusid.name}
                                                value={formValues.productorderstatusid.value}
                                                onChange={handleDynamicSelectedValueForproductorderstatusid}

                                                error={formValues.productorderstatusid.error}
                                                helpertext={formValues.productorderstatusid.error && formValues.productorderstatusid.errorMessage}
                                            >
                                                <MenuItem key={0} value="0">Select Item</MenuItem>
                                                {
                                                    mfproductorderstatusList && mfproductorderstatusList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.productorderstatusname.placeholder}
                                            required={formValues.productorderstatusname.required}
                                            label={formValues.productorderstatusname.label}
                                            name={formValues.productorderstatusname.name}
                                            value={formValues.productorderstatusname.value}
                                            onChange={handleTextChange}
                                            error={formValues.productorderstatusname.error}
                                            helpertext={formValues.productorderstatusname.error && formValues.productorderstatusname.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>

                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.stitchingstatusname.placeholder}
                                            required={formValues.stitchingstatusname.required}
                                            label={formValues.stitchingstatusname.label}
                                            name={formValues.stitchingstatusname.name}
                                            value={formValues.stitchingstatusname.value}
                                            onChange={handleTextChange}
                                            error={formValues.stitchingstatusname.error}
                                            helpertext={formValues.stitchingstatusname.error && formValues.stitchingstatusname.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>

                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.digitalstatusname.placeholder}
                                            required={formValues.digitalstatusname.required}
                                            label={formValues.digitalstatusname.label}
                                            name={formValues.digitalstatusname.name}
                                            value={formValues.digitalstatusname.value}
                                            onChange={handleTextChange}
                                            error={formValues.digitalstatusname.error}
                                            helpertext={formValues.digitalstatusname.error && formValues.digitalstatusname.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>
                                    <Grid item lg={3} xs={12}>
                                        <FormControl sx={{ width: { lg: '100%', xs: '200px', display: 'none' } }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">Product Name</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.productid.label}
                                                name={formValues.productid.name}
                                                value={formValues.productid.value}
                                                onChange={handleDynamicSelectedValueForproductid}

                                                error={formValues.productid.error}
                                                helpertext={formValues.productid.error && formValues.productid.errorMessage}
                                            >
                                                <MenuItem key={0} value="0">Select Item</MenuItem>
                                                {
                                                    mfproductList && mfproductList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={1} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.qty.placeholder}
                                            required={formValues.qty.required}
                                            label={formValues.qty.label}
                                            name={formValues.qty.name}
                                            value={formValues.qty.value}
                                            onChange={handleTextChange}
                                            error={formValues.qty.error}
                                            helpertext={formValues.qty.error && formValues.qty.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.matter.placeholder}
                                            required={formValues.matter.required}
                                            label={formValues.matter.label}
                                            name={formValues.matter.name}
                                            value={formValues.matter.value}
                                            onChange={handleTextChange}
                                            error={formValues.matter.error}
                                            helpertext={formValues.matter.error && formValues.matter.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>



                                    <Grid item lg={2} xs={12}>
                                        <FormControl sx={{ width: { lg: '100%', xs: '200px', display: 'none' } }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">Delivery Priority</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.deliverypriorityid.label}
                                                name={formValues.deliverypriorityid.name}
                                                value={formValues.deliverypriorityid.value}
                                                onChange={handleDynamicSelectedValueFordeliverypriorityid}

                                                error={formValues.deliverypriorityid.error}
                                                helpertext={formValues.deliverypriorityid.error && formValues.deliverypriorityid.errorMessage}
                                            >
                                                <MenuItem key={0} value="0">Select Item</MenuItem>
                                                {
                                                    mfdeliverypriorityList && mfdeliverypriorityList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={2} xs={12} sx={{ display: 'none' }}>
                                        <MyDate
                                            label={formValues.expecteddelivery.label}
                                            fieldName={formValues.expecteddelivery.name}
                                            defaultValue={formValues.expecteddelivery.value}
                                            errStr={formValues.expecteddelivery.errorMessage}
                                            setDate={setDate}
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.deliverypriorityname.placeholder}
                                            required={formValues.deliverypriorityname.required}
                                            label={formValues.deliverypriorityname.label}
                                            name={formValues.deliverypriorityname.name}
                                            value={formValues.deliverypriorityname.value}
                                            onChange={handleTextChange}
                                            error={formValues.deliverypriorityname.error}
                                            helpertext={formValues.deliverypriorityname.error && formValues.deliverypriorityname.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>


                                    <Grid item lg={4} xs={12}>
                                        <FormControl sx={{ width: { lg: '100%', xs: '200px', display: 'none' } }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">{formValues.productorderstatusid.label}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.productorderstatusid.label}
                                                name={formValues.productorderstatusid.name}
                                                value={formValues.productorderstatusid.value}
                                                onChange={handleDynamicSelectedValueForproductorderstatusid}

                                                error={formValues.productorderstatusid.error}
                                                helpertext={formValues.productorderstatusid.error && formValues.productorderstatusid.errorMessage}
                                            >
                                                <MenuItem key={0} value="0">Select Item</MenuItem>
                                                {
                                                    mfproductorderstatusList && mfproductorderstatusList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.productorderstatusname.placeholder}
                                            required={formValues.productorderstatusname.required}
                                            label={formValues.productorderstatusname.label}
                                            name={formValues.productorderstatusname.name}
                                            value={formValues.productorderstatusname.value}
                                            onChange={handleTextChange}
                                            error={formValues.productorderstatusname.error}
                                            helpertext={formValues.productorderstatusname.error && formValues.productorderstatusname.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>

                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.partyname.placeholder}
                                            required={formValues.partyname.required}
                                            label={formValues.partyname.label}
                                            name={formValues.partyname.name}
                                            value={formValues.partyname.value}
                                            onChange={handleTextChange}
                                            error={formValues.partyname.error}
                                            helpertext={formValues.partyname.error && formValues.partyname.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>

                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.businessid.placeholder}
                                            required={formValues.businessid.required}
                                            label={formValues.businessid.label}
                                            name={formValues.businessid.name}
                                            value={formValues.businessid.value}
                                            onChange={handleTextChange}
                                            error={formValues.businessid.error}
                                            helpertext={formValues.businessid.error && formValues.businessid.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.productname.placeholder}
                                            required={formValues.productname.required}
                                            label={formValues.productname.label}
                                            name={formValues.productname.name}
                                            value={formValues.productname.value}
                                            onChange={handleTextChange}
                                            error={formValues.productname.error}
                                            helpertext={formValues.productname.error && formValues.productname.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.orderid.placeholder}
                                            required={formValues.orderid.required}
                                            label={formValues.orderid.label}
                                            name={formValues.orderid.name}
                                            value={formValues.orderid.value}
                                            onChange={handleTextChange}
                                            error={formValues.orderid.error}
                                            helpertext={formValues.orderid.error && formValues.orderid.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>
                                </Grid>
                                : <></>}
                        </Grid>
                    </Grid>
                </Box>
                <Grid height='25px' ></Grid>
                <Grid padding={1}>
                    <Divider />
                </Grid>
                <Grid container direction="row" justifyContent="flex-end"  >
                    <Button variant="outlined" type="submit" >{mfproductorderId === -1 ? 'Add' : 'Save'}</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="outlined" onClick={() => { dispatch(setShowEditForMfproductorder(false)); }}>Close</Button>
                </Grid>
            </Grid>
        </form>
    );
};