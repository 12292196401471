import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography,Divider} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import MforderInfo from './MforderInfo';
import MforderProductListByOrderId from './MforderProductListByOrderId';
import { setSelectedMforderId } from 'store/slice/ManufSlice';
import { transformDateValue } from 'core/js/FormUtils';
import IconEnabled from 'core/components/layout/IconEnabled';


export default function MforderListTableRow({obj, getActionComponent,handleRowClick, actionList}){
  const {mobileView} = useSelector((state)=>state.menu);
  useEffect(()=>{
  },[])
  const getHoverColor = () =>{
    if (mobileView) return ''
    return 'primary.main';
  }
  const getHoverBackgroundColor = () =>{
    if (mobileView) return ''
    return 'secondary.200';
  }
  const getBackgroundColor = (obj) =>{
    if (mobileView) {
      if (obj.expand) return 'secondary.500'
      return 'white'
    }
    return 'white'
  }

  
    return (
      
      <>
        <Grid item lg={12} sx={{ width: '100%',}}>
          {/* <ROW1 - CONTAINER/> */}
          <Grid container direction="row" onClick={() => {handleRowClick(obj.id);}}
            sx={{cursor: 'pointer',
              justifyContent: "flex-start", alignItems: "center",  padding:1,  
              backgroundColor: getBackgroundColor(obj),
              color: mobileView && obj.expand?'primary.main':'primary.darker',
              '&:hover': {
                backgroundColor: getHoverBackgroundColor, 
                color: getHoverColor()
              },
            }}>
            {/* <ROW1 - CELLS/> */}
              <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.id}</Typography></Grid>
              <Grid item lg={3} xs={7} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.partyname}</Typography></Grid>

              {!mobileView?<>
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{transformDateValue(obj.orderdate)}</Typography></Grid>
              <Grid item lg={3} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.executive}</Typography></Grid>
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.orderstatusname}</Typography></Grid>
              </>:<></>}
              <Grid item lg={1} xs={3}>
                <Grid container direction="row" sx={{ justifyContent: "flex-end", width: '100%' }}>
                  {actionList.filter(action=>action.status.includes(obj.orderstatusname)).map((actionObj, index) => (
                    <IconEnabled key={`${obj.id}-${actionObj.status}-${index}`} actionObj={actionObj} id={obj.id} />
                  ))}
                </Grid>
              </Grid>

            
          </Grid>
        </Grid>
        <Grid item lg={12} sx={{ width: '100%' }}><Divider /></Grid>
        {obj.expand ?
        <Grid container direction="row" >
          <Grid item sx={{ width: '100%',}}>
            < MforderInfo  mforderObj={obj} />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <MforderProductListByOrderId orderId={obj.id}/>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
          
        </Grid>
        : <Grid container direction="row">
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>}
      </>
      
    )
}