import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MfprintingstatusFilterCriteria from './MfprintingstatusFilterCriteria'
import MfprintingstatusList from './MfprintingstatusList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MfprintingstatusAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMfprintingstatusDashboardMfprintingstatusList } from '../../store/slice/ManufSlice';

export default function MfprintingstatusListView({setShowEdit,setMfprintingstatusId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mfprintingstatusDashboard);   

    const [filteredMfprintingstatusList, setFilteredMfprintingstatusList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mfprintingstatusDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mfprintingstatusDashboard.mfprintingstatusList.length>-1){
        setFilteredMfprintingstatusList(mfprintingstatusDashboard.mfprintingstatusList);
        transformToCardFormat(mfprintingstatusDashboard.mfprintingstatusList, setCardList);
      }
    },[mfprintingstatusDashboard.mfprintingstatusList])

    useEffect(()=>{
          transformToCardFormat(filteredMfprintingstatusList, setCardList);
      },[filteredMfprintingstatusList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredMfprintingstatusList(mfprintingstatusDashboard.mfprintingstatusList);
            transformToCardFormat(mfprintingstatusDashboard.mfprintingstatusList, setCardList);

        } else {
            setFilteredMfprintingstatusList(
                mfprintingstatusDashboard.mfprintingstatusList
                    .filter(mfprintingstatus=> mfprintingstatus.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mfprintingstatusDashboard.filteredMfprintingstatusList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MfprintingstatusList
                    setShowEdit={setShowEdit}
                    setMfprintingstatusId = {setMfprintingstatusId}
                    tableList={filteredMfprintingstatusList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}