import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import { Button, Divider, TextField, Grid, Typography, Box, CircularProgress } from "@mui/material";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Groups2Icon from '@mui/icons-material/Groups2';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { setSelectedBottomMenu } from 'store/slice/EcomSlice';


// ==============================|| DASHBOARD - DEFAULT ||============================== //

const styles = {
  strip: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: 'white',
    //color: "#fff",
    textAlign: "center",
    //padding: "10px 0",
    boxShadow: "0 -1px 1px rgba(0, 0, 0, 0.1)",
    zIndex: 1, // Ensure it stays above other content
  },
  text: {
    margin: 0,
    fontSize: "16px",
  },
};

const BottomMenu = () => {
  const dispatch = useDispatch();
  const {userprofileInfo, initialAPIload} = useSelector((state)=>state.headerslice);
  const {selectedMenu} = useSelector((state)=>state.ecomslice.bottomMenu);
  
  return (
    <div style={styles.strip}>
        <Grid container direction="row" sx={{ padding: 1, height:'70px', justifyContent: 'space-between', backgroundColor:'white' }}>
          
          <Grid lg={1} xs={3} item sx={{}} >
            <Grid container direction="column" sx={{borderRadius:2,
              borderBottom:selectedMenu==='HOME'?'1px solid #003a8c':'',
              borderTop:selectedMenu==='HOME'?'1px solid #003a8c':'',
              backgroundColor:selectedMenu==='HOME'?'secondary.lighter':'',
              cursor:selectedMenu==='HOME'?'':'pointer'
              }}
               
              onClick={() => dispatch(setSelectedBottomMenu('HOME'))} >
              <Grid item xs={3} ><BusinessCenterIcon fontSize="medium"/></Grid>
              <Grid item xs={3} >Dashboard</Grid>
            </Grid>
          </Grid>

          <Grid lg={1} xs={3} item sx={{}} >
            <Grid container direction="column" sx={{borderRadius:2,
              borderBottom:(selectedMenu==='ORDERS' || selectedMenu==='ARCHIVED-ORDERS')?'1px solid #003a8c':'',
              borderTop:(selectedMenu==='ORDERS' || selectedMenu==='ARCHIVED-ORDERS')?'1px solid #003a8c':'',
              backgroundColor:(selectedMenu==='ORDERS' || selectedMenu==='ARCHIVED-ORDERS')?'secondary.lighter':'',
              cursor:selectedMenu==='ORDERS'?'':'pointer'
              }}
               
              onClick={() => dispatch(setSelectedBottomMenu('ORDERS'))} >
              <Grid item xs={3} ><BusinessCenterIcon fontSize="medium"/></Grid>
              <Grid item xs={3} >Orders</Grid>
            </Grid>
          </Grid>
          <Grid lg={1} xs={3} item sx={{}} >
            <Grid container direction="column" sx={{borderRadius:2,borderBottom:selectedMenu==='PARTY'?'1px solid #003a8c':'',borderTop:selectedMenu==='PARTNER'?'1px solid #003a8c':'',backgroundColor:selectedMenu==='PARTY'?'secondary.lighter':'', cursor:selectedMenu==='PARTY'?'':'pointer'}} onClick={() => dispatch(setSelectedBottomMenu('PARTY'))} >
              <Grid item xs={3} ><Groups2Icon fontSize="medium"/></Grid>
              <Grid item xs={3} >Party</Grid>
            </Grid>
          </Grid>
          <Grid lg={1} xs={3} item sx={{}} >
            <Grid container direction="column" sx={{borderRadius:2,borderBottom:selectedMenu==='PRODUCTS'?'1px solid #003a8c':'',borderTop:selectedMenu==='PRODUCTS'?'1px solid #003a8c':'',backgroundColor:selectedMenu==='PRODUCTS'?'secondary.lighter':'', cursor:selectedMenu==='PRODUCTS'?'':'pointer'}} onClick={() => dispatch(setSelectedBottomMenu('PRODUCTS'))} >
              <Grid item xs={3} ><Groups2Icon fontSize="medium"/></Grid>
              <Grid item xs={3} >Products</Grid>
            </Grid>
          </Grid>
        </Grid>
          
          
      </div>
  );
};

export default BottomMenu;