import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography, TextField, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { updateOrderRemarksAPI } from 'api/OmsFactoryopsWebServices';
import { updateMforderObj } from 'store/slice/ManufSlice';
import { setMforderUpdateRemarksModal } from 'store/slice/ManufSlice';

export default function RemarksMgmt() {
    const dispatch = useDispatch();

    const { mforderObj } = useSelector((state) => state.manufslice.mforderDashboard);

    const [editMode, setEditMode] = useState(true);
    const [remarks, setRemarks] = useState(mforderObj.remarks);
    
    const [apiInProgress, setApiInProgress] = useState(false);
    useEffect(() => {
    }, [mforderObj])

    const handleTextChange = (e) => {
        setRemarks(e.target.value);
    }
    const updateRemarksFailure = (errMsg) => {
        alert("!!! ERROR =>" + errMsg);
        setApiInProgress(false);
    }
    const updateRemarksSuccess = (apiResponse) => {
        dispatch(updateMforderObj(apiResponse.mforderInfo));
        setEditMode(false);
        setApiInProgress(false);
        dispatch(setMforderUpdateRemarksModal(false));
    }
    const updateRemarks = () => {
        setApiInProgress(true);
        updateOrderRemarksAPI({
            "orderId": mforderObj.id,
            "remarks": remarks
        }, updateRemarksSuccess, updateRemarksFailure)
    }
    return (
        <>
                <Grid container direction="row" sx={{ padding: .5 }} justifyContent="space-between" alignItems="center">
                    <Grid item lg={10}>
                        <Typography variant="h5" color="seconary.darker">Remarks</Typography>
                    </Grid>
                    {/* <Grid item lg={2} onClick={() => updateRemarks()} sx={{ paddingRight: 2, display: "flex", alignItems: "center", cursor: 'pointer', justifyContent: "center", backgroundColor: "primary.lighter" }}>
                        <EditIcon sx={{ marginRight: 0.5 }} />
                        <Typography variant="h6" color="secondary.darker">
                            Save
                        </Typography>
                    </Grid> */}
                </Grid>

                <Grid item lg={12} xs={12} sx={{ backgroundColor: 'white', padding: .25 }}>
                    <TextField size="Normal"
                        multiline
                        rows={2}
                        placeholder="Remarks"
                        required={false}
                        name="remarks"
                        value={remarks}
                        onChange={handleTextChange}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid container justifyContent="flex-end" lg={12} xs={12} sx={{ padding: 2 }}>
                {apiInProgress ?<CircularProgress />:<Button variant="contained" onClick={() => updateRemarks()}>Save</Button>}
                </Grid>
        </>
    )
}