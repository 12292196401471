import {formFieldToReqJSON} from '../../core/js/FormUtils';
import { v4 as uuidv4 } from 'uuid';

 var TaskinstanceResourcePrefix = "https://appmachine.in/manuf-oms-api/taskinstance";
 var TaskinstancesResourcePrefix = "https://appmachine.in/manuf-oms-api/taskinstances";


export const createAPI = (formValues, successCallBack, failuerCallback) => {
    const token = localStorage.getItem("JWT");
    var reqObj = formFieldToReqJSON(formValues);
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
        body: JSON.stringify(reqObj)
    };

    fetch(TaskinstanceResourcePrefix, requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}


export function updateAPI(id,formValues, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    var reqObj = formFieldToReqJSON(formValues);
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json',
      'X-Request-ID': requestId,
      'Authorization': `Bearer ${token}` },
      body: JSON.stringify(reqObj)
    };
      
        fetch(TaskinstanceResourcePrefix, requestOptions)
            .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}
export function getAPI(id, loadForm){
    const token = localStorage.getItem("JWT");
    let response;
    const requestId = uuidv4().split("-")[4];
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'X-Request-ID': requestId,
                'Authorization': `Bearer ${token}`}
            };
        fetch(TaskinstanceResourcePrefix+'/'+id,requestOptions)
            .then((data) => data.json())
            .then((data) => {
                loadForm(data);
                //setData({data},{formValues}, setFormValues);
            })
    } catch (error) {
        // setAlert(true);
        console.log('There was an error', error);
    }
    
}

export function getListAPI(setTaskinstanceList, dataFetchCallBack){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'GET',
      headers: {
        'X-Request-ID': requestId,
        'Authorization': `Bearer ${token}`
    }
    };
    fetch(TaskinstancesResourcePrefix, requestOptions)
        .then((data) => data.json())
        .then((data) => {setTaskinstanceList(data.list);dataFetchCallBack(data.list)})
}

export function deleteAPI(id, deleteCallBack){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'DELETE',
      headers: {'X-Request-ID': requestId,'Authorization': `Bearer ${token}`}
    };
    fetch(TaskinstanceResourcePrefix+"/"+id, requestOptions)
        .then((data) => data.json())
        .then((data) => {
            deleteCallBack(data);
        })
  }

export function updateEditableAPI(id,formValues, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    var reqObj = formFieldToReqJSON(formValues);
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(reqObj)
    };
      
    fetch(TaskinstanceResourcePrefix+'/'+id+'/editable', requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            if (response.status===undefined)    successCallBack(response);
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    failuerCallback(errorMessage);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}

export function getTaskinstanceInfoByBusinessidAPI(businessid, setTaskinstanceList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(TaskinstancesResourcePrefix+"/businessid/"+businessid+"/businessid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setTaskinstanceList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getTaskinstanceInfoByTaskidAPI(taskid, setTaskinstanceList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(TaskinstancesResourcePrefix+"/taskid/"+taskid+"/taskid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setTaskinstanceList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getTaskinstanceInfoByBusinessprocessidAPI(businessprocessid, setTaskinstanceList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(TaskinstancesResourcePrefix+"/businessprocessid/"+businessprocessid+"/businessprocessid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setTaskinstanceList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getTaskinstanceInfoByTaskstatusidAPI(taskstatusid, setTaskinstanceList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(TaskinstancesResourcePrefix+"/taskstatusid/"+taskstatusid+"/taskstatusid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setTaskinstanceList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getTaskinstanceInfoByOrderIdAPI(orderid, setTaskinstanceList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(TaskinstancesResourcePrefix+"/orderid/"+orderid+"/orderId", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setTaskinstanceList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getTaskinstanceInfoByOrderidandproductidAPI(productid,orderid, setTaskinstanceList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(TaskinstancesResourcePrefix+"/orderid/"+orderid+"/productid/"+productid+"/orderidandproductid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setTaskinstanceList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getTaskinstanceInfoByTaskidandproductidAPI(taskid,productid, setTaskinstanceList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(TaskinstancesResourcePrefix+"/taskid/"+taskid+"/productid/"+productid+"/taskidandproductid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setTaskinstanceList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getTaskinstanceInfoByProductorderidAPI(productorderid, setTaskinstanceList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(TaskinstancesResourcePrefix+"/productorderid/"+productorderid+"/productorderid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setTaskinstanceList(data.list);
          dataFetchCallBack(data.list)
    })
}export function getTaskinstanceInfoByProductinstanceidAPI(productinstanceid, setTaskinstanceList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(TaskinstancesResourcePrefix+"/productinstanceid/"+productinstanceid+"/productinstanceid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setTaskinstanceList(data.list);
          dataFetchCallBack(data.list)
    })
}