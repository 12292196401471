import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import FactoryroleDashboard from '../FactoryroleDashboard';

import { openDrawer } from './../../../store/slice/MenuSlice';

export default function FactoryrolePage() {
    const dispatch = useDispatch();
    const { FactoryroleList} = useSelector((state)=>state.manufaslice)
    useEffect(() => {
        dispatch(openDrawer(false));
    },[]);

    return (
        < FactoryroleDashboard />
    )

}