import { createSlice } from '@reduxjs/toolkit';

export const EcomSlice = createSlice({
    name: "ecomslice",
    initialState: {
        cart: {
            cartObj:{},
            refresh:false,
            cartCount:0,
            selectedProduct:{
                id:0, 
                title:"", 
                description:"", 
                price:"",
                colorid:""
            }
        },
        checkout:{
            selectedTab:'ITEMS',
            orderObj:{},
            openOrderConfirmationModal:false
        },
        refreshOrder:false,
        anonymousId:'',
        addressList:[],
		addressDashboard:{
            selectedId:-1,
            showEdit:false,
            addressList:[],
            addressObj:{},
            filterCriteria:{
                
			pincode:''
            },
            addAddressModalOpen:false,
        },
        cartitemList:[],
        bottomMenu:{
            selectedMenu:"HOME"
        },
        ordrList:[],
		ordrDashboard:{
            selectedId:-1,
            showEdit:false,
            ordrList:[],
            ordrObj:{},
            filterCriteria:{
                
			title:'',
			customerid:'',
			customermobile:''
            },
        },
        mlm:{
            mlmUserObj:null,
            partnerAPISuccess:false,
            inviteeMobileNumber:'',
            partnerObj:{},
            inviteeObj:{},
            inviteeProcessStatus:'',
            showGraph:false
        }
    },
    reducers: {
        setCartitemList: (state, action) =>{
            state.cartitemList=action.payload;
        },
        deleteCartitem: (state, action)=>{
            state.cartitemList.splice(state.cartitemList.findIndex(a => a.id === action.payload) , 1);
        },
        updateCartitemObj: (state, action)=>{
            let index = state.cartitemList.map(cartitemObj=>cartitemObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.cartitemList[index]=action.payload;
            }
        },
        setAddressFilterCriteria : (state, action)=>{
            state.addressDashboard.filterCriteria=action.payload;
        },
        setShowEditForAddress: (state,action)=>{
            state.addressDashboard.showEdit=action.payload;
            
        },
        addAddressObj: (state, action)=>{
            state.addressList.unshift(action.payload);
            state.addressDashboard.addressList.unshift(action.payload);

            state.addressDashboard.addressObj=action.payload;
            state.addressDashboard.selectedId=action.payload.id;
        },
        updateAddressObj: (state, action)=>{
            let index = state.addressList.map(addressObj=>addressObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.addressList[index]=action.payload;
            }

            index = state.addressDashboard.addressList.map(addressObj=>addressObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.addressDashboard.addressList[index]=action.payload;
            }
            if (state.addressDashboard.addressObj!==null){
                state.addressDashboard.addressObj = action.payload;
            }
        },
        deleteAddressObj: (state, action)=>{
            state.addressList.splice(state.addressList.findIndex(a => a.id === action.payload) , 1);
            state.addressDashboard.addressList.splice(state.addressDashboard.addressList.findIndex(a => a.id === action.payload) , 1);
            state.addressDashboard.productObj={}
            state.addressDashboard.selectedId=-1
        },
        setSelectedAddressId: (state, action)=>{
            if (action.payload!==-1){
                state.addressDashboard.selectedId=action.payload;
                state.addressDashboard.addressObj = state.addressList[
                    state.addressList.findIndex(addressObj=>addressObj.id===parseInt(action.payload))
            ];
            } else {
                state.addressDashboard.selectedId=action.payload;
                state.addressDashboard.addressObj ={}
            }
        },
        setAddressList: (state, action) =>{
            state.addressList=action.payload;
            state.addressDashboard.addressList=action.payload;
        },
        setAddressDashboardAddressList: (state, action) =>{
            state.addressDashboard.addressList=action.payload;
        },
        setAddressAddAddressModal:(state,action)=>{
            state.addressDashboard.addAddressModalOpen=action.payload;
        },
        setSelectedTab: (state, action) =>{
            state.checkout.selectedTab=action.payload;
        },
        setCartObj : (state, action) =>{
            state.cart.cartObj=action.payload;
        },
        setOrderObj : (state, action) =>{
            state.checkout.orderObj = action.payload
        },
        setOpenOrderConfirmationModal : (state, action) =>{
            state.checkout.openOrderConfirmationModal = action.payload
        },
        setSelectedBottomMenu : (state, action) =>{
            state.bottomMenu.selectedMenu=action.payload;
        },
        setCartCount: (state, action)=>{
            state.cart.cartCount=action.payload;
        },
        setAnonymousId: (state, action)=>{
            state.anonymousId=action.payload;
        },
        refreshCart: (state, action)=>{
            state.cart.refresh = !state.cart.refresh;
        },
        refreshOrder: (state, action)=>{
            state.refreshOrder= !state.refreshOrder;
        },
        setOrderConfirmation : (state, action)=>{
            state.refreshOrder= !state.refreshOrder;
            state.cart.cartObj={};
            state.cart.anonymousId='';
            state.checkout.selectedTab="ITEMS";
            state.cart.cartCount=0;
            state.checkout.orderObj.id = action.payload;
            state.cartitemList=[];
        },
        setOrdrFilterCriteria : (state, action)=>{
            state.ordrDashboard.filterCriteria=action.payload;
        },
        setShowEditForOrdr: (state,action)=>{
            state.ordrDashboard.showEdit=action.payload;
            
        },
        addOrdrObj: (state, action)=>{
            state.ordrList.unshift(action.payload);
            state.ordrDashboard.ordrList.unshift(action.payload);

            state.ordrDashboard.ordrObj=action.payload;
            state.ordrDashboard.selectedId=action.payload.id;
        },
        updateOrdrObj: (state, action)=>{
            let index = state.ordrList.map(ordrObj=>ordrObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.ordrList[index]=action.payload;
            }

            index = state.ordrDashboard.ordrList.map(ordrObj=>ordrObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.ordrDashboard.ordrList[index]=action.payload;
            }
            if (state.ordrDashboard.ordrObj!==null){
                state.ordrDashboard.ordrObj = action.payload;
            }
        },
        deleteOrdrObj: (state, action)=>{
            state.ordrList.splice(state.ordrList.findIndex(a => a.id === action.payload) , 1);
            state.ordrDashboard.ordrList.splice(state.ordrDashboard.ordrList.findIndex(a => a.id === action.payload) , 1);
            state.ordrDashboard.productObj={}
            state.ordrDashboard.selectedId=-1
        },
        setSelectedOrdrId: (state, action)=>{
            if (action.payload!==-1){
                state.ordrDashboard.selectedId=action.payload;
                state.ordrDashboard.ordrObj = state.ordrList[
                    state.ordrList.findIndex(ordrObj=>ordrObj.id===parseInt(action.payload))
            ];
            } else {
                state.ordrDashboard.selectedId=action.payload;
                state.ordrDashboard.ordrObj ={}
            }
        },
        setOrdrList: (state, action) =>{
            state.ordrList=action.payload;
            state.ordrDashboard.ordrList=action.payload;
        },
        setOrdrDashboardOrdrList: (state, action) =>{
            state.ordrDashboard.ordrList=action.payload;
        },
        setMlmUserObj : (state,action) =>{
            state.mlm.mlmUserObj=action.payload;
        },
        setPartnerAPISuccess : (state, action)=>{
            state.mlm.partnerAPISuccess= action.payload;
        },
        setInviteeMobileNumber : (state, action) =>{
            state.mlm.inviteeMobileNumber=action.payload;
        },
        setPartnerObj: (state, action) =>{
            state.mlm.partnerObj=action.payload;
        },
        setInviteeObj: (state, action) =>{
            state.mlm.inviteeObj=action.payload;
        },
        setInviteeProcessStatus: (state, action) =>{
            state.mlm.inviteeProcessStatus=action.payload;
        },
        setShowGraph: (state, action) =>{
            state.mlm.showGraph=action.payload;
        },
        
        
    }
});
export const { setShowGraph, setInviteeProcessStatus, setInviteeObj, setPartnerObj, setInviteeMobileNumber, setPartnerAPISuccess, setMlmUserObj, setOrderConfirmation,setCartCount, setAnonymousId, refreshCart, refreshOrder, setSelectedBottomMenu, setOrderObj, setOpenOrderConfirmationModal, setCartObj, setSelectedTab, updateAddressObj,addAddressObj, setShowEditForAddress,setAddressFilterCriteria, setSelectedAddressId, deleteAddressObj,setAddressList, setAddressDashboardAddressList,setShowProfileMgmtModal, setAddressAddAddressModal, setCartitemList,deleteCartitem,updateCartitemObj,
    setOrdrList,getOrdrList,updateOrdrObj,addOrdrObj,deleteOrdrObj,
setSelectedOrdrId,
setOrdrDashboardOrdrList,
setShowEditForOrdr,
setOrdrFilterCriteria,setProductList,getProductList,updateProductObj,addProductObj,deleteProductObj,
} = EcomSlice.actions;
export default EcomSlice.reducer;