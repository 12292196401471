import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip, Divider, Button } from "@mui/material";
import Cutting from "../assets/cutter.png"; // Import local images
import Printing from "../assets/printer.png";
import Stitching from "../assets/sewing.png";
import Design from '../assets/drawing.png';
import RmCheck from '../assets/rmcheck.png';
import AccountCheck from '../assets/accountcheck.png';
import Packaging from '../assets/packaging.png';
import { useNavigate } from "react-router-dom";
import { setTaskinstanceList, setSelectedTaskinstanceId } from "store/slice/ManufSlice";
import { getTaskinstanceInfoByTaskidandproductidAPI } from "components/taskinstance/TaskinstanceAPI";

import { useSelector, useDispatch } from 'react-redux';

const TaskInstanceListByProductOrderId = ({orderId, productId}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //const [taskInstanceListByProductIdAndTaskId, setTaskInstanceListByProductIdAndTaskId] = useState([]);
    const {taskinstanceList} = useSelector((state)=>state.manufslice);
    
    useEffect(()=>{
       // console.log("taskinstanceList=>"+JSON.stringify(taskinstanceList))
        //getTaskinstanceInfoByTaskidandproductidAPI(taskId, productId, setTaskInstanceList, ()=>{})
    },[])

    const getTaskInstanceListByOrderIdProductIdAndTaskId = (taskId) =>{
        const filteredList = taskinstanceList
            .filter(taskinstanceObj => taskinstanceObj.orderid===orderId && taskinstanceObj.productid===productId && taskinstanceObj.taskid===taskId)

        console.log("filteredList=>"+JSON.stringify(filteredList));
        if (filteredList.length>0)
            return filteredList[0].taskstatusname
        else return ''
    }

    return (

        <>
        <Grid container justifyContent="center" lg={1} alignItems="center">
            {getTaskInstanceListByOrderIdProductIdAndTaskId(1)}
        </Grid>
        <Grid container justifyContent="center" lg={1} alignItems="center">
            {getTaskInstanceListByOrderIdProductIdAndTaskId(2)}
        </Grid>
        <Grid container justifyContent="center" lg={1} alignItems="center" >
        {getTaskInstanceListByOrderIdProductIdAndTaskId(3)}
        </Grid>
        <Grid container justifyContent="center" lg={1} alignItems="center" >
        {getTaskInstanceListByOrderIdProductIdAndTaskId(4)}
        </Grid>
        <Grid container justifyContent="center" lg={1} alignItems="center">
        {getTaskInstanceListByOrderIdProductIdAndTaskId(5)}
        </Grid>
        <Grid container justifyContent="center" lg={1} alignItems="center">
        {getTaskInstanceListByOrderIdProductIdAndTaskId(6)}
        </Grid>
        <Grid container justifyContent="center" lg={1} alignItems="center" >
        {getTaskInstanceListByOrderIdProductIdAndTaskId(7)}
        </Grid>
        </>
        // <Grid container directin="column" justifyContent="flex-start" >
        //     {taskInstanceList.map(taskInstanceObj=>
        //             <Grid item>
        //                 {taskInstanceObj.orderid}
        //             </Grid>
        //         )
        //     }
            


        // </Grid>

    );
};

export default TaskInstanceListByProductOrderId;
