import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MfdeliverypriorityFilterCriteria from './MfdeliverypriorityFilterCriteria'
import MfdeliverypriorityList from './MfdeliverypriorityList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MfdeliverypriorityAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMfdeliverypriorityDashboardMfdeliverypriorityList } from '../../store/slice/ManufSlice';

export default function MfdeliverypriorityListView({setShowEdit,setMfdeliverypriorityId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mfdeliverypriorityDashboard);   

    const [filteredMfdeliverypriorityList, setFilteredMfdeliverypriorityList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mfdeliverypriorityDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mfdeliverypriorityDashboard.mfdeliverypriorityList.length>-1){
        setFilteredMfdeliverypriorityList(mfdeliverypriorityDashboard.mfdeliverypriorityList);
        transformToCardFormat(mfdeliverypriorityDashboard.mfdeliverypriorityList, setCardList);
      }
    },[mfdeliverypriorityDashboard.mfdeliverypriorityList])

    useEffect(()=>{
          transformToCardFormat(filteredMfdeliverypriorityList, setCardList);
      },[filteredMfdeliverypriorityList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredMfdeliverypriorityList(mfdeliverypriorityDashboard.mfdeliverypriorityList);
            transformToCardFormat(mfdeliverypriorityDashboard.mfdeliverypriorityList, setCardList);

        } else {
            setFilteredMfdeliverypriorityList(
                mfdeliverypriorityDashboard.mfdeliverypriorityList
                    .filter(mfdeliverypriority=> mfdeliverypriority.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mfdeliverypriorityDashboard.filteredMfdeliverypriorityList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MfdeliverypriorityList
                    setShowEdit={setShowEdit}
                    setMfdeliverypriorityId = {setMfdeliverypriorityId}
                    tableList={filteredMfdeliverypriorityList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}