import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography} from '@mui/material';


export default function MforderListTableHeader(){
    const {mobileView} = useSelector((state)=>state.menu);
    return (
      
      <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "center", width: '100%', padding: 1, backgroundColor: 'primary.lighter' }}>

            {/* <HEADER - CELLS/> */}
            <Grid item lg={1} xs={2} ><Typography variant="h5"> # </Typography></Grid>
            <Grid item lg={3} xs={6} ><Typography variant="h5"> Party Name </Typography></Grid>
            {!mobileView?<>
            <Grid item lg={2} xs={1} ><Typography variant="h5"> Order Date </Typography></Grid>
            <Grid item lg={3} xs={1} ><Typography variant="h5"> Executive </Typography></Grid>
            <Grid item lg={2} xs={1} ><Typography variant="h5"> Status</Typography></Grid>
            </>:<></>}

      </Grid>
      
    )
}