import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MfproductorderstatusFilterCriteria from './MfproductorderstatusFilterCriteria'
import MfproductorderstatusList from './MfproductorderstatusList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MfproductorderstatusAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMfproductorderstatusDashboardMfproductorderstatusList } from '../../store/slice/ManufSlice';

export default function MfproductorderstatusListView({setShowEdit,setMfproductorderstatusId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mfproductorderstatusDashboard);   

    const [filteredMfproductorderstatusList, setFilteredMfproductorderstatusList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mfproductorderstatusDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mfproductorderstatusDashboard.mfproductorderstatusList.length>-1){
        setFilteredMfproductorderstatusList(mfproductorderstatusDashboard.mfproductorderstatusList);
        transformToCardFormat(mfproductorderstatusDashboard.mfproductorderstatusList, setCardList);
      }
    },[mfproductorderstatusDashboard.mfproductorderstatusList])

    useEffect(()=>{
          transformToCardFormat(filteredMfproductorderstatusList, setCardList);
      },[filteredMfproductorderstatusList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredMfproductorderstatusList(mfproductorderstatusDashboard.mfproductorderstatusList);
            transformToCardFormat(mfproductorderstatusDashboard.mfproductorderstatusList, setCardList);

        } else {
            setFilteredMfproductorderstatusList(
                mfproductorderstatusDashboard.mfproductorderstatusList
                    .filter(mfproductorderstatus=> mfproductorderstatus.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mfproductorderstatusDashboard.filteredMfproductorderstatusList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MfproductorderstatusList
                    setShowEdit={setShowEdit}
                    setMfproductorderstatusId = {setMfproductorderstatusId}
                    tableList={filteredMfproductorderstatusList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}