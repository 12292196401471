import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function MfproductorderstatusListTableRow({obj, getActionComponent,handleRowClick}){
    return (
      
      <>
        <Grid item lg={12} sx={{ width: '100%'}}>
          {/* <ROW1 - CONTAINER/> */}
          <Grid container direction="row" onClick={() => handleRowClick(obj.id)}
            sx={{
              justifyContent: "center", alignItems: "center",  padding:.25,  backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'secondary.lighter', color: 'primary.main'
              },
            }}>
            {/* <ROW1 - CELLS/> */}
            
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 13 }}>{obj.businessid}</Typography></Grid>
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 13 }}>{obj.title}</Typography></Grid>

              <Grid container direction="row" sx={{ justifyContent: "flex-end", alignItems: "flex-start", width: '100%', }}>
                {getActionComponent(obj)}
              </Grid>
            
          </Grid>
        </Grid>

        

      </>
      
    )
}