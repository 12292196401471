import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import MfproductDashboard from '../MfproductDashboard';

import { openDrawer } from './../../../store/slice/MenuSlice';

export default function MfproductPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(openDrawer(false));
    },[]);

    return (
        < MfproductDashboard />
    )

}