import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function TaskinstanceInfo(){
  const { taskinstanceObj} = useSelector((state)=>state.manufslice.taskinstanceDashboard);
    return (
      <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: { xs: window.screen.height - 250, lg: window.screen.height } }}>
        
        <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{backgroundColor:'white'}}
      >
            
        <Grid item lg={4} xs={6}><FieldView label="Business Id"  value={ taskinstanceObj.businessid!==null && taskinstanceObj.businessid!==undefined?taskinstanceObj.businessid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Title"  value={ taskinstanceObj.title!==null && taskinstanceObj.title!==undefined?taskinstanceObj.title:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Order Id"  value={ taskinstanceObj.orderid!==null && taskinstanceObj.orderid!==undefined?taskinstanceObj.orderid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Task Id"  value={ taskinstanceObj.taskid!==null && taskinstanceObj.taskid!==undefined?taskinstanceObj.taskid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Task Name"  value={ taskinstanceObj.taskname!==null && taskinstanceObj.taskname!==undefined?taskinstanceObj.taskname:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Task Status Id"  value={ taskinstanceObj.taskstatusid!==null && taskinstanceObj.taskstatusid!==undefined?taskinstanceObj.taskstatusid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Task Status Name"  value={ taskinstanceObj.taskstatusname!==null && taskinstanceObj.taskstatusname!==undefined?taskinstanceObj.taskstatusname:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Business Process Id"  value={ taskinstanceObj.businessprocessid!==null && taskinstanceObj.businessprocessid!==undefined?taskinstanceObj.businessprocessid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Product Id"  value={ taskinstanceObj.productid!==null && taskinstanceObj.productid!==undefined?taskinstanceObj.productid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Product Order Id"  value={ taskinstanceObj.productorderid!==null && taskinstanceObj.productorderid!==undefined?taskinstanceObj.productorderid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Product Instance Id"  value={ taskinstanceObj.productinstanceid!==null && taskinstanceObj.productinstanceid!==undefined?taskinstanceObj.productinstanceid:'NOT KNONWN' }/></Grid>
      </Grid>
      </Grid>
      </Box>
    )
}