
import SettingsIcon from '@mui/icons-material/Settings';
import QrCode2Icon from '@mui/icons-material/QrCode2';


const FactoryConfiguration = {
  id: '7',
  title: 'Factory Configuration',
  type: 'group',
  role:'FACTORY_MANAGER,FACTORY_ADMIN',
  children: [
    {
      id: 'mfproduct',
      title: 'Product',
      type: 'item',
      url: '/mfproduct',
      icon: QrCode2Icon
    },
  ]
};

export default FactoryConfiguration;