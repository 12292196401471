import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip, Divider, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Done from "./assets/done.jpg";
import Wip from "./assets/wip1.png";
import Todo from "./assets/todo1.jpeg";

import { useSelector, useDispatch } from 'react-redux';
import { setMforderUpdateMfOrderStatusModal } from "store/slice/ManufSlice";
import { setTaskinstanceUpdateStatusModal } from "store/slice/ManufSlice";
import { setSelectedMforderId, setSelectedMfproductorderId, setSelectedTaskinstanceId } from "store/slice/ManufSlice";

const TaskInstanceListByProductOrderId = ({productOrderId}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //const {taskinstanceList} = useSelector((state)=>state.manufslice);
    const {taskinstanceList} = useSelector((state)=>state.manufslice.taskinstanceDashboard);
    
    const getTaskInstanceListByOrderIdProductIdAndTaskId = (taskId) =>{
        const index = taskinstanceList.findIndex(taskinstanceObj => 
            taskinstanceObj.productorderid === productOrderId && 
            taskinstanceObj.taskid === taskId
        )
        return taskinstanceList[index]
    }
    const updateStatus = (taskInstanceObj)=>{
        dispatch(setSelectedTaskinstanceId(taskInstanceObj.id));
        dispatch(setTaskinstanceUpdateStatusModal(true));
    }
    const getTaskInstanceIcon = (taskInstanceObj) =>{
        if (!taskInstanceObj)   return '';
        
        if (taskInstanceObj && taskInstanceObj.taskstatusname==='WIP')    return <Avatar  src={Wip} sx={{ width: 45, height: 45, padding: 0.5 }} />
        else if (taskInstanceObj && taskInstanceObj.taskstatusname==='DONE')    return <Avatar src={Done} sx={{ width: 45, height: 45, padding: 0.5 }} />
        else  if (taskInstanceObj && taskInstanceObj.taskstatusname==='TODO')  return <Avatar src={Todo} sx={{ width: 45, height: 45, padding: 0.5 }} />

        return ''
    }

    return (

        <>
        <Grid container justifyContent="center" lg={1} xs={1} alignItems="center" sx={{cursor:'pointer'}} onClick={()=>updateStatus(getTaskInstanceListByOrderIdProductIdAndTaskId(1))}>
            {getTaskInstanceIcon(getTaskInstanceListByOrderIdProductIdAndTaskId(1))}
        </Grid>
        <Grid container justifyContent="center" lg={1}  xs={1} alignItems="center" sx={{cursor:'pointer'}} onClick={()=>updateStatus(getTaskInstanceListByOrderIdProductIdAndTaskId(2))}>
            {getTaskInstanceIcon(getTaskInstanceListByOrderIdProductIdAndTaskId(2))}
        </Grid>
        <Grid container justifyContent="center" lg={1} xs={1} alignItems="center" sx={{cursor:'pointer'}} onClick={()=>updateStatus(getTaskInstanceListByOrderIdProductIdAndTaskId(3))}>
            {getTaskInstanceIcon(getTaskInstanceListByOrderIdProductIdAndTaskId(3))}
        </Grid>
        <Grid container justifyContent="center" lg={1} xs={1} alignItems="center" sx={{cursor:'pointer'}} onClick={()=>updateStatus(getTaskInstanceListByOrderIdProductIdAndTaskId(4))}>
            {getTaskInstanceIcon(getTaskInstanceListByOrderIdProductIdAndTaskId(4))}
        </Grid>
        <Grid container justifyContent="center" lg={1} xs={1} alignItems="center" sx={{cursor:'pointer'}} onClick={()=>updateStatus(getTaskInstanceListByOrderIdProductIdAndTaskId(5))}>
            {getTaskInstanceIcon(getTaskInstanceListByOrderIdProductIdAndTaskId(5))}
        </Grid>
        {/* <Grid container justifyContent="center" lg={1} xs={1} alignItems="center" sx={{cursor:'pointer'}} onClick={()=>updateStatus(getTaskInstanceListByOrderIdProductIdAndTaskId(6))}>
            {getTaskInstanceIcon(getTaskInstanceListByOrderIdProductIdAndTaskId(6))}
        </Grid> */}
        <Grid container justifyContent="center" lg={1} xs={1} alignItems="center" sx={{cursor:'pointer'}} onClick={()=>updateStatus(getTaskInstanceListByOrderIdProductIdAndTaskId(7))}>
            {getTaskInstanceIcon(getTaskInstanceListByOrderIdProductIdAndTaskId(7))}
        </Grid>
        <Grid container justifyContent="center" lg={1} xs={1} alignItems="center" sx={{cursor:'pointer'}} onClick={()=>updateStatus(getTaskInstanceListByOrderIdProductIdAndTaskId(13))}>
            {getTaskInstanceIcon(getTaskInstanceListByOrderIdProductIdAndTaskId(13))}
        </Grid>
        </>
    );
};

export default TaskInstanceListByProductOrderId;
