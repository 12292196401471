import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';


import { openDrawer } from 'store/slice/MenuSlice';
import { setMfproductorderDashboardMfproductorderList,setSelectedMforderId,
    setMforderDashboardMforderList, setTaskinstanceList
 } from 'store/slice/ManufSlice';
import { getListAPI } from 'components/taskinstance/TaskinstanceAPI';
import MfOrderDashboardHeader from './MfOrderDashboardHeader';
import UpdateMfOrderStatusActionModal from 'components/mforder/action/updateMfOrderStatus/UpdateMfOrderStatusActionModal';

export default function MfOrderDashboard() {
    const dispatch = useDispatch();
    const {mfproductorderList} = useSelector((state)=>state.manufslice);
    const {mforderList} = useSelector((state)=>state.manufslice);

    useEffect(() => {
        //dispatch(openDrawer({drawerOpen:false}));
        dispatch(setSelectedMforderId(-1));

        dispatch(setMforderDashboardMforderList(
            mforderList.filter(mfOrder=>mfOrder.orderstatusid!==9)
        ))
        getListAPI(()=>{}, getTaskInstanceListSuccess);
    },[mforderList]);

    const getTaskInstanceListSuccess = (apiResponse) =>{
        dispatch(setTaskinstanceList(apiResponse))
    }

    const getProductOrderWithWIPstatusSuccess = (apiResponse) =>{
        const uniqueProducts = apiResponse.reduce((acc, curr) => {
            if (!acc.some(item => item.productid === curr.productid)) {
              acc.push(curr);
            }
            return acc;
          }, []);

          dispatch(setMfproductorderDashboardMfproductorderList(uniqueProducts))
        
    }

    return (
        <>
       <MfOrderDashboardHeader/>
       
       </>
    )
}