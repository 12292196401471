import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip, Divider, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import TaskInstanceListByProductOrderId from "./TaskInstanceListByProductOrderId";

const ProductOrderListWithOrderId = ({ orderId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mfproductorderList } = useSelector((state) => state.manufslice);
    const [productOrderList, setProductOrderList] = useState([]);
    const {taskinstanceList} = useSelector((state)=>state.manufslice.taskinstanceDashboard);
    const {filteredTaskId} = useSelector((state)=>state.appslice.orderDashboard);
    
    useEffect(()=>{
    },[mfproductorderList]);
    
    const getSortedProductOrderIdList = (productorderList) =>{
        const taskInstanceListByOrderId = taskinstanceList.filter(taskinstanceObj=>taskinstanceObj.orderid===orderId);

        // Extract task instace list which are in given task id and given task status
        // Extract product Order Id of extracted task instance List
        
        const todoTaskInstanceList = taskInstanceListByOrderId.filter(taskInstance => taskInstance.orderid===orderId && taskInstance.taskstatusid === 1 && taskInstance.taskid===filteredTaskId);
        const productOrderIdListWithTODOtask = [...new Set(todoTaskInstanceList.map(taskInstance => taskInstance.productorderid))];

        const wipTaskInstanceList = taskInstanceListByOrderId.filter(taskInstance => taskInstance.orderid===orderId && taskInstance.taskstatusid === 2 && taskInstance.taskid===filteredTaskId);
        const productOrderIdListWithWIPtask = [...new Set(todoTaskInstanceList.map(taskInstance => taskInstance.productorderid))];

        const todoProductOrderList = productorderList.filter(productorder => productOrderIdListWithTODOtask.includes(productorder.id)); // those part of given task id & it's status
        const wipProductOrderList = productorderList.filter(productorder => productOrderIdListWithWIPtask.includes(productorder.id)); // those part of given task id & it's status 
        const followProductOrderList = productorderList.filter(productorder => !productOrderIdListWithTODOtask.includes(productorder.id) && !productOrderIdListWithWIPtask.includes(productorder.id)); // those remaining
    
        // Merge WIP first, then others
        const mergedProductOrderList = [...todoProductOrderList, ...wipProductOrderList,...followProductOrderList];
        
        return mergedProductOrderList;
    }
    const getProductOrderList = ()=>{
        return getSortedProductOrderIdList(mfproductorderList.filter(productOrder => (productOrder.orderid === orderId && productOrder.productorderstatusid!==4)))
    }
    const sortProductOrderListBasedOnTaskIdStatus = (productOrderList, taskId, taskInstanceStatusId)=>{

    } 

    return (
            <Grid item lg={12}>
                {
                
                getProductOrderList().map((productOrderObj, index) =>
                    <>
                        <Grid key={index}>
                            <Grid container alignItems="center" direction="row" sx={{ height:'100%', 
                                borderBottom: 
                                        productOrderList.length>1 && 
                                        index<productOrderList.length-1
                                    ?'1px dotted #d9d9d9':'' }}>
                                <Grid item lg={2} sx={{ padding: 1 }}>{productOrderObj.productname}</Grid>
                                <Grid item lg={10} xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" sx={{paddingLeft:.5, paddingRight:.5}}>
                                        <TaskInstanceListByProductOrderId productOrderId={productOrderObj.id}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
    );

};

export default ProductOrderListWithOrderId;
