import { Grid, Tooltip} from "@mui/material";

export function getMfproductorderStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        productid:{
            name:'productid',
            label:'Product Id',
            value:'',
            error:false,
            errorMessage:'You must enter Product Id',
            required:true,
            fieldtype:'bodynamiclist'
        },
        productname:{
            name:'productname',
            label:'Product Name',
            value:'',
            error:false,
            errorMessage:'You must enter Product Name',
            required:true,
            fieldtype:'string'
        },
        matter:{
            name:'matter',
            label:'Matter',
            value:'',
            error:false,
            errorMessage:'You must enter Matter',
            required:true,
            fieldtype:'string'
        },
        qty:{
            name:'qty',
            label:'Qty',
            value:'',
            error:false,
            errorMessage:'You must enter Qty',
            required:true,
            fieldtype:'number'
        },
        deliverypriorityid:{
            name:'deliverypriorityid',
            label:'Delivery Priority Id',
            value:'',
            error:false,
            errorMessage:'You must enter Delivery Priority Id',
            required:true,
            fieldtype:'bodynamiclist'
        },
        deliverypriorityname:{
            name:'deliverypriorityname',
            label:'Delivery Priority Name',
            value:'',
            error:false,
            errorMessage:'You must enter Delivery Priority Name',
            required:true,
            fieldtype:'string'
        },
        expecteddelivery:{
            name:'expecteddelivery',
            label:'Expected Delivery',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'date'
        },
        stitchingstatusid:{
            name:'stitchingstatusid',
            label:'Stitching status Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        stitchingstatusname:{
            name:'stitchingstatusname',
            label:'Stitching Status Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        digitalstatusid:{
            name:'digitalstatusid',
            label:'Digital Status Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        digitalstatusname:{
            name:'digitalstatusname',
            label:'Digital Status Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        productorderstatusid:{
            name:'productorderstatusid',
            label:'Product Order Status Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        productorderstatusname:{
            name:'productorderstatusname',
            label:'Product Order Status Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        partyname:{
            name:'partyname',
            label:'Party Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        orderid:{
            name:'orderid',
            label:'Order Id',
            value:'',
            error:false,
            errorMessage:'You must enter Order Id',
            required:true,
            fieldtype:'ID'
        },
        printingtype:{
            name:'printingtype',
            label:'Printing Type',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'boolean'
        },
        stitchingtype:{
            name:'stitchingtype',
            label:'Stitching Type',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'boolean'
        },
        remarks:{
            name:'remarks',
            label:'Remarks',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        stockready:{
            name:'stockready',
            label:'Stock Ready',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'boolean'
        },
        withprinting:{
            name:'withprinting',
            label:'With Printing',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'boolean'
        },
        unit:{
            name:'unit',
            label:'Unit',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'list'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'productname', headerName: 'Product Name', width:150 },
        { field: 'matter', headerName: 'Matter', width:50 },
        { field: 'qty', headerName: 'Qty', width:50 },
        { field: 'deliverypriorityid', headerName: 'Delivery Priority Id', width:50 },
        { field: 'deliverypriorityname', headerName: 'Delivery Priority Name', width:50 },
        { field: 'expecteddelivery', headerName: 'Expected Delivery', width:50 },
        { field: 'stitchingstatusname', headerName: 'Stitching Status Name', width:50 },
        { field: 'digitalstatusname', headerName: 'Digital Status Name', width:50 },
        { field: 'productorderstatusname', headerName: 'Product Order Status Name', width:50 },
        { field: 'partyname', headerName: 'Party Name', width:50 },
        { field: 'orderid', headerName: 'Order Id', width:50 },
        { field: 'unit', headerName: 'Unit', width:50 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['productid']:{
          ...formValues['productid'],
          value:data.productid,
        },['productname']:{
          ...formValues['productname'],
          value:data.productname,
        },['matter']:{
          ...formValues['matter'],
          value:data.matter,
        },['qty']:{
          ...formValues['qty'],
          value:data.qty,
        },['deliverypriorityid']:{
          ...formValues['deliverypriorityid'],
          value:data.deliverypriorityid,
        },['deliverypriorityname']:{
          ...formValues['deliverypriorityname'],
          value:data.deliverypriorityname,
        },['expecteddelivery']:{
          ...formValues['expecteddelivery'],
          value:data.expecteddelivery,
        },['stitchingstatusid']:{
          ...formValues['stitchingstatusid'],
          value:data.stitchingstatusid,
        },['stitchingstatusname']:{
          ...formValues['stitchingstatusname'],
          value:data.stitchingstatusname,
        },['digitalstatusid']:{
          ...formValues['digitalstatusid'],
          value:data.digitalstatusid,
        },['digitalstatusname']:{
          ...formValues['digitalstatusname'],
          value:data.digitalstatusname,
        },['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['productorderstatusid']:{
          ...formValues['productorderstatusid'],
          value:data.productorderstatusid,
        },['productorderstatusname']:{
          ...formValues['productorderstatusname'],
          value:data.productorderstatusname,
        },['partyname']:{
          ...formValues['partyname'],
          value:data.partyname,
        },['orderid']:{
          ...formValues['orderid'],
          value:data.orderid,
        },['printingtype']:{
          ...formValues['printingtype'],
          value:data.printingtype,
        },['stitchingtype']:{
          ...formValues['stitchingtype'],
          value:data.stitchingtype,
        },['remarks']:{
          ...formValues['remarks'],
          value:data.remarks,
        },['stockready']:{
          ...formValues['stockready'],
          value:data.stockready,
        },['withprinting']:{
          ...formValues['withprinting'],
          value:data.withprinting,
        },['unit']:{
          ...formValues['unit'],
          value:data.unit,
        },
      })
}