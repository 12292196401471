import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import TaskstatusEditForm from './TaskstatusEditForm';
import TaskstatusList from './TaskstatusList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import TaskstatusListView from './TaskstatusListView'


export default function TaskstatusDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.taskstatusDashboard);
  const [ taskstatusId, setTaskstatusId] = React.useState('');
  const [reloadTaskstatusList, setReloadTaskstatusList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <TaskstatusEditForm 
            setReloadTaskstatusList={setReloadTaskstatusList}
            taskstatusId={taskstatusId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <TaskstatusListView 
                      setTaskstatusId = {setTaskstatusId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
