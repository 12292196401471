import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Divider } from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function MfpartyInfo({ mfpartyObj }) {
  return (
    <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid item sx={{ overflowY: "scroll", maxHeight: { xs: window.screen.height - 250, lg: window.screen.height } }}>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{  padding: 1 }}
        >
          <Grid item lg={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Divider sx={{ width: '95%', borderColor: "primary.dark" }} />
</Grid>
          <Grid item lg={4} xs={8}><FieldView color="secondary.100" fontSize={15} label="email" value={mfpartyObj.email !== null && mfpartyObj.email !== undefined ? mfpartyObj.email : 'NOT KNONWN'} /></Grid>
          <Grid item lg={4} xs={4}><FieldView color="secondary.100" fontSize={15} label="GST" value={mfpartyObj.gst !== null && mfpartyObj.gst !== undefined ? mfpartyObj.gst : 'NOT KNONWN'} /></Grid>
          <Grid item lg={4} xs={12}><FieldView color="secondary.100" fontSize={15} label="Address" value={mfpartyObj.address !== null && mfpartyObj.address !== undefined ? mfpartyObj.address : 'NOT KNONWN'} /></Grid>

        </Grid>
      </Grid>
    </Box>
  )
}