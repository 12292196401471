import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import { Button, Divider, TextField, Grid, Typography, Box } from "@mui/material"

import { getMforderStruct, setData } from '../MforderStruct'

import { updateTextValue, handleSubmit, updateValue, updateSelectedValue } from '../../../core/js/FormUtils'
import { createAPI, updateAPI, getAPI, updateEditableAPI } from '../MforderAPI';
import { CoreContext } from "../../../core/context/CoreContext";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { validMobileNumberEntry, validNameEntry, validEmailEntry } from '../../../core/js/FormValidation';

import { useSearchParams } from "react-router-dom";
import MyDate from '../../../core/components/MyDate';
import { useDispatch, useSelector } from 'react-redux';
import { updateMforderObj, addMforderObj, setShowEditForMforder } from 'store/slice/ManufSlice';




export default function MforderStatusEditForm() {
    const dispatch = useDispatch();
    const { setNotification, lastURL } = React.useContext(CoreContext);
    const [formValues, setFormValues] = useState(getMforderStruct);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('Something went wrong');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const {appuserInfo} = useSelector((state)=>state.headerslice)
    const { mforderDashboard } = useSelector((state) => state.manufslice);
    const [mforderId, setMforderId] = useState(mforderDashboard.selectedId);
    const { businessid } = useSelector((state) => state.headerslice.userprofileInfo)

    const { mfpartyList } = useSelector((state) => state.manufslice);
    const handleDynamicSelectedValueForpartyid = (e) => {
        updateSelectedValue(formValues, setFormValues, e,
            'partyname', mfpartyList.find(obj => obj.id === e.target.value).title);
    }

    const {mforderstatusList} = useSelector((state)=>state.manufslice);
    const handleDynamicSelectedValueFororderstatusid = (e) =>{
        updateSelectedValue(formValues, setFormValues,e, 
            'orderstatusname', mforderstatusList.find(obj => obj.id === e.target.value).title);
    }

    useEffect(() => {
        if (mforderId !== -1) {
            if (dataLoaded) return;
            getAPI(mforderId, loadForm);
        }
        else {
            // formValues.<<CONTEXT>>.value=searchParams.get('<<CONTEXT>>');
            setDataLoaded(true);
            setFormValues({
                ...formValues,
                ['businessid']: {
                    ...formValues['businessid'],
                    value: businessid
                },
                ['executive']: {
                    ...formValues['executive'],
                    value: appuserInfo.name
                },
                ['orderdate']: {
                    ...formValues['orderdate'],
                    value: new Date().toISOString()
                },
                ['orderstatusid']: {
                    ...formValues['orderstatusid'],
                    value: 1
                },
                ['orderstatusname']: {
                    ...formValues['orderstatusname'],
                    value: 'DRAFT'
                }
            })
        }

        
        
    }, []);

    const loadForm = (data) => {
        setData(data, formValues, setFormValues);
        setDataLoaded(true);
    }
    const handleRadioChange = (name, value) => {
        setShowError(false);
        console.log(value + " == " + getRadioAPIvalue(value))
        updateValue(formValues, setFormValues, name, getRadioAPIvalue(value));
    }
    const getRadioValue = (value) => {
        if (value)
            return "yes"
        else
            return "no"
    }
    const getRadioAPIvalue = (value) => {
        if (value === "yes")
            return true
        else
            return false
    }
    const handleTextChange = (e) => {
        setShowError(false);
        updateTextValue(formValues, setFormValues, e);
    }

    const handleNameChange = (e) => {
        setShowError(false);

        if (validNameEntry(e))
            updateTextValue(formValues, setFormValues, e);
    }
    const handleMobileChange = (e) => {
        setShowError(false);

        if (validMobileNumberEntry(e))
            updateTextValue(formValues, setFormValues, e);
    }
    const handleEmailidChange = (e) => {
        setShowError(false);

        if (validEmailEntry(e))
            updateTextValue(formValues, setFormValues, e);
    }

    const createSuccessCallback = (obj) => {
        dispatch(addMforderObj(obj));
        setNotification('Record has been cretaed successfully !!!');
        dispatch(setShowEditForMforder(false));
    }
    const updateSuccessCallback = (obj) => {
        dispatch(updateMforderObj(obj));
        setNotification('Record with id[' + mforderId + '] has been updated successfully !!!');
        dispatch(setShowEditForMforder(false));

    }
    const failuerCallback = (errorMsg) => {
        setErrorMsg(errorMsg);
        setShowError(true);
    }
    const onSubmit = (e) => {
        handleSubmit(e, mforderId, formValues, setFormValues, createSuccessCallback, updateSuccessCallback, failuerCallback, createAPI, updateAPI);
    }
    const setDate = (fieldName, value) => {
        var date = new Date(value);

        const day = date.getDate();
        const dayStr = day < 10 ? '0' + day : day.toString();

        const month = date.getMonth() + 1;
        const monthStr = month < 10 ? '0' + month : month.toString();
        var dateStr = date.getFullYear() + "-" + monthStr + "-" + dayStr + "T00:00:00.000Z";
        updateValue(formValues, setFormValues, fieldName, dateStr);
    }
    return (
        <form noValidate onSubmit={onSubmit} >
            <Grid container direction="column" padding={.25}>
                {showError ? <Alert severity="error">{errorMsg}</Alert> : ''}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: 440 }}>
                        <Grid container direction="column" padding={.25}>
                            <Grid item>
                                <Typography variant='h4'>MF Order {mforderId === -1 ? 'Add' : 'Edit'}</Typography>
                                <Divider />
                            </Grid>
                            <Grid height='10px' ></Grid>
                            {dataLoaded ?
                                <Grid container display="flex" padding={1} spacing={2} direction="row" justifyContent="space-between" alignItems="center">

                                    <Grid item lg={4} xs={12}>
                                        <FormControl sx={{ width: { lg: '100%', xs: '200px' } }} size="Normal">
                                            <InputLabel id="demo-simple-select-autowidth-label">Party Name</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                label={formValues.partyid.label}
                                                name={formValues.partyid.name}
                                                value={formValues.partyid.value}
                                                onChange={handleDynamicSelectedValueForpartyid}

                                                error={formValues.partyid.error}
                                                helpertext={formValues.partyid.error && formValues.partyid.errorMessage}
                                            >
                                                <MenuItem key={0} value="0">Select Item</MenuItem>
                                                {
                                                    mfpartyList && mfpartyList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <MyDate
                                            label={formValues.orderdate.label}
                                            fieldName={formValues.orderdate.name}
                                            defaultValue={formValues.orderdate.value}
                                            errStr={formValues.orderdate.errorMessage}
                                            setDate={setDate}
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.executive.placeholder}
                                            required={formValues.executive.required}
                                            label={formValues.executive.label}
                                            name={formValues.executive.name}
                                            value={formValues.executive.value}
                                            onChange={handleTextChange}
                                            error={formValues.executive.error}
                                            helpertext={formValues.executive.error && formValues.executive.errorMessage}
                                            sx={{ width: '100%', }}
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.remarks.placeholder}
                                            required={formValues.remarks.required}
                                            label={formValues.remarks.label}
                                            name={formValues.remarks.name}
                                            value={formValues.remarks.value}
                                            onChange={handleTextChange}
                                            error={formValues.remarks.error}
                                            multiline
                                            rows={3}
                                            helpertext={formValues.remarks.error && formValues.remarks.errorMessage}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>

                                    <Grid item lg={4} xs={12}>
                                <FormControl sx={{  width:{lg:'100%',xs:'200px',}}} size="Normal">
                                    <InputLabel id="demo-simple-select-autowidth-label">Order Status</InputLabel>
                                    <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            label={formValues.orderstatusid.label}
                                            name={formValues.orderstatusid.name}
                                            value={formValues.orderstatusid.value}
                                            onChange={handleDynamicSelectedValueFororderstatusid}
                                            
                                            error={formValues.orderstatusid.error}
                                            helpertext={formValues.orderstatusid.error && formValues.orderstatusid.errorMessage}
                                            >
                                            <MenuItem key={0} value="0">Select Item</MenuItem>
                                            {
                                                mforderstatusList && mforderstatusList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>) 
                                            }
                                        </Select>
                                </FormControl> 
                            </Grid>                        
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.orderstatusname.placeholder}
                                    required={formValues.orderstatusname.required}
                                    label={formValues.orderstatusname.label}
                                    name={formValues.orderstatusname.name}
                                    value={formValues.orderstatusname.value}
                                    onChange={handleTextChange}
                                    error={formValues.orderstatusname.error}
                                    helpertext={formValues.orderstatusname.error && formValues.orderstatusname.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.partyname.placeholder}
                                            required={formValues.partyname.required}
                                            label={formValues.partyname.label}
                                            name={formValues.partyname.name}
                                            value={formValues.partyname.value}
                                            onChange={handleTextChange}
                                            error={formValues.partyname.error}
                                            helpertext={formValues.partyname.error && formValues.partyname.errorMessage}
                                            sx={{ width: '100%', display:'none' }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <TextField size="Normal"
                                            placeholder={formValues.businessid.placeholder}
                                            required={formValues.businessid.required}
                                            label={formValues.businessid.label}
                                            name={formValues.businessid.name}
                                            value={formValues.businessid.value}
                                            onChange={handleTextChange}
                                            error={formValues.businessid.error}
                                            helpertext={formValues.businessid.error && formValues.businessid.errorMessage}
                                            sx={{ width: '100%', display: 'none' }}
                                        />
                                    </Grid>
                                </Grid>
                                : <></>}
                        </Grid>
                    </Grid>
                </Box>
                <Grid height='25px' ></Grid>
                <Grid padding={1}>
                    <Divider />
                </Grid>
                <Grid container direction="row" justifyContent="flex-end"  >
                    <Button variant="outlined" type="submit" >{mforderId === -1 ? 'Add' : 'Save'}</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="outlined" onClick={() => { dispatch(setShowEditForMforder(false)); }}>Close</Button>
                </Grid>
            </Grid>
        </form>
    );
};