
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// icons
const icons = {
  ArrowForwardIosIcon
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const oms = {
  id: 'oms',
  title: 'Manuf',
  type: 'group',
  role: 'FACTORY_ADMIN',
  children: [
      {
      id: 'mfprintingstatus',
      title: 'Mfprintingstatus',
      type: 'item',
      url: '/mfprintingstatus',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mfstitchingstatus',
      title: 'Mfstitchingstatus',
      type: 'item',
      url: '/mfstitchingstatus',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mfdigitalstatus',
      title: 'Mfdigitalstatus',
      type: 'item',
      url: '/mfdigitalstatus',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mfdeliverypriority',
      title: 'Mfdeliverypriority',
      type: 'item',
      url: '/mfdeliverypriority',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mfprintingtype',
      title: 'Mfprintingtype',
      type: 'item',
      url: '/mfprintingtype',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mforderstatus',
      title: 'Mforderstatus',
      type: 'item',
      url: '/mforderstatus',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mforder',
      title: 'Mforder',
      type: 'item',
      url: '/mforder',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mfparty',
      title: 'Mfparty',
      type: 'item',
      url: '/mfparty',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mfproductorder',
      title: 'Mfproductorder',
      type: 'item',
      url: '/mfproductorder',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mfproduct',
      title: 'Mfproduct',
      type: 'item',
      url: '/mfproduct',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mfproductorderstatus',
      title: 'Mfproductorderstatus',
      type: 'item',
      url: '/mfproductorderstatus',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'businessprocess',
      title: 'Businessprocess',
      type: 'item',
      url: '/businessprocess',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'task',
      title: 'Task',
      type: 'item',
      url: '/task',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'taskinstance',
      title: 'Taskinstance',
      type: 'item',
      url: '/taskinstance',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'tasktype',
      title: 'Tasktype',
      type: 'item',
      url: '/tasktype',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'factoryrole',
      title: 'Factoryrole',
      type: 'item',
      url: '/factoryrole',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'taskstatus',
      title: 'Taskstatus',
      type: 'item',
      url: '/taskstatus',
      icon: icons.ArrowForwardIosIcon
  }
  ]
};

export default oms;