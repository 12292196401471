import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function MfpartyListTableHeader(){
    const {mobileView} = useSelector((state)=>state.menu)
    return (
      
      <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "center", width: '100%', padding: 1, backgroundColor: 'primary.lighter' }}>
          <Grid item lg={4} xs={5} ><Typography variant="h5"> Title </Typography></Grid>
          <Grid item lg={2} xs={5} ><Typography variant="h5"> Mobile </Typography></Grid>
          {!mobileView?
          <>
          <Grid item lg={4} xs={3} ><Typography variant="h5"> email </Typography></Grid>
          </>:
          <></>}

      </Grid>
      
    )
}