import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MfpartyFilterCriteria from './MfpartyFilterCriteria'
import MfpartyListImpl from './MfpartyListImpl'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MfpartyAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMfpartyDashboardMfpartyList } from '../../store/slice/ManufSlice';

export default function MfpartyListView({setShowEdit,setMfpartyId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.mfpartyDashboard);   

    const [filteredMfpartyList, setFilteredMfpartyList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mfpartyDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mfpartyDashboard.mfpartyList.length>-1){
        setFilteredMfpartyList(mfpartyDashboard.mfpartyList);
        transformToCardFormat(mfpartyDashboard.mfpartyList, setCardList);
      }
    },[mfpartyDashboard.mfpartyList])

    useEffect(()=>{
          transformToCardFormat(filteredMfpartyList, setCardList);
      },[filteredMfpartyList])

    useEffect(()=>{
        if (filterCriteria.title==='' && filterCriteria.email==='' && filterCriteria.mobile==='' && filterCriteria.gst==='' ){
            setFilteredMfpartyList(mfpartyDashboard.mfpartyList);
            transformToCardFormat(mfpartyDashboard.mfpartyList, setCardList);

        } else {
            setFilteredMfpartyList(
                mfpartyDashboard.mfpartyList
                    .filter(mfparty=> mfparty.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))
.filter(mfparty=> mfparty.mobile.toString().toUpperCase().includes(filterCriteria.mobile.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mfpartyDashboard.filteredMfpartyList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MfpartyListImpl
                    setShowEdit={setShowEdit}
                    setMfpartyId = {setMfpartyId}
                    tableList={filteredMfpartyList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}