import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import MforderDashboard from '../MforderDashboard';

import { openDrawer } from './../../../store/slice/MenuSlice';
import { setMforderDashboardMforderList } from 'store/slice/ManufSlice';
import { setTitle,setOrderType } from 'store/slice/AppSlice';

export default function MforderListPage() {
    const dispatch = useDispatch();
    const {mforderList} = useSelector((state)=>state.manufslice);

    useEffect(() => {
        dispatch(setOrderType('IN-PROGRESS'))
        dispatch(setMforderDashboardMforderList(
                   mforderList.filter(orderObj=>orderObj.orderstatusid!==9)
               ))
       
    },[mforderList]);

    return (
        < MforderDashboard />
    )

}