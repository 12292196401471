import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box, Typography} from '@mui/material';
import FieldView2 from 'core/components/layout/FieldView2';


export default function MforderInfo(){
  const { taskinstanceObj} = useSelector((state)=>state.manufslice.taskinstanceDashboard);
  const {mforderList} = useSelector((state)=>state.manufslice)
  const [orderObj, setOrderObj] = useState({})
  useEffect(()=>{
    console.log("taskinstanceObj=>"+JSON.stringify(taskinstanceObj))
    
    setOrderObj(mforderList[mforderList.findIndex(mforder=>mforder.id===taskinstanceObj.orderid)])
    
  },[])
    return (
      <Box lg={12} sx={{ flexGrow: 1 }}>
        <Grid xs={12}><Typography color="primary.darker" variant="h5">Order Info</Typography></Grid>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: { xs: '20vh', lg: '58vh' } }}>
        
        <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{backgroundColor:'white'}}
      >
            
        <Grid item lg={2} xs={4}><FieldView2 color="white" label="Order #"  value={ orderObj.id!==null && orderObj.id!==undefined?orderObj.id:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={8}><FieldView2 color="white" label="Party Name"  value={ orderObj.partyname!==null && orderObj.partyname!==undefined?orderObj.partyname:'NOT KNONWN' }/></Grid>
        <Grid item lg={2} xs={4}><FieldView2 color="white" label="Order Date" value={ orderObj.orderdate!==null && orderObj.orderdate!==undefined?orderObj.orderdate.split('T')[0]:'NOT KNONWN' }/></Grid>
        <Grid item lg={2} xs={4}><FieldView2 color="white" label="Executive"  value={ orderObj.executive!==null && orderObj.executive!==undefined?orderObj.executive:'NOT KNONWN' }/></Grid>
        <Grid item lg={2} xs={4}><FieldView2 color="white" label="Status"  value={ orderObj.orderstatusname!==null && orderObj.orderstatusname!==undefined?orderObj.orderstatusname:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={12}><FieldView2 color="white" label="Remarks"  value={ orderObj.remarks!==null && orderObj.remarks!==undefined?orderObj.remarks:'NOT KNONWN' }/></Grid>
        
      </Grid>
      </Grid>
      </Box>
    )
}