import {useState, useEffect} from 'react';

import MfproductorderStatusEditForm from 'components/mfproductorder/components/MfproductorderStatusEditFormImpl';
export default function UpdateProductOrderStatusActionImpl(){

    return (
        <>
        <MfproductorderStatusEditForm/>
        </>
    )
}