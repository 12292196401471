import { transformDateValue } from 'core/js/FormUtils';
export const getMfproductorderView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let mfproductorderList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Product Name","fieldValue":transformDataValue(data.productname,"string"), "fieldType":"string"},
                            {"fieldLabel":"Product Order Status Name","fieldValue":transformDataValue(data.productorderstatusname,"string"), "fieldType":"string"},
                            {"fieldLabel":"Party Name","fieldValue":transformDataValue(data.partyname,"string"), "fieldType":"string"},
                            {"fieldLabel":"Unit","fieldValue":transformDataValue(data.unit,"list"), "fieldType":"list"},
              
              
            ],
            'dataList': [
                              {"fieldLabel":"Matter","fieldValue":transformDataValue(data.matter,"string"), "fieldType":"string"},
                            {"fieldLabel":"Qty","fieldValue":transformDataValue(data.qty,"number"), "fieldType":"number"},
                            {"fieldLabel":"Delivery Priority Name","fieldValue":transformDataValue(data.deliverypriorityname,"string"), "fieldType":"string"},
                            {"fieldLabel":"Expected Delivery","fieldValue":transformDataValue(data.expecteddelivery,"date"), "fieldType":"date"},
                            {"fieldLabel":"Stitching Status Name","fieldValue":transformDataValue(data.stitchingstatusname,"string"), "fieldType":"string"},
                            {"fieldLabel":"Digital Status Name","fieldValue":transformDataValue(data.digitalstatusname,"string"), "fieldType":"string"},
              
              ]
          }

          mfproductorderList.push(card);
  })
  return mfproductorderList;
}