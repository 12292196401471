import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip, Divider, MenuItem, Button, TextField, FormControl, InputLabel, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedMfpartyId, setSelectedMfproductId, setSelectedMforderId } from "store/slice/ManufSlice";

const MfOrderDashboardFilterCriteria = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [partyList, setPartyList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [orderId, setOrderId] = useState();
    const [partyId, setPartyId] = useState(0);
    const [productId, setProductId] = useState(0);
    
    const { mforderList,mfproductorderList, taskinstanceList } = useSelector((state) => state.manufslice);
    const [tastList, setTaskList] = useState([]);
    
    useEffect(() => {
        setPartyList(getUniquePartyList());
        setProductList(getUniqueProductList());

    }, [mfproductorderList, mforderList])
    const getUniquePartyList = () => {
        const uniqueOrdersMap = new Map();
        mforderList.forEach(order => {
            if (order.orderstatusid===6 && !uniqueOrdersMap.has(order.partyid)) {
                uniqueOrdersMap.set(order.partyid, order);
            }
        });
        const uniqueOrders = Array.from(uniqueOrdersMap.values());

        return uniqueOrders;
    }
    const getUniqueProductList = () => {
        const uniqueProductOrdersMap = new Map();
        mfproductorderList.forEach(productOrder => {
            if (!uniqueProductOrdersMap.has(productOrder.productid)) {
                uniqueProductOrdersMap.set(productOrder.productid, productOrder);
            }
        });
        return Array.from(uniqueProductOrdersMap.values());
    }
    
    const updateOrderId = (e) => {
        const { name, value } = e.target;
        console.log('OrderValue =>'+value)
        setOrderId(value);
        if (value.length>0)
            dispatch(setSelectedMforderId(value));
        else
            dispatch(setSelectedMforderId(-1));

    }
    const setSelectedPartyId = (e) =>{
        setPartyId(e.target.value);
        if (e.target.value!==0)
         dispatch(setSelectedMfpartyId(e.target.value))
        else
            dispatch(setSelectedMfpartyId(-1))
    }
    const setSelectedProductId = (e) =>{
        setProductId(e.target.value);
        if (e.target.value!==0)
            dispatch(setSelectedMfproductId(e.target.value));
        else
            dispatch(setSelectedMfproductId(-1));
    }
    return (
        <Grid container direction="row" sx={{padding:1}} justifyContent="space-between">
            <Grid item lg={2} xs={3}>
                <TextField size="Normal"
                    placeholder="orderId"
                    required={false}
                    label="Order Id"
                    name="orderId"
                    value={orderId}
                    onChange={updateOrderId}
                    sx={{ width: '100%' }}
                />
            </Grid>
            <Grid item lg={4} xs={4}>
                <FormControl sx={{ width: '100%' }} size="Normal">
                    <InputLabel id="demo-simple-select-autowidth-label">Party</InputLabel>
                    <Select
                        label="Party"
                        name="Party"
                        value={partyId}
                        onChange={setSelectedPartyId}

                    >
                        <MenuItem key={0} value={0}>All Parties</MenuItem>
                        {
                            partyList && partyList.map((partyObj, i) => <MenuItem key={i} value={partyObj.partyid}>{partyObj.partyname}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item lg={4} xs={4}>
                <FormControl sx={{ width: '100%' }} size="Normal">
                    <InputLabel id="demo-simple-select-autowidth-label">Product</InputLabel>
                    <Select
                        label="Product"
                        name="Product"
                        value={productId}
                        onChange={setSelectedProductId}

                    >
                        <MenuItem key={0} value={0}>ALL Products</MenuItem>
                            {
                                productList && productList.map((product, i) => <MenuItem key={i} value={product.productid}>{product.productname}</MenuItem>) 
                            } 
                    </Select>
                </FormControl>
            </Grid>
        </Grid>

    );
};

export default MfOrderDashboardFilterCriteria;
