import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import TaskstatusFilterCriteria from './TaskstatusFilterCriteria'
import TaskstatusList from './TaskstatusList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './TaskstatusAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setTaskstatusDashboardTaskstatusList } from '../../store/slice/ManufSlice';

export default function TaskstatusListView({setShowEdit,setTaskstatusId}){
    const {filterCriteria} = useSelector((state)=>state.manufslice.taskstatusDashboard);   

    const [filteredTaskstatusList, setFilteredTaskstatusList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { taskstatusDashboard } = useSelector((state) => state.manufslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (taskstatusDashboard.taskstatusList.length>-1){
        setFilteredTaskstatusList(taskstatusDashboard.taskstatusList);
        transformToCardFormat(taskstatusDashboard.taskstatusList, setCardList);
      }
    },[taskstatusDashboard.taskstatusList])

    useEffect(()=>{
          transformToCardFormat(filteredTaskstatusList, setCardList);
      },[filteredTaskstatusList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredTaskstatusList(taskstatusDashboard.taskstatusList);
            transformToCardFormat(taskstatusDashboard.taskstatusList, setCardList);

        } else {
            setFilteredTaskstatusList(
                taskstatusDashboard.taskstatusList
                    .filter(taskstatus=> taskstatus.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(taskstatusDashboard.filteredTaskstatusList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <TaskstatusList
                    setShowEdit={setShowEdit}
                    setTaskstatusId = {setTaskstatusId}
                    tableList={filteredTaskstatusList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}