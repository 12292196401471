import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  IconButton,
  Button,
  Box,
  CircularProgress
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import AddIcon from "@mui/icons-material/Add";
import {getListAPI as getPartyList} from '../../MfpartyAPI';
import { setMode } from "store/slice/OrderMgmtSlice";
import { refreshProductList } from "store/slice/OrderMgmtSlice";
import { setMfpartySelectPartyModal, setPartyObj } from "store/slice/OrderMgmtSlice";
import { updateOrderPartyAPI } from "core/components/party-mgmt/MfpartyAPI";
import { setOrderObj } from "store/slice/OrderMgmtSlice";

/* Integration Imports - START */
import { setSelectedMfpartyId, setMfpartyList,setMfpartyManagePartyModal } from "store/slice/ManufSlice";

/* Integration Imports - END */




const MfpartySelection = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [parties, setParties] = useState([]);
  const [loadApiInProgress, setLoadApiInProgress] = useState(false);
  const [savePartyApiInProgress, setSavePartyApiInProgress] = useState(false);
  const {mode,refreshPartyList,orderObj, partyObj} = useSelector((state)=>state.ordermgmtslice);
  const [localPartyObj, setLocalPartyObj] = useState(partyObj);
  
  const getPartyListSuccess = (apiResponse) =>{
    setLoadApiInProgress(false);
    dispatch(setMfpartyList(apiResponse))
  }
  
  useEffect(()=>{
          setLoadApiInProgress(true);
          getPartyList(setParties, getPartyListSuccess );
  },[refreshPartyList])

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const addParty = () => {
    dispatch(setSelectedMfpartyId(-1));
      dispatch(setMfpartyManagePartyModal(true));
  };

  const handlePartySelection = (partyObj) =>{
    setLocalPartyObj(partyObj);
  }
  
  

  const saveSelectedPartySuccess = (apiResponse) =>{
      dispatch(setPartyObj(localPartyObj))
      dispatch(setOrderObj(apiResponse.mforderInfo));
      setSavePartyApiInProgress(false);
      dispatch(setMfpartySelectPartyModal(false));
    }
    const saveSelectedPartyFailure = (errMsg) => {
      setSavePartyApiInProgress(false);
      alert("!!! ERROR : "+errMsg);
    }
    const saveSelectedParty = () =>{
      if (orderObj.id===undefined){
        dispatch(setMode(""))
        dispatch(setPartyObj(localPartyObj));
        return;
      } 
      
      setSavePartyApiInProgress(true);
      updateOrderPartyAPI({
        "orderId":orderObj.id,
        "partyId":localPartyObj.id
      },saveSelectedPartySuccess, saveSelectedPartyFailure)
    }
  return (
    <>
    {/* {mode==="ADD-PARTY"?<MfpartyEditFormImpl/>:<> */}
      <Grid container sx={{ padding: .5 }} lg={12}>
        {/* Search and Add Button */}
        <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
          <Grid item xs={8} lg={10}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search party..."
              value={searchQuery}
              onChange={handleSearch}
              size="small"
            />
          </Grid>
          <Grid item xs={4} lg={2} sx={{ paddingLeft: '.25rem', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={addParty}>
              Party
            </Button>
          </Grid>
        </Grid>

        {/* Party Selection List within restricted lg={12} container */}
        <Grid item lg={12} xs={12} sx={{ minHeight: "35vh", maxHeight: "35vh", backgroundColor: 'white', overflowY: "auto", border: "1px solid #ddd", }}>
          <Grid container sx={{ padding: 1 }}>
            {!loadApiInProgress && parties
              .filter((party) => party.title.toLowerCase().includes(searchQuery.toLowerCase()))
              .map((party) => (
                <Grid item xs={12} sm={4} md={4} lg={4} key={party.id}
                  onClick={() => handlePartySelection(party)}
                  sx={{
                    padding: 1,
                    //boxShadow: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                >
                  <Grid container justifyContent="space-between" sx={{
                    padding: 1, bgcolor: 'primary.lighter', borderRadius: 2, width: '100%'
                  }}>

                    <Typography variant="h6" color="primary.darker">
                      {party.title}
                    </Typography>
                    {localPartyObj?.id === party.id ? (
                      <CheckCircleIcon color="primary" />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                  </Grid>
                </Grid>
              ))}
              {loadApiInProgress?
                <Grid container justifyContent="center" alignItems="center" sx={{minHeight: "25vh"}}>
                <CircularProgress/>
                </Grid>
                :<></>
                
                }
          </Grid>
        </Grid>
      </Grid>
      {/* Selected Party Ticket */}
      <Grid item lg={12} xs={12} sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          <Grid container direction="column" sx={{ padding: .5, width: '100%' }}>
            <Grid>
              <Typography variant="h6" color="primary">Selected Party</Typography></Grid>
            <Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', paddingTop: .5 }}>
                <Grid item lg={10} xs={8}>

                  <Grid container sx={{ backgroundColor: "white", padding: 2 }}>
                    <Grid item>
                      <Typography variant="h6" color="primary.darker">
                        {localPartyObj.id ? localPartyObj.title : 'Party not selected yet'}

                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {savePartyApiInProgress?<><CircularProgress/></>:
                <Grid item xs={4} lg={2} sx={{ paddingLeft: '.25rem', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" color="primary" onClick={()=>saveSelectedParty()} disabled={!localPartyObj.id}>
                    Continue
                  </Button>
                </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
      </Grid>
      {/* </>} */}
    </>

  );
};

export default MfpartySelection;
