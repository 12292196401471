import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip, Divider, Button } from "@mui/material";
import Cutting from "../assets/cutter.png"; // Import local images
import Printing from "../assets/printer.png";
import Stitching from "../assets/sewing.png";
import Design from '../assets/drawing.png';
import RmCheck from '../assets/rmcheck.png';
import AccountCheck from '../assets/accountcheck.png';
import Packaging from '../assets/packaging.png';
import { useNavigate } from "react-router-dom";
import { setTaskinstanceList, setSelectedTaskinstanceId } from "store/slice/ManufSlice";
import { getTaskinstanceInfoByTaskidandproductidAPI } from "components/taskinstance/TaskinstanceAPI";

import { useSelector, useDispatch } from 'react-redux';
import TaskInstanceListByProductOrderId from "./TaskInstanceListByProductOrderId";

const ProductOrderListWithOrderId = ({orderId}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //const [taskInstanceList, setTaskInstanceList] = useState([]);
    const {mfproductorderList} = useSelector((state)=>state.manufslice);
    const [productOrderList, setProductOrderList] = useState([]);

    useEffect(()=>{
        //getTaskinstanceInfoByTaskidandproductidAPI(taskId, productId, setTaskInstanceList, ()=>{})
        setProductOrderList(
            mfproductorderList.filter(productOrder=>productOrder.orderid===orderId)
        )
    },[])

    return (
        <>
            <Grid item lg={12}>
                {   productOrderList.map(productOrderObj=> 
                    <>
                        <Grid >
                            <Grid container direction="row">
                                <Grid item lg={2} >{productOrderObj.productname}</Grid>
                                <Grid item lg={10}>
                                    <Grid container justifyContent="space-between">
                                        <TaskInstanceListByProductOrderId orderId={orderId} productId={productOrderObj.productid}/>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                            
                        </Grid>
                        
                    </>
                    )
                    
                }
           </Grid>
        </>
    );

};

export default ProductOrderListWithOrderId;
