import { Grid, Tooltip} from "@mui/material";

export function getTaskinstanceStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        title:{
            name:'title',
            label:'Title',
            value:'',
            error:false,
            errorMessage:'You must enter Title',
            required:true,
            fieldtype:'string'
        },
        orderid:{
            name:'orderid',
            label:'Order Id',
            value:'',
            error:false,
            errorMessage:'You must enter Order Id',
            required:true,
            fieldtype:'ID'
        },
        taskid:{
            name:'taskid',
            label:'Task Id',
            value:'',
            error:false,
            errorMessage:'You must enter Task Id',
            required:true,
            fieldtype:'bodynamiclist'
        },
        taskname:{
            name:'taskname',
            label:'Task Name',
            value:'',
            error:false,
            errorMessage:'You must enter Task Name',
            required:true,
            fieldtype:'string'
        },
        taskstatusid:{
            name:'taskstatusid',
            label:'Task Status Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        taskstatusname:{
            name:'taskstatusname',
            label:'Task Status Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        businessprocessid:{
            name:'businessprocessid',
            label:'Business Process Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        productid:{
            name:'productid',
            label:'Product Id',
            value:'',
            error:false,
            errorMessage:'You must enter Product Id',
            required:true,
            fieldtype:'ID'
        },
        productorderid:{
            name:'productorderid',
            label:'Product Order Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        productinstanceid:{
            name:'productinstanceid',
            label:'Product Instance Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'title', headerName: 'Title', width:100 },
        { field: 'orderid', headerName: 'Order Id', width:150 },
        { field: 'taskid', headerName: 'Task Id', width:150 },
        { field: 'taskname', headerName: 'Task Name', width:150 },
        { field: 'taskstatusid', headerName: 'Task Status Id', width:150 },
        { field: 'taskstatusname', headerName: 'Task Status Name', width:150 },
        { field: 'businessprocessid', headerName: 'Business Process Id', width:150 },
        { field: 'productid', headerName: 'Product Id', width:150 },
        { field: 'productorderid', headerName: 'Product Order Id', width:150 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['title']:{
          ...formValues['title'],
          value:data.title,
        },['orderid']:{
          ...formValues['orderid'],
          value:data.orderid,
        },['taskid']:{
          ...formValues['taskid'],
          value:data.taskid,
        },['taskname']:{
          ...formValues['taskname'],
          value:data.taskname,
        },['taskstatusid']:{
          ...formValues['taskstatusid'],
          value:data.taskstatusid,
        },['taskstatusname']:{
          ...formValues['taskstatusname'],
          value:data.taskstatusname,
        },['businessprocessid']:{
          ...formValues['businessprocessid'],
          value:data.businessprocessid,
        },['productid']:{
          ...formValues['productid'],
          value:data.productid,
        },['productorderid']:{
          ...formValues['productorderid'],
          value:data.productorderid,
        },['productinstanceid']:{
          ...formValues['productinstanceid'],
          value:data.productinstanceid,
        },
      })
}