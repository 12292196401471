import { createSlice } from '@reduxjs/toolkit';

export const AppSlice = createSlice({
    name: "app",
    initialState: {
        orderDashboard:{
          filteredTaskId:0
        },
        title:'',
        orderType:'IN-PROGRESS'
      },
    reducers: {
      setFilteredTaskId : (state, action) =>{
        state.orderDashboard.filteredTaskId=action.payload;
      },
      setTitle : (state, action) =>{
        state.title = action.payload;
      },
      setOrderType : (state, action) =>{
        state.orderType=action.payload;
      }
    }
});
export const { setFilteredTaskId,setTitle,setOrderType } = AppSlice.actions;
export default AppSlice.reducer;