import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip, Divider, Button } from "@mui/material";
import Cutting from "./assets/cutter.png"; // Import local images
import Printing from "./assets/printer.png";
import Stitching from "./assets/sewing.png";
import Design from './assets/drawing.png';
import RmCheck from './assets/rmcheck.png';
import AccountCheck from './assets/accountcheck.png';
import Packaging from './assets/packaging.png';
import Dispatch from './assets/dispatch.png';
import { useNavigate } from "react-router-dom";
import { setTaskinstanceList, setSelectedTaskinstanceId,setSelectedMfproductId, 
    setSelectedMfpartyId,setSelectedMforderId, setTaskinstanceDashboardTaskinstanceList } from "store/slice/ManufSlice";

import { useSelector, useDispatch } from 'react-redux';
import ProductOrderListWithOrderId from "./ProductOrderListWithOrderId";
import UpdateStatusActionModal from "components/taskinstance/action/UpdateStatus/UpdateStatusActionModal";
import MfOrderDashboardFilterCriteria from "./MfOrderDashboardFilterCriteria";
import { transformDateValue } from "core/js/FormUtils";

import { setFilteredTaskId } from "store/slice/AppSlice";

const MfOrderDashboardHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {mobileView} = useSelector((state)=>state.menu)
    const {taskinstanceList} = useSelector((state)=>state.manufslice);
    const {filteredTaskId} = useSelector((state)=>state.appslice.orderDashboard);
    

    const { mforderList } = useSelector((state) => state.manufslice.mforderDashboard);
    const [tastList, setTaskList] = useState([]);
    const { mfproductorderList } = useSelector((state) => state.manufslice);

    const {mfpartyObj} = useSelector((state)=>state.manufslice.mfpartyDashboard);
    const {mfproductObj} = useSelector((state)=>state.manufslice.mfproductDashboard);
    const [orderList, setOrderList] = useState([]);
    const {selectedId} = useSelector((state)=>state.manufslice.mforderDashboard);
    
    const getOrderListBasedOnPartyId = () =>{
        let filteredProductOrders = mfproductorderList.filter(productorder => {
            return productorder.partyname.trim()===mfpartyObj.title.trim()
        })
        const orderidList = filteredProductOrders.map(productOrder => productOrder.orderid);
        return orderidList;
    }
    const getOrderListBasedOnPartyIdAndProductId = () =>{
        let filteredProductOrders = mfproductorderList.filter(productorder => 
            productorder.partyname.trim()===mfpartyObj.title.trim() &&
            productorder.productid === mfproductObj.id
        )
        const orderidList = filteredProductOrders.map(productOrder => productOrder.orderid);
        return orderidList;
    }
    const getOrderListBasedOnSelectedProductId = () =>{
        let filteredProductOrders = mfproductorderList.filter(productorder => 
            productorder.productid === mfproductObj.id
        )
        const orderidList = filteredProductOrders.map(productOrder => productOrder.orderid);
        return orderidList;
    }
    
    useEffect(()=>{
        let productOrderList = [];
        if (selectedId!==-1){
            setOrderList(
                mforderList.filter(orderObj=> orderObj.orderstatusid===6 && String(orderObj.id).startsWith(String(selectedId)))
            )
        
        } else {

            if (mfpartyObj && mfpartyObj.id!==undefined && mfproductObj && mfproductObj.id!==undefined){
                productOrderList = getOrderListBasedOnPartyIdAndProductId();
                setOrderList(
                    mforderList.filter(orderObj=>orderObj.orderstatusid===6 && productOrderList.includes(orderObj.id))
                )
            }
            else if (mfpartyObj && mfpartyObj.id!==undefined){
                productOrderList = getOrderListBasedOnPartyId();
                setOrderList(
                    mforderList.filter(orderObj=>orderObj.orderstatusid===6 && productOrderList.includes(orderObj.id))
                )
            }
            else if (mfproductObj && mfproductObj.id!==undefined){
                const productOrderList = getOrderListBasedOnSelectedProductId(mfproductObj.id);
                setOrderList(
                    mforderList.filter(orderObj=> orderObj.orderstatusid===6 && productOrderList.includes(orderObj.id))
                )
            }
            else
                //setOrderList(mforderList.filter(orderObj=>orderObj.orderstatusid===6));
                setOrderList(mforderList);
        }

    },[mfproductObj,mfpartyObj,selectedId, mforderList])
    
    useEffect(() => {
        dispatch(setSelectedTaskinstanceId(-1))
        dispatch(setSelectedMfproductId(-1))
        dispatch(setSelectedMfpartyId(-1))
        dispatch(setSelectedMforderId(-1))
    }, [])
    useEffect(()=>{

    },[orderList])
    const sortOrderList = (taskId) =>{
        dispatch(setFilteredTaskId(taskId));
        getAllOrdersWhoseTaskInstanceWithGivenTaskIdAreInWIP(taskId, 1)
    }
    const getAllOrdersWhoseTaskInstanceWithGivenTaskIdAreInWIP = (taskId, taskStatusId) => {
        // Filter task instances with the given taskId
        const taskInstanceList = taskinstanceList.filter(taskInstance => taskInstance.taskid === taskId);
    
        // Separate WIP and non-WIP task instances
        const topTaskInstanceList = taskInstanceList.filter(taskInstance => taskInstance.taskstatusid === taskStatusId);
        const followTaskInstanceList = taskInstanceList.filter(taskInstance => taskInstance.taskstatusid !== taskStatusId);
    
        // Merge WIP first, then others
        const mergedTaskInstanceList = [...topTaskInstanceList, ...followTaskInstanceList];
        
        // Extract unique order IDs while maintaining order from mergedTaskInstanceList
        const orderidList = [...new Set(mergedTaskInstanceList.map(taskInstance => taskInstance.orderid))];
    
        // Filter mforderList based on extracted order IDs
        const filteredOrders = mforderList.filter(orderObj => orderidList.includes(orderObj.id));
    
        // Sort orders to maintain the sequence of `orderidList`
        const sortedFilteredOrders = orderidList.map(orderId =>
            filteredOrders.find(orderObj => orderObj.id === orderId)
        ).filter(Boolean); // Remove any undefined values
    
        // Set the final sorted order list
        setOrderList(sortedFilteredOrders);
    };
    
    const getTaskBorderColor = (taskId) =>{
        if (filteredTaskId===taskId)
            return '2px solid #003a8c'
        else
            return ''
    }
    const sortByOrderId = () =>{
        dispatch(setFilteredTaskId(0));
        const sortedOrders = [...mforderList].filter(mforder=>mforder.orderstatusid!==1).sort((a, b) => a.orderid - b.orderid);
        setOrderList(
            sortedOrders
        );
    }
    
    return (

        <Grid container directin="column" justifyContent="flex-start" >
            <Grid item lg={12} xs={12} sx={{backgroundColor:'white',}}>
                <MfOrderDashboardFilterCriteria/>
            </Grid>
            <Grid item lg={12} xs={12} sx={{paddingTop:1}}>
                <Grid container direction="row" justifyContent="center" alignItems="flex-end">
                    {mobileView?<></>:<Grid lg={1} xs={1} item sx={{paddingLeft:.5, cursor:'pointer', backgroundColor:filteredTaskId===0?'secondary.400': 'secondary.200'}} onClick={()=>sortByOrderId()}><Typography variant="h5" color="primary">Order <br/>Info</Typography></Grid>}
                    <Grid lg={11} xs={12} item >
                        <Grid container justifyContent="space-between" alignItems="center" sx={{paddingLeft:.5, paddingRight:.5}}>
                            {mobileView?<></>:<Grid item lg={2} xs={2}  sx={{paddingLeft:.5, paddingRight:.5}}><Typography variant="h5" color="primary" sx={{backgroundColor:filteredTaskId===10?'secondary.400': 'secondary.200'}}>Product</Typography></Grid>}
                            <Grid lg={10} xs={12} container justifyContent="space-between">

                                <Grid lg={1} xs={1} item sx={{  backgroundColor: filteredTaskId===1?'secondary.400': 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" onClick={()=>sortOrderList(1)} sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25}}>
                                            <Tooltip title="RM CHECK" arrow>
                                                <Avatar src={RmCheck} sx={{ width: 45, height: 45, padding: 0.5,border:getTaskBorderColor(1) }} />
                                            </Tooltip>
                                        </Grid>
                                        {mobileView?<></>:<Typography variant="h6" align="center">RM</Typography>}
                                        </Grid>
                                </Grid>

                                <Grid lg={1} xs={1} item sx={{ backgroundColor: filteredTaskId===2?'secondary.400': 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" onClick={()=>sortOrderList(2)} sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Design Approval" arrow>
                                                <Avatar src={Design} sx={{ width: 45, height: 45,  padding: 0.5,border:getTaskBorderColor(2) }} />
                                            </Tooltip>
                                        </Grid>
                                        {mobileView?<></>:<Typography variant="h6" align="center">Design</Typography>}
                                        </Grid>
                                </Grid>

                                <Grid lg={1} xs={1} item sx={{  backgroundColor: filteredTaskId===4?'secondary.400': 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" onClick={()=>sortOrderList(4)} sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Printing" arrow>
                                                <Avatar src={Printing} sx={{ width: 45, height: 45,  padding: 0.5,border:getTaskBorderColor(4) }} />
                                            </Tooltip>
                                        </Grid>{mobileView?<></>:<Typography variant="h6" align="center">Printing</Typography>}</Grid>
                                </Grid>
                                
                                <Grid lg={1} xs={1} item sx={{  backgroundColor: filteredTaskId===3?'secondary.400': 'secondary.200'}}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" onClick={()=>sortOrderList(3)} sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Cutting" arrow>
                                                <Avatar src={Cutting} sx={{ width: 45, height: 45, padding: 0.5,border:getTaskBorderColor(3) }} />
                                            </Tooltip>
                                        </Grid>{mobileView?<></>:<Typography variant="h6" align="center">Cutting</Typography>}</Grid>
                                </Grid>

                                

                                <Grid lg={1} xs={1} item sx={{ backgroundColor: filteredTaskId===5?'secondary.400': 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" onClick={()=>sortOrderList(5)} sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Stitching" arrow>
                                                <Avatar src={Stitching} sx={{ width: 45, height: 45,  padding: 0.5,border:getTaskBorderColor(5) }} />
                                            </Tooltip>
                                        </Grid>{mobileView?<></>:<Typography variant="h6" align="center">Stiching</Typography>}</Grid>
                                </Grid>

                                {/* <Grid lg={1} xs={1} item sx={{ backgroundColor: filteredTaskId===6?'secondary.400': 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" onClick={()=>sortOrderList(6)} sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Finance Check" arrow>
                                                <Avatar src={AccountCheck} sx={{width: 45, height: 45,  padding: 0.5,border:getTaskBorderColor(6) }} />
                                            </Tooltip>
                                        </Grid>{mobileView?<></>:<Typography variant="h6" align="center">Account</Typography>}</Grid>
                                </Grid> */}

                                <Grid lg={1} xs={1} item sx={{  backgroundColor: filteredTaskId===7?'secondary.400': 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" onClick={()=>sortOrderList(7)} sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Packaging" arrow>
                                                <Avatar src={Packaging} sx={{ width: 45, height: 45,  padding: 0.5,border:getTaskBorderColor(7) }} />
                                            </Tooltip>
                                        </Grid>{mobileView?<></>:<Typography variant="h6" align="center">Packaging</Typography>}</Grid>
                                </Grid>
                                <Grid lg={1} xs={1} item sx={{ backgroundColor: filteredTaskId===6?'secondary.400': 'secondary.200' }}>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" onClick={()=>sortOrderList(6)} sx={{ backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', mb: .25, }}>
                                            <Tooltip title="Finance Check" arrow>
                                                <Avatar src={Dispatch} sx={{width: 45, height: 45,  padding: 0.5,border:getTaskBorderColor(6) }} />
                                            </Tooltip>
                                        </Grid>{mobileView?<></>:<Typography variant="h6" align="center">Dispatch</Typography>}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
            <Grid item lg={12} xs={12} sx={{ paddingTop: .5 }}>
                <Divider sx={{ backgroundColor: 'primary.light', height: '2px' }} />
            </Grid>

            <Box lg={12} xs={12} sx={{ flexGrow: 1, }}>
                <Grid item sx={{ overflowY: "scroll", maxHeight: '65vh' }}>
                    <Grid item xs={12} lg={12} sx={{ backgroundColor: 'white', }}>
                        {
                            orderList && orderList.map((orderObj, index) => {
                                return (
                                    <Grid key={orderObj.id} sx={{ height: '100%', borderBottom:'1px solid #d9d9d9', paddingTop:1}}>
                                        <Grid container alignItems="center" direction="row" justifyContent="space-between" sx={{ backgroundColor: 'secondary.lighter',  }}>
                                            <Grid item lg={1} xs={12} alignItems="center" sx={{paddingLeft:.5 }}>
                                                <Grid container justifyContent="space-between" direction="row" sx={{ width: '100%', }}>
                                                <Grid item >
                                                        <Typography variant="h5" sx={{ fontWeight: 'light', fontSize: 14 }} color="primary.darker">Order Date</Typography>
                                                        <Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>{transformDateValue(orderObj.orderdate)}</Typography>
                                                    </Grid>
                                                    <Grid item >
                                                        <Typography variant="h5" sx={{ fontWeight: 'light', fontSize: 14 }} color="primary.darker">Order Id</Typography>
                                                        <Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>{orderObj.id}</Typography>
                                                    </Grid>
                                                    <Grid item >
                                                        <Typography variant="h5" sx={{ fontWeight: 'light', fontSize: 14 }} color="primary.darker">Party</Typography>
                                                        <Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>{orderObj.partyname}</Typography>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid item lg={11} xs={12} container direction="row" alignItems="center" sx={{ backgroundColor: 'white', minHeight: '80px'}}>
                                                <Grid lg={12} xs={12} container justifyContent="space-between" alignItems="center" sx={{ height: '100%' }} >
                                                    <ProductOrderListWithOrderId orderId={orderObj.id} index={index}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                    </Grid>
                </Grid>
            </Box>
            <UpdateStatusActionModal/>
        </Grid>


    );
};

export default MfOrderDashboardHeader;
