import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MforderstatusEditForm from './MforderstatusEditForm';
import MforderstatusList from './MforderstatusList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MforderstatusListView from './MforderstatusListView'


export default function MforderstatusDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.mforderstatusDashboard);
  const [ mforderstatusId, setMforderstatusId] = React.useState('');
  const [reloadMforderstatusList, setReloadMforderstatusList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MforderstatusEditForm 
            setReloadMforderstatusList={setReloadMforderstatusList}
            mforderstatusId={mforderstatusId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MforderstatusListView 
                      setMforderstatusId = {setMforderstatusId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
