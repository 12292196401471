import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MfproductorderEditFormImpl from './MfproductorderEditFormImpl';
import MfproductorderList from './MfproductorderList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MfproductorderListView from './MfproductorderListView'


export default function MfproductorderDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.mfproductorderDashboard);
  const [ mfproductorderId, setMfproductorderId] = React.useState('');
  const [reloadMfproductorderList, setReloadMfproductorderList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MfproductorderEditFormImpl 
            setReloadMfproductorderList={setReloadMfproductorderList}
            mfproductorderId={mfproductorderId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MfproductorderListView 
                      setMfproductorderId = {setMfproductorderId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
