import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MfdigitalstatusEditForm from './MfdigitalstatusEditForm';
import MfdigitalstatusList from './MfdigitalstatusList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MfdigitalstatusListView from './MfdigitalstatusListView'


export default function MfdigitalstatusDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.mfdigitalstatusDashboard);
  const [ mfdigitalstatusId, setMfdigitalstatusId] = React.useState('');
  const [reloadMfdigitalstatusList, setReloadMfdigitalstatusList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MfdigitalstatusEditForm 
            setReloadMfdigitalstatusList={setReloadMfdigitalstatusList}
            mfdigitalstatusId={mfdigitalstatusId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MfdigitalstatusListView 
                      setMfdigitalstatusId = {setMfdigitalstatusId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
