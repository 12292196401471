import {useState, useEffect} from 'react';
import {Grid, Button, CircularProgress, Box, Typography,Divider} from '@mui/material';
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from 'core/components/ErrorMessage';
import SuccessMessage from 'core/components/SuccessMessage';
import { addInfo } from 'store/slice/HeaderSlice';
import { approveOrderAPI } from 'api/OmsFactoryopsWebServices';
import { setMforderOrderToFactoryModal,updateMforderObj,updateMfproductObj, setMforderManageMfOrderModal } from 'store/slice/ManufSlice';
import { addTaskinstanceObj } from 'store/slice/ManufSlice';


export default function OrderToFactoryActionImpl(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [successMsg, setSuccessMsg] = useState('');
    const [apiInProgress, setApiInProgress] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgType, setMsgType] = useState(1);
    const {mforderObj} = useSelector((state)=>state.manufslice.mforderDashboard);

    useEffect(()=>{
        
    },[mforderObj]);

    const updateProducts = (productList)=>{
        productList.map(productObj => dispatch(
            updateMfproductObj(productObj)
        ))
    }
    const addIntoTaskInstanceList = (taskInstanceList)=>{
        taskInstanceList.map(taskInstanceObj => dispatch(
            addTaskinstanceObj(taskInstanceObj))
        )
    }
    const orderToProductionFailure = (apiErrMsg)=>{
        setApiInProgress(false);
        setMsg(apiErrMsg);
        setMsgType(0);
    }
    const handleSendOrderToProduction = () =>{
        setMsg('')
        setApiInProgress(true);
        approveOrderAPI({   
            orderid:mforderObj.id
            }, orderToProductionSuccess, orderToProductionFailure
        )
    }
    const orderToProductionSuccess = (response)=>{
        dispatch(addInfo({
            id:'001',
            value:response.mforderInfo.id
        }))
        
        dispatch(updateMforderObj(response.mforderInfo));
        updateProducts(response.mfproductorderInfoList.list);
        //addIntoTaskInstanceList(response.taskinstanceInfoList.list);
        dispatch(setMforderManageMfOrderModal(false));

        setApiInProgress(false);
        setMsg("Order '"+response.mforderInfo.id+"' is submitted to Production !!!");
        setMsgType(1);
    }

    const closeDeleteModal = () =>{
        dispatch(setMforderOrderToFactoryModal(false));
    }
    return (
        <>
         <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box padding={2} sx={{backgroundColor: 'white', borderRadius: 4, boxShadow: 3, width:"90%"}}>
                    <Grid lg={12} xs={12} container direction="column">
                        <Grid container display="flex" direction="column" justifyContent="center" alignItems="center" >
                            <Grid item>
                                <Typography variant="h5" component="h2" color="primary.darker">
                                    CONFIRMATION
                                </Typography>
                            </Grid>
                            <Divider sx={{width:'100%'}}/>
                            <Grid item>
                            <Typography id="modal-modal-description" sx={{ mt: 10 }}>
                                Are you sure to submit Order #{mforderObj.id} to Production?
                            </Typography>
                            </Grid>
                        </Grid>
                        <Box height="50px" sx={{ width: '100%' }}></Box>
                        <Divider />
                        <Grid padding={2}>
                            {
                                apiInProgress ?
                                    <Grid container display="flex" direction="row" justifyContent="center">
                                        <CircularProgress padding={2} />
                                    </Grid>
                                    :
                                    msg!==''
                                        ?<Grid sx={{textAlign:'center' }}>
                                            <Typography color={msgType?'green':'red'} >{msg}</Typography>
                                        </Grid>
                                        :
                                        <Grid container display="flex" direction="row" justifyContent="space-between">
                                            <Button variant="contained" onClick={()=>handleSendOrderToProduction()}> Yes</Button>
                                            <Button variant="contained" onClick={() => closeDeleteModal()}> No</Button>
                                        </Grid>
                            }
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </Box>
        </>
    )
}