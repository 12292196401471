import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Tooltip } from "@mui/material";
import Cutting from "../assets/cutter.png"; // Import local images
import Printing from "../assets/printer.png";
import Stitching from "../assets/sewing.png";
import Design from '../assets/drawing.png';
import RmCheck from '../assets/rmcheck.png';
import AccountCheck from '../assets/accountcheck.png';
import Packaging from '../assets/packaging.png';
import { useSelector, useDispatch } from 'react-redux';

import { getTaskinstanceInfoByOrderidandproductidAPI } from "components/taskinstance/TaskinstanceAPI";
import { setTaskinstanceUpdateStatusModal, updateTaskinstanceObj } from "store/slice/ManufSlice"; 
import UpdateStatusActionModal from '../../taskinstance/action/UpdateStatus/UpdateStatusActionModal';
import { setSelectedMfproductorderId } from "store/slice/ManufSlice";
import { setSelectedTaskinstanceId } from "store/slice/ManufSlice";
import { setTaskinstanceList,addTaskinstanceObj } from "store/slice/ManufSlice";


export default function MfproductOrderProgress({ productOrderObj }) {
    const dispatch = useDispatch();
    const [taskInstanceList, setTaskInstanceList] = useState([]);
    const { mforderObj } = useSelector((state) => state.manufslice.mforderDashboard);
    const {taskinstanceObj} = useSelector((state)=>state.manufslice.taskinstanceDashboard);

    const injectTaskInstanceList = (taskInstanceListFromAPI) =>{
        taskInstanceListFromAPI.forEach(taskInstance=>{
            dispatch(addTaskinstanceObj(taskInstance));
        })
        dispatch(setSelectedTaskinstanceId(-1));
    }
    useEffect(() => {
        getTaskinstanceInfoByOrderidandproductidAPI(productOrderObj.productid, mforderObj.id, setTaskInstanceList, injectTaskInstanceList)
    }, [])

    useEffect(()=>{ // refresh list once task instance status is updated
        if (taskinstanceObj.id!==undefined){
            getTaskinstanceInfoByOrderidandproductidAPI(productOrderObj.productid, mforderObj.id, setTaskInstanceList, () => { })
            dispatch(updateTaskinstanceObj(taskinstanceObj))
        }
    },[taskinstanceObj])

    const openStatusUpdate = (taskInstanceId) =>{
        dispatch(setSelectedTaskinstanceId(taskInstanceId))
        dispatch(setSelectedMfproductorderId(productOrderObj.id));
        dispatch(setTaskinstanceUpdateStatusModal(true));
    }

    const getTaskImage = (taskObj) => {
        if (taskObj.taskname === 'Cutting') return Cutting;
        if (taskObj.taskname === 'Printing') return Printing;
        if (taskObj.taskname === 'Stitching') return Stitching;
        if (taskObj.taskname === 'Design Approval') return Design;
        if (taskObj.taskname === 'Finance Check') return AccountCheck;
        if (taskObj.taskname === 'RM Check') return RmCheck;
        if (taskObj.taskname === 'Packaging') return Packaging;

    }
    return (

        <Grid container direction="row" justifyContent="space-between">
            <Grid item sx={{ padding: 0.5, backgroundColor:'secondary.100',width:'30%', }}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                    <Grid item sx={{ padding: 0.5,  borderRadius: 5 }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            {taskInstanceList
                                .filter((taskInstance) => taskInstance.taskstatusname === 'TODO')
                                .map((taskInstance) => (
                                    <Grid item sx={{ padding: 0.5 }}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                width: '50px',
                                                height: '50px',  // ✅ Ensure height for centering
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                display: "flex",
                                                cursor:'pointer',
                                                border:taskinstanceObj && taskInstance.id===taskinstanceObj.id?'2px solid #0050b3':''
                                            }}
                                            onClick={()=>openStatusUpdate(taskInstance.id)}
                                        >
                                            <Tooltip title={taskInstance.taskname} arrow>
                                                <Avatar
                                                    src={getTaskImage(taskInstance)}
                                                    sx={{ width: 45, height: 45, padding: 0.5 }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item sx={{ padding: 0.5, backgroundColor:'secondary.100',width:'30%'}}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                    <Grid item sx={{ padding: 0.5,  borderRadius: 5 }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            {taskInstanceList
                                .filter((taskInstance) => taskInstance.taskstatusname === 'WIP')
                                .map((taskInstance) => (
                                    <Grid item sx={{ padding: 0.5,  }}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                width: '50px',
                                                height: '50px',  // ✅ Ensure height for centering
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                display: "flex",
                                                cursor:'pointer',
                                                border:taskinstanceObj &&  taskInstance.id===taskinstanceObj.id?'2px solid #0050b3':''
                                            }}
                                            onClick={()=>openStatusUpdate(taskInstance.id)}
                                        >
                                            <Tooltip title={taskInstance.taskname} arrow>
                                                <Avatar
                                                    src={getTaskImage(taskInstance)}
                                                    sx={{ width: 45, height: 45, padding: 0.5 }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item sx={{ padding: 0.5, backgroundColor:'secondary.100',width:'30%'}}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                    <Grid item sx={{ padding: 0.5,  borderRadius: 5 }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            {taskInstanceList
                                .filter((taskInstance) => taskInstance.taskstatusname === 'DONE')
                                .map((taskInstance) => (
                                    <Grid item sx={{ padding: 0.5 }}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                width: '50px',
                                                height: '50px',  // ✅ Ensure height for centering
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                display: "flex",
                                                cursor:'pointer',
                                                border:taskinstanceObj && taskInstance.id===taskinstanceObj.id?'2px solid #0050b3':''
                                            }}
                                            onClick={()=>openStatusUpdate(taskInstance.id)}
                                        >
                                            <Tooltip title={taskInstance.taskname} arrow>
                                                <Avatar
                                                    src={getTaskImage(taskInstance)}
                                                    sx={{ width: 45, height: 45, padding: 0.5 }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>



        <UpdateStatusActionModal/>
        </Grid>

    );
};
