import React, { useState, useEffect } from 'react'
import { useSearchParams} from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import DeleteNotification from '../../core/components/DeleteNotification';
import {CoreContext} from "../../core/context/CoreContext";
import PageWaiting from '../../core/components/PageWaiting';
import {isMobile} from 'react-device-detect';

import {getListAPI, deleteAPI} from './MfproductorderAPI';
import {getColumns} from './MfproductorderStruct';
import ListCardView from '../../core/components/card/ListCardView';
import ListTableView from '../../core/components/card/ListTableView';

import {getMfproductorderView} from './MfproductorderView';
import CardListLayout from '../../core/components/card/layout/CardListLayout';
import { useSelector, useDispatch } from 'react-redux';
import {setViewParams} from '../../store/slice/ChoiceSelectionSlice';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';

import {setFilter} from '../../store/slice/ConfSlice';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import MyPageContainer from '../../core/template/MyPageContainer';
import { setMfproductorderAddMfProductOrderModal, deleteMfproductorderObj  ,setSelectedMfproductorderId,setSelectedActionId , setShowEditForMfproductorder,setMfproductorderUpdateProductOrderStatusModal} from 'store/slice/ManufSlice';
import AddMfProductOrderActionModal from './action/AddMfProductOrder/AddMfProductOrderActionModal';
import MfproductorderListTable from './components/MfproductorderListTable';
import { useNavigate } from "react-router-dom";
import { setClickedBo,setActionClicked } from 'store/slice/BreadCrumSlice';

import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';

import UpdateProductOrderStatusActionModal from './action/updateProductOrderStatus/UpdateProductOrderStatusActionModal';

import { transformDateValue } from 'core/js/FormUtils';

export default function MfproductorderListImpl( {tableList, cardList, setShowEdit, setMfproductorderId}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotification, setShowLoading, showloading, lastURL } = React.useContext(CoreContext);
  const columns = getColumns(editCallBack, initDeleteCallBack);
  const [view, setView] = useState(1);
  const { showfilter } = useSelector((state) => state.confslice)

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const {selectedId, mfproductorderObj} = useSelector((state)=>state.manufslice.mfproductorderDashboard);
  const { mfproductorderList } = useSelector((state) => state.manufslice.mfproductorderDashboard);

  useEffect(() => {
  }, [mfproductorderList, mfproductorderObj])
  
  function initDeleteCallBack(id){
    dispatch(setSelectedMfproductorderId(id));
    setShowDeleteAlert(true);
  }
  function deleteCallBack(obj){
    dispatch(deleteMfproductorderObj(obj.id));
    setNotification('Record deleted successfully !!!')
  }
  function deleteRecord(){
    deleteAPI(selectedId,deleteCallBack);
    setShowDeleteAlert(false);
  }

  function editCallBack(id){
    dispatch(setSelectedMfproductorderId(id));
   // dispatch(setShowEditForMfproductorder(true));
    dispatch(setMfproductorderAddMfProductOrderModal(true));
  }
  
  const createMfproductorder=()=>{
    dispatch(setSelectedMfproductorderId(-1));
    //dispatch(setShowEditForMfproductorder(true));
    dispatch(setMfproductorderAddMfProductOrderModal(true));
  }

  const updateProductOrderStatusCallBack = (id) =>{
    dispatch(setSelectedMfproductorderId(id));
    dispatch(setMfproductorderUpdateProductOrderStatusModal(true));
  }

const getDataWithDateTransformation = (data) =>{
    let newData = {
      ...data,
      'expand':false,
		'expecteddelivery': transformDateValue(data.expecteddelivery)
    }
    return newData;
  }
  const mfproductorderTransformation = (tableList) =>{
    let newDataList=[]
    tableList.map((data)=>{ 
      newDataList.push(getDataWithDateTransformation(data));
     })
    return newDataList;
  }

  const payload = {
    mfproductorderList:mfproductorderList,
    cardList:getMfproductorderView(tableList),
        actionList: [
          {title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>},
          {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>},

          {id:36, title:'Update Product Order Status', callBackMethod:updateProductOrderStatusCallBack, icon:<DynamicFeedIcon/>},
          
        ],
        dialogueActionList: [
          
        ],
    tableView:{
      tableList:mfproductorderTransformation(tableList),
      mfproductorderList:mfproductorderList,
      actionList:[
        {title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>},
        {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>},
        // {id:36, title:'Update Product Order Status', callBackMethod:updateProductOrderStatusCallBack, icon:<DynamicFeedIcon/>},
        
      ]
    }
  }
  
  const getPayload = ()=>{
    return payload;
  }

  useEffect(()=>{
    if (document.body.offsetWidth<500)  setView(0);
    dispatch(setViewParams({
      colsCount:{xs:12, sm:4, md:4, lg:4},
      modal:{
            
      }
      }))
  },[])

  return (
   <>
   {showloading?<PageWaiting/>:
      <Grid container direction="column" paddingTop={2}>
              <Grid item>
                  <Grid container direction="row" justifyContent={'space-between'} alignContent={'center'}>
                      <Grid item>
                        <Typography variant='h5'>Product List</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent={'flex-end'} spacing={1}>
                        {/* {showfilter?
                            <Grid item>
                              <Tooltip title="Hide filter" sx={{cursor: "pointer"}}>
                                <FilterAltOffIcon onClick={()=>{dispatch(setFilter(false))}}/>
                              </Tooltip>
                            </Grid>
                            :
                            <Grid item>
                              <Tooltip title="Show filter" sx={{cursor: "pointer"}}>
                                <FilterAltIcon onClick={()=>{dispatch(setFilter(true))}}/>
                              </Tooltip>
                            </Grid>
                            }
                            {view===1?
                            <Grid item>
                              <Tooltip title="Grid View" sx={{cursor: "pointer"}}>
                                <ViewWeekIcon onClick={()=>{setView(0)}}/>
                              </Tooltip>
                            </Grid>
                            :
                            <Grid item>
                              <Tooltip title="Table View" sx={{cursor: "pointer"}}>
                                <CalendarViewMonthIcon onClick={()=>{setView(1)}}/>
                              </Tooltip>
                            </Grid>
                            } */}
                            <Grid item>
                              <Tooltip title="Add Mfproductorder" sx={{cursor: "pointer"}}>
                                <AddIcon onClick={()=>{createMfproductorder()}}/>
                              </Tooltip>
                            </Grid>
                          </Grid>
                      </Grid>
                      
                  </Grid>
              </Grid>
              <Grid item>
                  < MfproductorderListTable 
                    payload={getPayload().tableView}
                    />
              </Grid>     
      </Grid>
   }
    { showDeleteAlert?<DeleteNotification 
            id={selectedId}
            width="50%"
            setShowDeleteAlert={setShowDeleteAlert}
            deleteRecord = {deleteRecord}/>: ''
    }

<UpdateProductOrderStatusActionModal/>
<AddMfProductOrderActionModal/>
    </>
);


}