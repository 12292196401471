import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import BusinessprocessEditFormImpl from './BusinessprocessEditFormImpl';
import BusinessprocessList from './BusinessprocessList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import BusinessprocessListView from './BusinessprocessListView'


export default function BusinessprocessDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.manufslice.businessprocessDashboard);
  const [ businessprocessId, setBusinessprocessId] = React.useState('');
  const [reloadBusinessprocessList, setReloadBusinessprocessList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <BusinessprocessEditFormImpl 
            setReloadBusinessprocessList={setReloadBusinessprocessList}
            businessprocessId={businessprocessId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <BusinessprocessListView 
                      setBusinessprocessId = {setBusinessprocessId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
