import React, { useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import { setMforderPrintJobTicketModal } from "store/slice/ManufSlice";
import { transformDateValue } from "core/js/FormUtils";

const PdfComponent = ({ order,productOrderList }) => {
  const contentRef = useRef();
  const [pdfUrl, setPdfUrl] = useState(null);
  const {mobileView} = useSelector((state)=>state.menu)
  const dispatch = useDispatch();

  useEffect(()=>{
    generatePDF();
  },[])
  const generatePDF = async () => {
    const element = contentRef.current;
    const canvas = await html2canvas(element, { scale: 2 });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = 210;
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    // Convert PDF to Blob URL for preview
    const pdfBlob = pdf.output("blob");
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);
    setPdfUrl(pdfBlobUrl);
    if (mobileView){
        window.open(pdfBlobUrl, "_blank");
        dispatch(setMforderPrintJobTicketModal(false));
    }
        
  };

  const getStockDependentComponent = (product) =>{
    if (product.stockready){
      if (product.withprinting){
        return <td style={styles.td}>{product.printingtype?'Digital':'Screen'}</td>
      }
    } else {
      return <>
      <td style={styles.td}>{product.printingtype?'Digital':'Screen'}</td>
      <td style={styles.td}>{product.stitchingtype?'Simple':'Technical'}</td>
      </>
    }
  }
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", }}>
      {/* PDF Content to Convert */}
      {!pdfUrl?
      <div
        ref={contentRef}
        style={{
          width: "100%",
          minHeight: "50mm",
          padding: "5mm",
          fontSize: "12px",
          background: "#fff",
          border: "1px solid #ddd",
        }}
      >
        {/* Header Section */}
        <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Order Details</h2>
        <table style={{ width: "100%", marginBottom: "20px", borderCollapse: "collapse" }}>
          <tbody>
            <tr>
              <td><strong>Order ID:</strong> {order.id}</td>
              <td><strong>Order Date:</strong> {transformDateValue(order.orderdate)}</td>
              <td><strong>Party Name:</strong> {order.partyname}</td>
            </tr>
            <tr>
              <td><strong>Expected Delivery Date:</strong> {order.expecteddeliverydate?transformDateValue(order.expecteddeliverydate):'None'}</td>
              <td colSpan={2}><strong>Remarks:</strong> {order.remarks}</td>
            </tr>
          </tbody>
        </table>

        {/* Product Table */}
        <table style={{ width: "100%", borderCollapse: "collapse"}}>
          <thead>
            <tr style={{ background: "#f0f0f0" }}>
              {/* <th style={styles.th}>Product ID</th> */}
              <th style={styles.th}>Product</th>
              <th style={styles.th}>Quantity</th>
              <th style={styles.th}>Matter</th>
             
              <th style={styles.th}>Printing</th>
              <th style={styles.th}>Stictching</th>
              <th style={styles.th} colSpan={2}>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {productOrderList.map((product, index) => (
              <tr key={index}>
                {/* <td style={styles.td}>{product.productId}</td> */}
                <td style={styles.td}>{product.productname}</td>
                <td style={styles.td}>{product.qty}</td>
                <td style={styles.td}>{product.matter}</td>
                
                {getStockDependentComponent(product)}
                <td style={styles.td} colSpan={2}>{product.remarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>:<></>}

      {/* PDF Viewer (iframe) */}
      {pdfUrl && !mobileView && (
        <>
          <iframe
            id="pdfFrame"
            src={pdfUrl}
            style={{ width: "100%", height: "68vh", border: "1px solid #ddd" }}
            title="PDF Preview"
          />
          {/* <button
            onClick={handlePrint}
            style={{ marginTop: "10px", padding: "10px 20px", background: "#28a745", color: "#fff", border: "none", cursor: "pointer" }}
          >
            Print PDF
          </button> */}
        </>
      )}
    </div>
  );
};

// CSS Styles for Table
const styles = {
  th: {
    padding: "8px",
    border: "1px solid #000",
    textAlign: "left",
  },
  td: {
    padding: "5px",
    borderBottom: "1px solid #000",
  },
};

export default PdfComponent;
