import { useState, useEffect } from "react";

import { getUserAPI } from "api/IdentityServiceAPI";
import { setAppuserInfo, setuserprofileInfo, setLoggedIn, setAppId, setProfile,setInitialAPIload } from "store/slice/HeaderSlice";


import { getListAPI as getMfprintingstatusList } from 'components/mfprintingstatus/MfprintingstatusAPI';
import { getListAPI as getMfstitchingstatusList } from 'components/mfstitchingstatus/MfstitchingstatusAPI';
import { getListAPI as getMfdigitalstatusList } from 'components/mfdigitalstatus/MfdigitalstatusAPI';
import { getListAPI as getMfdeliverypriorityList } from 'components/mfdeliverypriority/MfdeliverypriorityAPI';
import { getListAPI as getMfprintingtypeList } from 'components/mfprintingtype/MfprintingtypeAPI';
import { getListAPI as getMforderstatusList } from 'components/mforderstatus/MforderstatusAPI';
import { getListAPI as getMforderList } from 'components/mforder/MforderAPI';
import { getListAPI as getMfpartyList } from 'components/mfparty/MfpartyAPI';
import { getListAPI as getMfproductorderList } from 'components/mfproductorder/MfproductorderAPI';
import { getListAPI as getMfproductList } from 'components/mfproduct/MfproductAPI';
import { getListAPI as getMfproductorderstatusList } from 'components/mfproductorderstatus/MfproductorderstatusAPI';
import { getListAPI as getBusinessprocessList } from 'components/businessprocess/BusinessprocessAPI';
import { getListAPI as getTaskList } from 'components/task/TaskAPI';
import { getListAPI as getTaskinstanceList } from 'components/taskinstance/TaskinstanceAPI';
import { getListAPI as getTasktypeList } from 'components/tasktype/TasktypeAPI';
import { getListAPI as getFactoryroleList } from 'components/factoryrole/FactoryroleAPI';
import { getListAPI as getTaskstatusList } from 'components/taskstatus/TaskstatusAPI';
import { getListAPI as getActionList } from '../api/ActionAPI';

import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActionList,setMfprintingstatusList,setMfstitchingstatusList,setMfdigitalstatusList,setMfdeliverypriorityList,setMfprintingtypeList,setMforderstatusList,setMforderList,setMfpartyList,setMfproductorderList,setMfproductList,setMfproductorderstatusList,setBusinessprocessList,setTaskList,setTaskinstanceList,setTasktypeList,setFactoryroleList,setTaskstatusList } from 'store/slice/ManufSlice';
import { setMessage } from "store/slice/HeaderSlice";

export default function ManufStoreLoader() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getActionListSuccess = (list) =>{dispatch(setActionList(list));}
    const {isLoggedIn, userprofileInfo} = useSelector((state)=>state.headerslice)

    
const getMfprintingstatusListSuccess = (list) =>{dispatch(setMfprintingstatusList(list));}
const getMfstitchingstatusListSuccess = (list) =>{dispatch(setMfstitchingstatusList(list));}
const getMfdigitalstatusListSuccess = (list) =>{dispatch(setMfdigitalstatusList(list));}
const getMfdeliverypriorityListSuccess = (list) =>{dispatch(setMfdeliverypriorityList(list));}
const getMfprintingtypeListSuccess = (list) =>{dispatch(setMfprintingtypeList(list));}
const getMforderstatusListSuccess = (list) =>{dispatch(setMforderstatusList(list));}
const getMforderListSuccess = (list) =>{dispatch(setMforderList(list));}
const getMfpartyListSuccess = (list) =>{dispatch(setMfpartyList(list));}
const getMfproductorderListSuccess = (list) =>{dispatch(setMfproductorderList(list));}
const getMfproductListSuccess = (list) =>{dispatch(setMfproductList(list));}
const getMfproductorderstatusListSuccess = (list) =>{dispatch(setMfproductorderstatusList(list));}
const getBusinessprocessListSuccess = (list) =>{dispatch(setBusinessprocessList(list));}
const getTaskListSuccess = (list) =>{dispatch(setTaskList(list));}
const getTaskinstanceListSuccess = (list) =>{dispatch(setTaskinstanceList(list));}
const getTasktypeListSuccess = (list) =>{dispatch(setTasktypeList(list));}
const getFactoryroleListSuccess = (list) =>{dispatch(setFactoryroleList(list));}
const getTaskstatusListSuccess = (list) =>{dispatch(setTaskstatusList(list));}

    /***   Load User profile if JWT is found otherwise Redirect to Login  */
    useEffect(() => {
        if (localStorage.getItem("JWT") !== null) {
                getUserAPI(loadUserSuccessCallBack, loadUserFailureCallBack);
        } else {
            navigate("/login");
        }
    }, [])
    const loadUserSuccessCallBack = (response) => {
        //if (response.userprofileInfo==undefined || response.appuserInfo==undefined){
            if (!response.appuserInfo){
            navigate('/login');
            return;
        } 
        dispatch(setMessage(response.userprofileInfo.businessname));
        dispatch(setAppuserInfo(response.appuserInfo));
        dispatch(setuserprofileInfo(response.userprofileInfo));
        dispatch(setLoggedIn(true));
    }
    const loadUserFailureCallBack = (response) => {
        navigate("/login");

    }
    /* -----------  */

    /***   On User Profile Load, fetch all BOs    */
    useEffect(() => {
        if (userprofileInfo!==null && userprofileInfo.username!==undefined)
            loadBOs();
    }, [userprofileInfo]);

    const loadBOs = ()=>{
        if (localStorage.getItem("JWT") === null) return;
        getActionList(()=>{}, getActionListSuccess);

        
	getMfprintingstatusList(()=>{}, getMfprintingstatusListSuccess);
	getMfstitchingstatusList(()=>{}, getMfstitchingstatusListSuccess);
	getMfdigitalstatusList(()=>{}, getMfdigitalstatusListSuccess);
	getMfdeliverypriorityList(()=>{}, getMfdeliverypriorityListSuccess);
	getMfprintingtypeList(()=>{}, getMfprintingtypeListSuccess);
	getMforderstatusList(()=>{}, getMforderstatusListSuccess);
	getMforderList(()=>{}, getMforderListSuccess);
	getMfpartyList(()=>{}, getMfpartyListSuccess);
	getMfproductorderList(()=>{}, getMfproductorderListSuccess);
	getMfproductList(()=>{}, getMfproductListSuccess);
	getMfproductorderstatusList(()=>{}, getMfproductorderstatusListSuccess);
	getBusinessprocessList(()=>{}, getBusinessprocessListSuccess);
	getTaskList(()=>{}, getTaskListSuccess);
	getTaskinstanceList(()=>{}, getTaskinstanceListSuccess);
	getTasktypeList(()=>{}, getTasktypeListSuccess);
	getFactoryroleList(()=>{}, getFactoryroleListSuccess);
	getTaskstatusList(()=>{}, getTaskstatusListSuccess);
         dispatch(setInitialAPIload(false));
    }
}